import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast';
import Select from 'react-select';
import Loader from '../../../pages/Loader';
import { TASK_PUT_DATA } from '../../auth/core/_requests';
import AllUser from '../../../pages/AllUser';
import { MultiSelect } from 'react-multi-select-component';
import { SelectOption } from '../../../pages/Interface';

const TaskEdit = ({ loadingEdit, setShowEditModal, initialEditValues,
    leadsOwnerSelected, setLeadsOwnerSelected, fetchTaskList, setTaskAssignby, taskAssignby,
    selectedOption, setSelectedOption, contactOptions, CustomOption, leadsOptions,
}: any) => {
    const [loading, setLoading] = useState(false)
    const [reminderEnabled, setReminderEnabled] = useState(false);

    const validationSchemaEdit = Yup.object().shape({
        task_type_upd: Yup.string().required('Task type is required'),
        task_status_upd: Yup.string().required('Task status is required'),
    });

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";

    const handleRelatedChange = (selected: any) => {
        // Only allow one selection at a time
        if (selected.length > 1) {
            selected = [selected[selected.length - 1]];
        }
        setTaskAssignby(selected);
    };


    const handleTaskAssign = (selected: any) => {
        setLeadsOwnerSelected(selected);
    };

    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
        event.preventDefault(); // Prevent the default form submission
        setSelectedOption(value);
        setTaskAssignby(null as any);
    };

    const handleContactUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const ContactOwner = leadsOwnerSelected?.value;
            const RelatedToId = taskAssignby[0].value
            const ReminderOn = `${values.task_due_date_upd} ${values.task_reminder_time_upd}`;
            const response = await TASK_PUT_DATA(
                values.task_id_upd,
                ContactOwner ? ContactOwner : "",
                selectedOption,
                RelatedToId ? RelatedToId : "",
                values.task_type_upd,
                values.task_due_date_upd,
                values.task_due_time_upd,
                values.task_status_upd,
                values.task_priority_upd,
                values.task_reminder_notify_upd,
                ReminderOn,
                values.task_description_upd,
                values.task_subject_upd,
                userId
            )
            if (response.data.message === 'success') {
                toast.success("Task Update successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setLeadsOwnerSelected(null as any)
                setTaskAssignby(null as any)
                actions.resetForm();
                fetchTaskList("0", userId)
                setShowEditModal(false);
            } else {
                toast.error("Failed to update task", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }
    const transformedTaskAssignby: SelectOption[] = taskAssignby ? taskAssignby.map((item: any) => ({ value: item.value, label: item.label })) : [];

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Task Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <Formik validationSchema={validationSchemaEdit} initialValues={initialEditValues} onSubmit={handleContactUpdate}>
                        {({ values }) => (
                            <Form>
                                <div className="modal-body form_content">
                                    <div className="form-group">
                                        <input type="hidden" name="task_id_upd" className="form-control" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className='form-label mb-3 required'>Task Assignee</label>
                                        <div className='task_assignee_dropdown'>
                                            <Select
                                                options={AllUser()}
                                                value={leadsOwnerSelected}
                                                placeholder="Search or select..."
                                                isSearchable
                                                onChange={handleTaskAssign}
                                                menuPosition="fixed"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="task_owner" className='form-label mb-3 required'>Task Related To</label>
                                        <div className="input-group mb-3">
                                            <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{selectedOption}</button>
                                            <ul className="dropdown-menu">
                                                <li><button className="dropdown-item" onClick={(e) => handleSelectChange(e, 'Lead')}>Lead</button></li>
                                                <li><button className="dropdown-item" onClick={(e) => handleSelectChange(e, 'Contact')}>Contact</button></li>
                                            </ul>

                                            <MultiSelect
                                                options={selectedOption === 'Lead' ? leadsOptions : contactOptions}
                                                value={transformedTaskAssignby}
                                                onChange={handleRelatedChange}
                                                className={selectedOption === 'Lead' ? 'task_leads_dropdown' : 'task_contacts_dropdown'}
                                                labelledBy="Select"
                                                hasSelectAll={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3" htmlFor="task_user_number_upd">Phone Number</label>
                                        <Field type="number" name="task_user_number_upd" className="form-control form-control-sm text-dark" title="Read Only" disabled/>
                                        <ErrorMessage name="task_user_number_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3" htmlFor="task_user_email_upd">Email</label>
                                        <Field type="text" name="task_user_email_upd" className="form-control form-control-sm text-dark" title="Read Only"  disabled/>
                                        <ErrorMessage name="task_user_email_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3 required" htmlFor="task_type_upd">Task Type</label>
                                        <Field as="select" name="task_type_upd" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="">-None-</option>
                                            <option value="1">Counseling</option>
                                            <option value="2">Email</option>
                                            <option value="3">Meeting</option>
                                        </Field>
                                        <ErrorMessage name="task_type_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group  mb-3">
                                        <label className="form-label mb-3 required">Due Date & Time</label>
                                        <div className='input-group'>
                                            <Field name="task_due_date_upd" type="date" className="form-control form-control-sm" />
                                            <Field name="task_due_time_upd" type="time" className="form-control form-control-sm" />
                                        </div>
                                        <ErrorMessage name="task_due_date_upd" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3 required" htmlFor="task_status_upd">Status</label>
                                        <Field as="select" name="task_status_upd" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="">-None-</option>
                                            <option value="1">Not started</option>
                                            <option value="6">Pending</option>
                                            <option value="3">In progress</option>
                                            <option value="4">Waiting For Input</option>
                                            <option value="5">Completed</option>
                                        </Field>
                                        <ErrorMessage name="task_status_upd" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label mb-3 required" htmlFor="task_priority_upd">Priority</label>
                                        <Field as="select" name="task_priority_upd" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="1">High</option>
                                            <option value="2">Highest</option>
                                            <option value="3">Low</option>
                                            <option value="4">Lowest</option>
                                            <option value="5">Normal</option>
                                        </Field>
                                        <ErrorMessage name="task_priority_upd" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label mt-3 mb-3" htmlFor="task_subject_upd">Subject</label>
                                        <Field type="text" name="task_subject_upd" className="form-control form-control-sm" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label mt-3 mb-3" htmlFor="task_description_upd">Task Description</label>
                                        <Field
                                            name="task_description_upd"
                                            className="form-control form-control-sm"
                                            as="textarea"
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="flexSwitchDefault" className="form-label mt-3 mb-3">Reminder</label>
                                        <div className="form-check form-switch form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitchDefault"
                                                checked={reminderEnabled}
                                                onChange={(e) => setReminderEnabled(e.target.checked)} // Update state on switch change
                                            />
                                            <h6 className="ms-5">On due date at {values.task_reminder_time_upd} by {
                                                values.task_reminder_notify_upd == "1" ? "Email" :
                                                    values.task_reminder_notify_upd == "2" ? "Pop Up" :
                                                        values.task_reminder_notify_upd == "3" ? "Email & Pop Up" : ""
                                            }
                                            </h6>
                                        </div>
                                    </div>

                                    {reminderEnabled && (
                                        <>
                                            <div style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', padding: '20px', marginTop: '10px' }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="fw-bold">Reminder on due date at </div>
                                                    <div className="form-group ms-5">
                                                        <Field name="task_reminder_time_upd" type="time" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <div className="fw-bold">Notify</div>
                                                    <div className="form-group ms-5">
                                                        <Field as="select" name="task_reminder_notify_upd" aria-label="Select example" className="form-select border-0 form-select-sm">
                                                            {/* <option value="">Select</option> */}
                                                            {/* <option value="1">Email</option> */}
                                                            <option value="2">Pop Up</option>
                                                            {/* <option value="3">Both</option> */}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    )
}

export default TaskEdit