import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import TaskDetails from './TaskDetails'
import { StaffLog } from './ChartStatics';
import { UserDeatils_Post } from '../../modules/auth/core/_requests'
import { AccountDetails, DashboardStats } from '../Interface'
import { useAuth } from '../../modules/auth'
import { Link } from 'react-router-dom';


type Props = {
    stats: DashboardStats | undefined;
    loading: boolean
}

const StaffsDashboard: React.FC<Props> = ({ stats, loading }) => {
    
    const [details, setDetails] = useState<AccountDetails>()
    const { currentUser } = useAuth()
    const [userRole] = useState(currentUser?.user_role || '')
    const [userID] = useState(currentUser?.user_id || '')
    const [userToken] = useState(currentUser?.api_token || '')

    const FetchAdmissionList = async (verify_user_token: any, user_id: any) => {
        try {
            const response = await UserDeatils_Post(verify_user_token, user_id);
            setDetails(response.data);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        FetchAdmissionList(userToken, userID);
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <div className="card border-transparent ps-15 pt-20 pb-20" data-bs-theme="light" style={{ background: '#1C325E' }}>
                        <div className="card-body p-0 pb-5">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
                                    Welcome Back, {details?.name} !<br />
                                    {stats?.total_meetings !== "0" ? (
                                        <span className='fs-6'>You have {stats?.total_meetings} meetings today</span>
                                    ) : (
                                        <span className='fs-6'>No Meetings Today</span>
                                    )}
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="border-end pe-4 border-muted border-opacity-10">
                                        <span className="mb-1 fw-bold fs-2x d-flex align-content-center text-white">{stats?.total_scheduled_calls}
                                            <KTIcon iconName="arrow-up-right" className="fs-3 text-white fs-5 lh-base" />
                                        </span>
                                        <span className="fw-semibold fs-6 text-gray-500">Call Schedule</span>
                                    </div>
                                    <div className="ps-4">
                                        <span className="mb-1 fw-bold fs-2x d-flex align-content-center text-white">{stats?.total_call_logs}
                                            <KTIcon iconName="arrow-up-right" className="fs-3 text-white fs-5 lh-base" />
                                        </span>
                                        <span className="fw-semibold fs-6 text-gray-500">Call Log</span>
                                    </div>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/avatars/welcom_image.png')} className="position-absolute me-3 bottom-0 end-0 h-200px" alt='welcome' />
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Link to={`/task-status/1/`}>
                                <div className="card dashboard_new_task">
                                    <div className="card-body d-flex justify-content-between align-items-start p-5">
                                        <div>
                                            <span className="fw-bold fs-3x text-white">{stats?.total_new_tasks ? stats?.total_new_tasks : "0"}</span>
                                            <p className="fw-bold fs-6 text-white">New <br />Task</p>
                                        </div>
                                        <div>
                                            <KTIcon iconName='chart-simple' className='fs-3x text-white' />
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <Link to={`/task-call-status/2/`}>
                                <div className="card mt-5 dashboard_schedule">
                                    <div className="card-body d-flex justify-content-between align-items-start p-5">
                                        <div>
                                            <span className="fw-bold fs-3x text-white">{stats?.total_scheduled_calls ? stats?.total_scheduled_calls : '0'}</span>
                                            <p className="fw-bold fs-6 text-white">Schedule <br />Task</p>
                                        </div>
                                        <div>
                                            <KTIcon iconName='calendar-add' className='fs-3x text-white' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='col-md-6'>
                            <Link to={`/task-status/6/`}>
                                <div className="card dashboard_pending">
                                    <div className="card-body d-flex justify-content-between align-items-start p-5">
                                        <div>
                                            <span className="fw-bold fs-3x text-white">{stats?.total_pending_tasks ? stats?.total_pending_tasks :'0'}</span>
                                            <p className="fw-bold fs-6 text-white">Pending <br />Task</p>
                                        </div>
                                        <div>
                                            <KTIcon iconName='question-2' className='fs-3x text-white' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/task-status/5/`}>
                                <div className="card mt-5 dashboard_completed">
                                    <div className="card-body d-flex justify-content-between align-items-start p-5">
                                        <div>
                                            <span className="fw-bold fs-3x text-white">{stats?.total_completed_tasks ? stats?.total_completed_tasks : "0"}</span>
                                            <p className="fw-bold fs-6 text-white">Completed <br />Task</p>
                                        </div>
                                        <div>
                                            <KTIcon iconName='check-circle' className='fs-3x text-white' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-5'>
                <div className='col-md-8'>
                <TaskDetails stats={stats} loading={loading}/>
                </div>
                <div className='col-md-4'>
                    <StaffLog className='mb-5 mb-xxl-8 h-500px' stats={stats} loading={loading} />
                </div>
            </div>
        </>
    )
}

export default StaffsDashboard