import React, { useState, useEffect } from 'react';
import { DASHBOARD_GET_STATS } from '../../modules/auth/core/_requests'
import { Chart } from './Chart';
import { StaffLog } from './ChartStatics';
import { RecentAdmission } from './Staffs';
import TaskDetails from './TaskDetails';
import StaffsDashboard from './StaffsDashboard';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useAuth } from '../../modules/auth';
import { AccountDetails, DashboardStats } from '../Interface';
import { Link } from 'react-router-dom';
import { fetchUserDetails } from '../CRM_GET';
import AlertForm from '../../modules/subscription/AlertForm';




const Dashboard = () => {
  const { currentUser } = useAuth()
  const [userRole] = useState(currentUser?.user_role || '')
  const [userID] = useState(currentUser?.user_id || '')
  const [userToken] = useState(currentUser?.api_token || '')
  const [stats, setStats] = useState<DashboardStats>();
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<AccountDetails>()


  const fetchStatsData = async (dashboard_stats: any, dashboard_user_id: any) => {
    try {
      const response = await DASHBOARD_GET_STATS(dashboard_stats, dashboard_user_id);
      setStats(response.data.dashboard_stats);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchStatsData("0", userID);
    fetchUserDetails(userToken, userID, setDetails, setLoading)
  }, []);

  const subscriptionEndDate: any = details?.subscription_end;

  return (
    <>
      {userRole == "1" || userRole == "2" ? (
        <>
          {userRole == "1" && (
            <AlertForm subscriptionEndDate={subscriptionEndDate} />
          )}
          <div className='card'>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
              </h3>
            </div>
          </div>
          <div className="row g-5 g-xl-10 mt-3">
            <div className="col-sm-6 col-xl-2 mb-xl-10">
              <Link to={`/leads`}>
                <div className="card h-lg-100">
                  <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    <div className="m-0">
                      <img src={toAbsoluteUrl('/media/icon/leads.png')} className="w-60px" alt="" />
                    </div>
                    <div className="d-flex flex-column my-7">
                      <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                        {stats?.total_leads ? stats?.total_leads : "0"}
                      </span>
                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-500">Total Leads</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-xl-2 mb-xl-10">
              <Link to={`/contacts`}>
                <div className="card h-lg-100">
                  <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    <div className="m-0">
                      <img src={toAbsoluteUrl('/media/icon/contacts.png')} className="w-50px" alt="" />
                    </div>
                    <div className="d-flex flex-column my-7">
                      <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                        {stats?.total_contacts ? stats?.total_contacts : "0"}
                      </span>
                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-500">Total Contacts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-sm-6 col-xl-2 mb-xl-10">
              <Link to={`/courses/all-courses`}>
                <div className="card h-lg-100">
                  <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    <div className="m-0">
                      <img src={toAbsoluteUrl('/media/icon/courses.png')} className="w-60px" alt="" />
                    </div>
                    <div className="d-flex flex-column my-7">
                      <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                        {stats?.total_courses ? stats?.total_courses : "0"}
                      </span>
                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-500">Courses</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-sm-6 col-xl-2 mb-xl-10">
              <Link to={`/admission/new-admission`}>
                <div className="card h-lg-100">
                  <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    <div className="m-0">
                      <img src={toAbsoluteUrl('/media/icon/admissions.png')} className="w-50px" alt="" />
                    </div>
                    <div className="d-flex flex-column my-7">
                      <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                        {stats?.total_admissions ? stats?.total_admissions : "0"}
                      </span>
                      <div className="m-0">
                        <span className="fw-semibold fs-6 text-gray-500">Admissions</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-4 mb-5 mb-xl-10">
              <div className="card card-flush border-0 dash_bg_color h-lg-100" data-bs-theme="light">
                <div className="card-header pt-2">
                  <h3 className="card-title">
                    <span className="text-white fs-3 fw-bold me-2">Revenues</span>
                  </h3>
                </div>
                <div className="card-body d-flex justify-content-between flex-column pt-1 px-0 pb-0">
                  <div className="d-flex flex-wrap px-9 mb-5">
                    <div className="rounded min-w-125px py-3 px-4 my-1 me-6" style={{ border: "2px dashed rgb(255 255 255 / 50%)" }}>
                      <div className="d-flex align-items-center">
                        <div className="text-white fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="4368" data-kt-countup-prefix="$" data-kt-initialized="1">₹ {stats?.total_revenue_today ? stats?.total_revenue_today : "0"}</div>
                      </div>
                      <div className="fw-semibold fs-6 text-white">Daily revenues</div>
                    </div>
                    <div className="rounded min-w-125px py-3 px-4 my-1" style={{ border: "2px dashed rgb(255 255 255 / 50%)" }}>
                      <div className="d-flex align-items-center">
                        <div className="text-white fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="120,000" data-kt-initialized="1">₹ {stats?.total_revenue_this_month ? stats?.total_revenue_this_month : "0"}</div>
                      </div>
                      <div className="fw-semibold fs-6 text-white">Monthly revenues</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-8'>
              <Chart className='card-xl-stretch mb-5 mb-xl-8 h-500px' stats={stats} />
            </div>
            <div className='col-md-4'>
              <RecentAdmission className='card-xl-stretch mb-xl-8 h-500px' stats={stats} loading={loading} />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4'>
              <StaffLog className='mb-5 mb-xxl-8 h-500px' stats={stats} loading={loading} />
            </div>
            <div className='col-md-8'>
              <TaskDetails stats={stats} loading={loading} />
            </div>
          </div>
        </>
      ) : ''}

      {userRole == "3" ? (
        <div>
          <StaffsDashboard stats={stats} loading={loading} />
        </div>
      ) : ''}
    </>
  )
}

export default Dashboard