import React, { useState } from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    itemsPerPage: number;
    handlePageChange: (page: number) => void;
    handleItemsPerPageChange: (itemsPerPage: number) => void;
}

const PaginationNew: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    itemsPerPage,
    handlePageChange,
    handleItemsPerPageChange,
}) => { 
    const options = [10, 20, 50, 100];

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(e.target.value);
        handleItemsPerPageChange(selectedValue); 
    };

    if (totalPages <= 1) {
        return null; // Don't render pagination if there's only one page
    }

    const firstPage = Math.max(1, currentPage - 1);
    const lastPage = Math.min(totalPages, currentPage + 1);

    return (
        <ul className="pagination justify-content-end mt-5 mb-5">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
            </li>
            {firstPage > 1 && (
                <>
                    <li className="page-item">
                        <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                    </li>
                    {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                </>
            )}
            {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                    <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                </li>
            ))}
            {lastPage < totalPages && (
                <>
                    {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    <li className="page-item">
                        <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                    </li>
                </>
            )}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
            </li>
            <li className="page-item">
                <button className="page-link">
                    <select className="form-select" onChange={handleChange} value={itemsPerPage}>
                        {options.map(option => (
                            <option key={option} value={option}>
                                {option} / page
                            </option>
                        ))}
                    </select>
                </button> 
            </li>
        </ul>
    );

};

export default PaginationNew;