import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Addons } from "../../../auth/core/_requests"
import Loader from '../../../../pages/Loader';
import CreateTemplate from './TemplateForm/CreateTemplate';
import { GET_TEMPLATES } from '../../../../pages/GET'; 

interface Example {
    body_text: [string[], string[]];
    header_handle: [string[], string[]];
}

interface Component {
    type: string;
    format?: string;
    text: string;
    example: Example;
}

interface DataItem {
    name: string;
    components: Component[];
    language: string;
    status: string;
    category: string;
    id: string;
}

interface ApiResponse {
    data: DataItem[];
}


const Template = () => {
    const [apiData, setApiData] = useState<ApiResponse | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const MAX_WORDS = 50; 

    let tokenData: any
    const AccessToken = async (addon: any) => {
        try {
            const response = await Addons(addon); 
            if (response.data.addon_details) {
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
                tokenData = addonMetaData.access_token
                GET_TEMPLATES(tokenData, setApiData);
            }
        } catch (error) {
            console.error('Error fetching addons:', error);
        }
    };

    useEffect(() => {
        AccessToken("whatsapp");
    }, []);

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
    };

    const handleTemplateCreate = () => {
        setOpenModal(true);
    };


    if (!apiData) {
        return (
            <Loader />
        );
    }
    return (
        <>
            <div className='card shadow-0'>
                <div className='card-header border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3'>Message Templates</span>
                    </h3>
                    <div className='card-toolbar'>
                        {/* <div>
                            <button className='btn btn-primary' onClick={handleTemplateCreate}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Create Template
                            </button>
                        </div> */}
                        <div>
                            <a href="https://business.facebook.com/wa/manage/message-templates/?business_id=3698776420376373&waba_id=234524643086539" target="_new"
                                className="btn btn-primary">  <KTIcon iconName='plus' className='fs-2' />Create Template</a>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#video_templates"
                            >
                                Video Templates
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#img_templates"
                            >
                                Image Templates
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#doc_templates"
                            >
                                Document Templates
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#text_templates"
                            >
                                Text Templates
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#other_templates"
                            >
                                Others Templates
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="video_templates" role="tabpanel"  >
                            <div className='row'>
                                {apiData.data.map((item) => (
                                    <>
                                        {item?.components.map((component, index) => (
                                            <>
                                                {component?.format === "VIDEO" ? (
                                                    <div key={index} className='col-md-3 mt-10'>
                                                        <div className="card shadow h-100">
                                                            <div className='d-flex justify-content-center bg-light'>
                                                            {item.components && item.components.map((component, index) => (
                                                                <div key={index} >

                                                                    {component.type === 'HEADER' && (
                                                                        <div>

                                                                            {component.example && component.example.header_handle && component.example.header_handle.map((header, headerIndex) => (
                                                                                <div key={headerIndex}>
                                                                                    {/* {header} */}
                                                                                    <video src={Array.isArray(header) ? header[0] : header} controls className='w-100' />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                            </div>
                                                            {/* <div className="card-header">
                                                                <h4 className="card-title">{item.name}</h4>
                                                            </div> */}
                                                            <div className="card-body">
                                                                {item.components && item.components.map((component, index) => (
                                                                    <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: (expandedItemId === item.id) ? component.text : (component.text ? component.text.slice(0, MAX_WORDS) : '') }} />
                                                                    </div>
                                                                ))}

                                                                {item.components && item.components.some(component => component.text && component.text.length > MAX_WORDS) && (
                                                                    <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => toggleExpand(item.id)}>
                                                                        {expandedItemId === item.id ? ' Read Less' : ' Read More'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="card-footer bg-light">
                                                                <span>Language: </span>  {item.language} <br></br>
                                                                <span className='bold'>Name: </span>  {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </>
                                        ))}
                                    </>
                                ))}
                                {!apiData.data.some(item => item.components.some(component => component.format === "VIDEO")) && (
                                    <div className="mt-10">
                                        <div className="d-flex justify-content-center p-10">
                                            <div>
                                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                                <h3 className="text-center">No Video Templates Found</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="img_templates" role="tabpanel">
                            <div className='row'>
                                {apiData.data.map((item) => (
                                    <>
                                        {item?.components.map((component, index) => (
                                            <>
                                                {component?.format === "IMAGE" ? (
                                                    <div className='col-md-3 mt-10'>
                                                        <div key={index} className="card card-custom card-stretch-50 shadow h-100">
                                                       
                                                            {item.components && item.components.map((component, index) => (
                                                                <div key={index} >

                                                                    {component.type === 'HEADER' && (
                                                                        <div>

                                                                            {component.example && component.example.header_handle && component.example.header_handle.map((header, headerIndex) => (
                                                                                <div key={headerIndex}>
                                                                                    {/* {header} */}
                                                                                    <img alt={item.name} src={Array.isArray(header) ? header[0] : header}  className='w-100 h-250px object-cover'/>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}

                                                            <div className="card-body">
                                                                {item.components && item.components.map((component, index) => (
                                                                    <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: (expandedItemId === item.id) ? component.text : (component.text ? component.text.slice(0, MAX_WORDS) : '') }} />
                                                                    </div>
                                                                ))}
                                                                {item.components && item.components.some(component => component.text && component.text.length > MAX_WORDS) && (
                                                                    <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => toggleExpand(item.id)}>
                                                                        {expandedItemId === item.id ? ' Read Less' : ' Read More'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="card-footer bg-light">
                                                                <span>Language: </span>  {item.language} <br></br>
                                                                <span className='bold'>Name: </span>  {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </>
                                        ))}
                                    </>
                                ))}
                                {!apiData.data.some(item => item.components.some(component => component.format === "IMAGE")) && (
                                    <div className="mt-10">
                                        <div className="d-flex justify-content-center p-10">
                                            <div>
                                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                                <h3 className="text-center">No Image Templates Found</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="doc_templates" role="tabpanel">
                            <div className='row'>
                                {apiData.data.map((item) => (
                                    <>
                                        {item?.components.map((component, index) => (
                                            <>
                                                {component?.format === "DOCUMENT" ? (
                                                    <div className='col-md-3 mt-10'>
                                                        <div key={index} className="card card-custom card-stretch-50 shadow  h-100">
                                                            <div className='d-flex justify-content-center bg-light'>
                                                                <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/pdf_image.png')} className='w-75px pt-10 pb-10' />
                                                            </div>

                                                            <div className="card-body">

                                                                {item.components && item.components.map((component, index) => (
                                                                    <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: (expandedItemId === item.id) ? component.text : (component.text ? component.text.slice(0, MAX_WORDS) : '') }} />
                                                                    </div>
                                                                ))}
                                                                {item.components && item.components.some(component => component.text && component.text.length > MAX_WORDS) && (
                                                                    <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => toggleExpand(item.id)}>
                                                                        {expandedItemId === item.id ? ' Read Less' : ' Read More'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="card-footer bg-light">
                                                                <span>Language: </span>  {item.language} <br></br>
                                                                <span className='bold'>Name: </span>  {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </>
                                        ))}
                                    </>
                                ))}
                                {!apiData.data.some(item => item.components.some(component => component.format === "DOCUMENT")) && (
                                    <div className="mt-10">
                                        <div className="d-flex justify-content-center p-10">
                                            <div>
                                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                                <h3 className="text-center">No Documents Templates Found</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="text_templates" role="tabpanel">
                            <div className='row'>
                                {apiData.data.map((item) => (
                                    <>
                                        {item?.components.map((component, index) => (
                                            <>
                                                {component?.format === "TEXT" ? (
                                                    <div className='col-md-3 mt-10'>
                                                        <div key={index} className="card card-custom card-stretch-50 shadow  h-100">

                                                            <div className="card-body">
                                                                {item.components.map((component, index) => (
                                                                    <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                                                                                                                                <div dangerouslySetInnerHTML={{ __html: (expandedItemId === item.id) ? component.text : component?.text?.slice(0, MAX_WORDS) }} />
                                                                    </div>
                                                                ))}
                                                                {item.components.some(component => component.text.length > MAX_WORDS) && (
                                                                    <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => toggleExpand(item.id)}>
                                                                        {expandedItemId === item.id ? ' Read Less' : ' Read More'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="card-footer bg-light">
                                                                <span>Language: </span>  {item.language} <br></br>
                                                                <span className='bold'>Name: </span>  {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </>
                                        ))}
                                    </>
                                ))}
                                {!apiData.data.some(item => item.components.some(component => component.format === "TEXT")) && (
                                    <div className="mt-10">
                                        <div className="d-flex justify-content-center p-10">
                                            <div>
                                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                                                <h3 className="text-center">No Text Templates Found</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="other_templates" role="tabpanel">
                            <div className='row'>
                                {apiData.data.map((item) => (
                                    <>
                                        {item.components.map((component, index) => {
                                            if (item.components[0].type === "BODY" && component.text && item.components[index].type !== "FOOTER") {
                                                return (
                                                    <div className='col-md-3 mt-10' key={index}>
                                                        <div className="card card-custom card-stretch-50 shadow  h-100">
                                                            <div className="card-body">
                                                                {item.components && item.components.map((component, index) => (
                                                                    <div key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: (expandedItemId === item.id) ? component.text : (component.text ? component.text.slice(0, MAX_WORDS) : '') }} />
                                                                    </div>
                                                                ))}
                                                                {item.components && item.components.some(component => component.text && component.text.length > MAX_WORDS) && (
                                                                    <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => toggleExpand(item.id)}>
                                                                        {expandedItemId === item.id ? ' Read Less' : ' Read More'}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="card-footer bg-light">
                                                                <span>Language: </span>  {item.language} <br></br>
                                                                <span className='bold'>Name: </span>  {item.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return "";
                                            }
                                        })}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className={`modal${openModal ? ' show' : ''}`} role="dialog" style={{ display: openModal ? 'block' : 'none' }}>
                <CreateTemplate setOpenModal={setOpenModal} />
            </div>
        </>
    );
}


export default Template