import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as XLSX from 'xlsx';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import { GET_TASK_LIST } from '../../pages/CRM_GET';
import { useAuth } from '../auth';
import { TaskDetails } from '../../pages/Interface';
import Loader from '../../pages/Loader';
import PaginationNew from '../../pages/PaginationNew';

const TaskReport = () => {
    const { currentUser } = useAuth()
    const [userID] = useState(currentUser?.user_id || '')
    const [refreshTask, setRefreshTask] = useState(false);
    const [taskData, setTaskData] = useState<TaskDetails[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPerPage, setShowPerPage] = useState(10);
    const [taskTotalDatas, setTaskTotalDatas] = useState<TaskDetails[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState('')
    const [filterSource, setFilterSource] = useState('')
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [dateState, setDateState] = useState<any>({
        startDate: '',
        endDate: ''
    });

    const RefreshTaskData = () => {
        setRefreshTask(true);
        GET_TASK_LIST("0", userID, setTaskData, setIsLoading)
            .then(response => {
                setRefreshTask(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
        setShowAllTags(!showAllTags)
    };

    useEffect(() => {
        GET_TASK_LIST("0", userID, setTaskData, setIsLoading)
    }, [])

    function jsonToExcel(jsonData: any[]) {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    function downloadExcel(data: any[], filename: string) {
        const excelBuffer = jsonToExcel(data);
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    const handleDownloadReport = async () => {
        try {
            // Create a new array with the modified data
            const modifiedData = taskData.map(lead => {
                const { task_status, task_type, task_reminder_mode, task_priority, ...rest } = lead;
                const taskType =
                    lead.task_type === '1' ? 'Counseling' :
                        lead.task_type === '2' ? 'Email' :
                            lead.task_type === '3' ? 'Meeting' : '';
                const taskPriority =
                    lead.task_priority === '1' ? 'High' :
                        lead.task_priority === '2' ? 'Highest' :
                            lead.task_priority === '3' ? 'Low' :
                                lead.task_priority === '4' ? 'Lowest' :
                                    lead.task_priority === '5' ? 'Normal' : '';
                const taskReminderMode =
                    lead.task_reminder_mode === '1' ? 'Email' :
                        lead.task_reminder_mode === '2' ? 'Pop Up' :
                            lead.task_reminder_mode === '3' ? 'Email & Pop Up' : '';
                const statusType =
                    lead.task_status === '1' ? 'Not started' :
                        lead.task_status === '6' ? 'Pending' :
                            lead.task_status === '3' ? 'In progress' :
                                lead.task_status === '4' ? 'Waiting For Input' :
                                    'Completed';
                return { ...rest, task_type: taskType, task_priority: taskPriority, task_reminder_mode: taskReminderMode, task_status: statusType };
            });
            downloadExcel(modifiedData, 'Task Report.xlsx');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const filtered = taskData?.filter((app: any) => {
            const appData = `${app.task_id} ${app.task_assignee_name} ${app.task_related_to_name} ${app.task_type} ${app.task_created_by}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setTaskTotalDatas(filtered);
    }, [searchQuery, taskData]);

    useEffect(() => {
        const filteredOrders = taskData.filter((item) => {
            const createdDate = new Date(item.task_created_on.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')); // Convert date string to Date object
            return (
                (!filterStatus || item.task_status === filterStatus) &&
                (!filterSource || item.task_type === filterSource) &&
                (!dateState.startDate || createdDate >= dateState.startDate) &&
                (!dateState.endDate || createdDate <= dateState.endDate)
            );
        });
        setTaskTotalDatas(filteredOrders);
    }, [filterStatus, filterSource, taskData, dateState]);

    const handleFilterExport = async () => {
        try {
            // Create a new array with the modified filtered data
            const modifiedFilteredData = taskData.map(lead => {
                const { task_status, task_type, task_reminder_mode, task_priority, ...rest } = lead;
                const taskType =
                    lead.task_type === '1' ? 'Counseling' :
                        lead.task_type === '2' ? 'Email' :
                            lead.task_type === '3' ? 'Meeting' : '';
                const taskPriority =
                    lead.task_priority === '1' ? 'High' :
                        lead.task_priority === '2' ? 'Highest' :
                            lead.task_priority === '3' ? 'Low' :
                                lead.task_priority === '4' ? 'Lowest' :
                                    lead.task_priority === '5' ? 'Normal' : '';
                const taskReminderMode =
                    lead.task_reminder_mode === '1' ? 'Email' :
                        lead.task_reminder_mode === '2' ? 'Pop Up' :
                            lead.task_reminder_mode === '3' ? 'Email & Pop Up' : '';
                const statusType =
                    lead.task_status === '1' ? 'Not started' :
                        lead.task_status === '6' ? 'Pending' :
                            lead.task_status === '3' ? 'In progress' :
                                lead.task_status === '4' ? 'Waiting For Input' :
                                    'Completed';
                return { ...rest, task_type: taskType, task_priority: taskPriority, task_reminder_mode: taskReminderMode, task_status: statusType };
            });

            downloadExcel(modifiedFilteredData, 'Task Report.xlsx');
        } catch (error) {
            console.error('Error exporting filtered data:', error);
        }
    };

    const handleLeadStatusFilter = (e: any) => {
        setFilterStatus(e.target.value);
    };

    const handleLeadSourceFilter = (e: any) => {
        setFilterSource(e.target.value);
    };

    const clearDate = () => {
        setDateState({ date: null });
    };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1);
    };

    const itemsPerPage = 10;
    const totalItems = taskData.length;
    const filterDataTotal = taskTotalDatas.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const taskDetails = taskTotalDatas.slice(startIndex, endIndex);

    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Task Report</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {taskData.length} Task</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshTaskData}>{!refreshTask ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>
                        <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i className="las la-filter fs-2" />
                        </button>
                        <div>
                            <button className='btn btn-primary' onClick={handleDownloadReport}>
                                <KTIcon iconName='file-down' className='fs-2' />
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div className="collapse" id="collapseExample">
                <div className='card bg_none'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        </h3>
                        <div className='card-toolbar'>
                            <div className='d-flex me-2'>
                                <Flatpickr
                                    value={dateState.date}
                                    onChange={([startDate, endDate]) => {
                                        setDateState({ startDate, endDate });
                                    }}
                                    options={{
                                        mode: "range"
                                    }}
                                    className='form-control form-control-solid'
                                    placeholder='Pick date'
                                />
                                {dateState.startDate && dateState.endDate && (
                                    <button className="btn btn-sm btn-secondary" onClick={clearDate}>
                                        <KTIcon iconName='abstract-11' className='' />
                                    </button>
                                )}
                            </div>
                            <div className='me-2'>
                                <select className='form-select' onChange={handleLeadSourceFilter}
                                    value={filterSource}>
                                    <option value="">Task Type</option>
                                    <option value="1">Counseling</option>
                                    <option value="2">Email</option>
                                    <option value="3">Meeting</option>
                                </select>
                            </div>
                            <div className='me-2'>
                                <select
                                    className='form-select'
                                    onChange={handleLeadStatusFilter}
                                    value={filterStatus}
                                >
                                    <option value="">Status</option>
                                    <option value="1">Not started</option>
                                    <option value="6">Pending</option>
                                    <option value="3">In progress</option>
                                    <option value="4">Waiting For Input</option>
                                    <option value="5">Completed</option>
                                </select>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleFilterExport}
                                disabled={!filterStatus && !filterSource && !searchQuery && !dateState.startDate && !dateState.endDate}
                            >
                                <KTIcon iconName='file-down' className='fs-2 me-2' />
                                Export Filtered
                            </button>
                            <div className="d-flex align-items-center">
                                <div className="input-group">
                                    <input type="search" placeholder='Search Name...'
                                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                        className="form-control w-50" />
                                    <button type="button" className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (taskDetails.length > 0 ? (
                <div className={`mt-5 ${refreshTask ? 'd opacity-50' : ''}`} >
                    <table className='table align-middle gs-0 gy-4'>
                        <thead className='table_bg_color'>
                            <tr className='fw-bold fs-6'>
                                <th className='ps-5'>S.No</th>
                                <th>Task Assigner</th>
                                <th>Task Related To</th>
                                <th>Tags</th>
                                <th> Task Type </th>
                                <th>Due Date</th>
                                <th>Status</th>
                                <th>Created by</th>
                            </tr>
                        </thead>
                        <tbody className='bg-light'>
                            {taskDetails.map((task, index) => (
                                <tr key={index} className='border-bottom  border-gray-300'>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>{task.task_id}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 w-200px'>
                                        <div className="symbol symbol-circle symbol-35px">
                                            <img src={task.task_assignee_pic ? task.task_assignee_pic : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                        </div>
                                        {task.task_assignee_name}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{task.task_related_to_name ? task.task_related_to_name : "-"}
                                        <span className="fw-semibold d-block fs-7">Source : {task.task_related_to ? task.task_related_to : "-"}</span>
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {typeof task.task_tags === 'string' ? (() => {
                                            const tags = task.task_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => tag.trim());
                                            return (
                                                <>
                                                    {tags.length > 0 && (
                                                        <span className="badge badge-success me-2 p-1">{tags[0]}</span>
                                                    )}
                                                    <div className="dropdown d-inline">
                                                        <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {tags.length > 1 && (
                                                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(task.task_id)}>
                                                                    {expandedItemId === task.task_id ? "Hide" : "+"}
                                                                    {expandedItemId === task.task_id ? "" : tags.length - 1}
                                                                </span>
                                                            )}
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            {showAllTags && tags.slice(1).map(tag => (
                                                                <li>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                    >
                                                                        <span key={tag}>{tag}</span>
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            );
                                        })() : '-'}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_type == "1" ? "Counseling" :
                                            task.task_type == "2" ? "Email" :
                                                task.task_type == "3" ? "Meeting" : ""
                                        }
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_due_date ? task.task_due_date : "-"}
                                        <span className="fw-semibold d-block fs-7">{task.task_due_time ? task.task_due_time : "-"}</span>
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_status === '1' ? (
                                            <span className='badge badge-primary text-white'>Not started</span>
                                        ) : task.task_status === '6' ? (
                                            <span className='badge badge-danger text-white'>Pending</span>
                                        ) : task.task_status === '3' ? (
                                            <span className='badge badge-warning text-white'>In progress</span>
                                        ) : task.task_status === '4' ? (
                                            <span className='badge bg-dark text-white'>Waiting For Input</span>
                                        ) : (
                                            <span className='badge badge-success text-white'>Completed</span>
                                        )}
                                    </td>

                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{task.task_created_by}
                                        <span className="fw-semibold d-block fs-7">{task.task_created_on}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {filterStatus || filterSource || searchQuery || dateState ? (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={filterTotalPage}
                            itemsPerPage={itemsPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    ) : (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={totalPages}
                            itemsPerPage={showPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    )}
                </div>
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">No Task Found</h3>
                    </div>
                </div>
            )}
        </>
    )
}

export default TaskReport