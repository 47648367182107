import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Form, Formik, ErrorMessage, FormikValues, Field, FieldArray, FormikErrors } from 'formik'
import * as Yup from 'yup'
import DocumentForm from './DocumentForm'
import { DocumentsData, Image } from '../../pages/Interface'
import { ADMISSION_DOC_GET_DATA, ADMISSION_DOC_POST_DATA, ADMISSION_OFFER_LETTER_POST_DATA } from '../auth/core/_requests'
import { useAuth } from '../auth'
import toast from "react-hot-toast";
import Loader from '../../pages/Loader'

const AdmissionView = ({ loadingView, admissionView, handleViewAdmission, appliedUnivercity, FetchAdmissionList }: any) => {
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState<Image[]>([]);
    const [uploadError, setUploadError] = useState<string[]>([]);
    const { currentUser } = useAuth()
    const [userID] = useState(currentUser?.user_id || '')
    const [documentsData, setDocumentData] = useState<DocumentsData[]>([])
    const [isUnvercityVisible, setIsUnivercityVisible] = useState(false);
    const [isDocVisible, setIsDocVisible] = useState(false);
    const [isOfferDocVisible, setIsOfferDocVisible] = useState(false);
    const [isFundVisible, setIsFundVisible] = useState(false);
    const [isCasVisible, setIsCasVisible] = useState(false);
    const [isStatusVisible, setIsStatusVisible] = useState(false);
    const [isVisaVisible, setIsVisaVisible] = useState(false);
    const [isLoanDocVisible, setIsLoanDocVisible] = useState(false);
    const [isDepositDocVisible, setIsDepositDocVisible] = useState(false);
    const AdmissionId = admissionView?.admission_id
    const [initialEditValues, setInitialEditValues] = useState(
        {
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: '',
            doc_offer_letter: '',
            doc_funding_method: '',
            doc_loan_process: '',
            doc_loan_process_type: '',
            doc_admission_status: '',
            doc_deposit_amount: '',
            doc_deposit_date: '',
            doc_visa_status: '',
            doc_cas_status: '',
        }
    )

    useEffect(() => {
        ADMISSION_DOC_GET_DATA(AdmissionId, "general")
            .then(response => {
                setDocumentData(response.data.documents);
            })
            .catch(error => {
                console.error('API Error:', error);
            });
    }, [AdmissionId]);

    const getFileName = (url: any) => {
        const parts = url.split('/');
        return parts[parts.length - 1];
    };

    const handleFileDownload = (fileUrl: string, fileName: string) => {
        // Create an anchor element
        const link = document.createElement('a');
        // Set the href and download attributes
        link.href = fileUrl;
        link.download = fileName;
        // Programmatically trigger the click event
        link.click();
    };

    interface University {
        doc_applied_id: string;
        doc_applied_university: string;
        doc_applied_status: string;
    }

    const initialValues = {
        doc_app_process: '',
        doc_offer_letter: '',
        doc_loan_process_type: '',
        doc_loan_process: '',
        universities: [
            { doc_applied_university: '', doc_applied_status: '' }
        ],
        doc_admission_status: '',
        doc_deposit_amount: '',
        doc_deposit_date: '',
        doc_funding_method: '',
        doc_cas_status: '',
        doc_visa_status: '',
    }

    const validationSchema = Yup.object().shape({
        doc_offer_letter: Yup.string().required('Offer letter is required'),
    });

    const UniversityvalidationSchema = Yup.object().shape({
        universities: Yup.array().of(
            Yup.object().shape({
                doc_applied_university: Yup.string().required('University name is required'),
                doc_applied_status: Yup.string().required('Status is required')
            })
        ).min(1, 'At least one university is required')
    });

    const FundingvalidationSchema = Yup.object().shape({
        doc_funding_method: Yup.string().required('Funding method is required'),
    });

    const LoanvalidationSchema = Yup.object().shape({
        doc_loan_process_type: Yup.string().required('Loan Process Status is required'),
        doc_loan_process: Yup.string().required('Loan Process is required'),
    });

    const DepositvalidationSchema = Yup.object().shape({
        doc_deposit_amount: Yup.string().required('Deposit Amount is required'),
        doc_deposit_date: Yup.string().required('Deposit Date is required'),
    });

    const CASvalidationSchema = Yup.object().shape({
        doc_cas_status: Yup.string().required('CAS is required'),
    });

    const AdmissionStatusSchema = Yup.object().shape({
        doc_admission_status: Yup.string().required('Admission Status is required'),
    });

    const VisavalidationSchema = Yup.object().shape({
        doc_visa_status: Yup.string().required('Visa Status is required'),
    });

    const formatFileSize = (bytes: number) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const ALLOWED_FORMATS = ['image/png', 'image/jpeg', 'video/mp4', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files) as File[];
            const errors: string[] = [];
            const selectedImages = files.filter(file => {
                const isValidFormat = ALLOWED_FORMATS.includes(file.type);
                const isValidSize = file.size <= MAX_FILE_SIZE;
                if (!isValidFormat) {
                    errors.push(`File ${file.name} has invalid format`);
                }
                if (!isValidSize) {
                    errors.push(`File ${file.type} exceeds the maximum allowed size of 5MB`);
                }
                return isValidFormat && isValidSize;
            }).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
                size: file.size,
            }));

            setUploadError(errors);

            if (selectedImages.length > 0) {
                setImages((prevImages) => [...prevImages, ...selectedImages]);
            }
        }
    };

    const handleRemoveImage = (index: number) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_doc_id', AdmissionId);
            formData.append('admission_doc_type', "general_document");
            formData.append('admission_doc_process_type', values.doc_app_process);
            formData.append('admission_doc_uploaded_by', userID);
            images.forEach((image: any) => {
                formData.append('admission_doc_file[]', image.file);
            });
            const response = await ADMISSION_DOC_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Documents Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                ADMISSION_DOC_GET_DATA(AdmissionId, "general")
                handleViewAdmission(AdmissionId, userID)
                handleViewAdmission(AdmissionId, userID)
                setIsDocVisible(false)
                setImages([])
                actions.resetForm()
            } else {
                toast.error("Failed to add documents", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleUniversitySubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_id', AdmissionId);
            formData.append('universities', JSON.stringify(values.universities));
            // formData.append('admission_university_name', values.doc_applied_university);
            // formData.append('admission_university_status', values.doc_applied_status);
            formData.append('admission_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Applied University Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsUnivercityVisible(false)
            } else {
                toast.error("Failed to add university", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching funding:', error);
        }
    }

    const handleOfferLetterSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_off_id', AdmissionId);
            formData.append('admission_offer_doc_type', "offer_document");
            formData.append('admission_offer_condition_letter', values.doc_offer_letter);
            formData.append('admission_offer_doc_uploaded_by', userID);
            images.forEach((image: any) => {
                formData.append('admission_offer_doc_file[]', image.file);
            });
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Offer Letter Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setImages([])
                ADMISSION_DOC_GET_DATA(AdmissionId, "general")
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsOfferDocVisible(false)
            } else {
                toast.error("Failed to add offer letter", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleFundSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_fund_id', AdmissionId);
            formData.append('admission_fund_method', values.doc_funding_method);
            formData.append('admission_fund_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Funding Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsFundVisible(false)
            } else {
                toast.error("Failed to add funding", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching funding:', error);
        }
    }

    const handleLoanSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_loan_doc_id', AdmissionId);
            formData.append('admission_loan_doc_type', "loan_document");
            formData.append('admission_loan_process', values.doc_loan_process);
            formData.append('admission_loan_process_type', values.doc_loan_process_type); 
            formData.append('admission_loan_doc_uploaded_by', userID);
            images.forEach((image: any) => {
                formData.append('admission_loan_doc_file[]', image.file);
            });
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                setLoading(false);
                setImages([])
                ADMISSION_DOC_GET_DATA(AdmissionId, "general")
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                setIsLoanDocVisible(false)
                actions.resetForm()
                toast.success("Loan Document Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
            } else {
                toast.error("Failed to add documents", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleDepositSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_deposit_id', AdmissionId);
            formData.append('admission_deposit_doc_type', "deposit_document");
            formData.append('admission_deposit_amount', values.doc_deposit_amount);
            formData.append('admission_deposit_date', values.doc_deposit_date);
            formData.append('admission_deposit_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Deposit Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsDepositDocVisible(false)
            } else {
                toast.error("Failed to add deposit", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleCASSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_cas_id', AdmissionId);
            formData.append('admission_cas_status', values.doc_cas_status);
            formData.append('admission_cas_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("CAS Status Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsCasVisible(false)
            } else {
                toast.error("Failed to add visa", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleVisaSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_visa_id', AdmissionId);
            formData.append('admission_visa_status', values.doc_visa_status);
            formData.append('admission_visa_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Visa Status Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsVisaVisible(false)
            } else {
                toast.error("Failed to add visa", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleAdmissionSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append('admission_status_id', AdmissionId);
            formData.append('admission_status', values.doc_admission_status);
            formData.append('admission_status_updated_by', userID);
            const response = await ADMISSION_OFFER_LETTER_POST_DATA(formData);
            if (response.data.message === 'success') {
                toast.success("Status Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                handleViewAdmission(AdmissionId, userID)
                FetchAdmissionList("0", userID);
                actions.resetForm()
                setIsStatusVisible(false)
            } else {
                toast.error("Failed to add admission", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching course:', error);
        }
    }

    const handleUniversityEdit = () => {
        setInitialEditValues({
            universities: appliedUnivercity.map((university: any) => ({
                doc_applied_id: university.applied_university_id || '',
                doc_applied_university: university.applied_university_name || '',
                doc_applied_status: university.applied_status || ''
            })),
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsUnivercityVisible(true)
    }

    const handleDocEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsDocVisible(true);
    };

    const handleOfferDocEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsOfferDocVisible(true)
    }

    const handleFundEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsFundVisible(true)
    }

    const handleLoanEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsLoanDocVisible(true)
    }

    const handleDepositEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsDepositDocVisible(true)
    }

    const handleCASEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsCasVisible(true)
    }

    const handleAdmissionEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsStatusVisible(true)
    }

    const handleVisaEdit = () => {
        setInitialEditValues({
            universities: [
                { doc_applied_id: '', doc_applied_university: '', doc_applied_status: '' }
            ],
            doc_app_process: admissionView.admission_doc_process_type,
            doc_offer_letter: admissionView.admission_offer_letter,
            doc_funding_method: admissionView.admission_fund_method,
            doc_loan_process: admissionView.admission_loan,
            doc_loan_process_type: admissionView.admission_loan_process_type,
            doc_admission_status: admissionView.admission_status,
            doc_deposit_amount: admissionView.admission_deposit_amount,
            doc_deposit_date: admissionView.admission_deposit_date,
            doc_visa_status: admissionView.admission_visa_status,
            doc_cas_status: admissionView.admission_cas_status,
        })
        setIsVisaVisible(true)
    } 

    return (
        <>
            <div className="offcanvas-header">
                <h3 id="offcanvasRightLabel">Admission Details :</h3>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
            </div>
            <div className="offcanvas-body">
                <div className='card'>
                    <div className='card-header border-0'>
                        <div className='d-flex align-items-center p-5'>
                            <div className="symbol symbol-circle symbol-75px">
                                <img src={admissionView.admission_student_pic ? admissionView.admission_student_pic : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                            </div>
                            <div>
                                <h3> {admissionView.admission_student_name} </h3>
                                <h6>  Exam Status- {admissionView.admission_exam_status === '1' ? (
                                    <span className='badge badge-primary text-white'>Exam Cleared</span>
                                ) : admissionView.admission_exam_status === '2' ? (
                                    <span className='badge badge-dark text-white'>Not Cleared</span>
                                ) : (
                                    ""
                                )}
                                </h6>
                            </div>
                        </div>

                        <div className='card-toolbar'>
                            {admissionView.admission_status === '1' ? (
                                <span className='badge bg_badge_color2 text-white'>Joined</span>
                            ) : admissionView.admission_status === '2' ? (
                                <span className='badge  bg_badge_color1 text-white'>On Going</span>
                            ) : admissionView.admission_status === '3' ? (
                                <span className='badge bg-danger text-white'>Cancelled</span>
                            ) : admissionView.admission_status === '4' ? (
                                <span className='badge badge-success text-white'>Completed</span>
                            ) :''}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-3 card contact-sidebar mt-5'>
                        <ul className="nav nav-tabs flex-column nav-line-tabs nav-line-tabs-2x fs-6">
                            <li className="nav-item ms-3">
                                <a
                                    className="nav-link active text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#student_details"
                                    id="document_sub"
                                >
                                    <KTIcon iconName='briefcase' className='fs-3 me-2 text-primary' /> Student Information
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#applied_univercity"
                                >
                                    <KTIcon iconName='teacher' className='fs-3 me-2 text-primary' /> Applied University
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#document_submission"
                                >
                                    <KTIcon iconName='document' className='fs-3 me-2 text-primary' /> Document Submission
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#offer_letter"
                                >
                                    <KTIcon iconName='book-open' className='fs-3 me-2 text-primary' /> Offer Letter
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#funding_method"
                                >
                                    <KTIcon iconName='note-2' className='fs-3 me-2 text-primary' /> Funding Method
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#loan_process"
                                >
                                    <KTIcon iconName='price-tag' className='fs-3 me-2 text-primary' /> Loan Process
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#deposit"
                                >
                                    <KTIcon iconName='bill' className='fs-2 me-2 text-primary' /> Deposit Paid
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#cas"
                                >
                                    <KTIcon iconName='clipboard' className='fs-3 me-2 text-primary' /> CAS / i20
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#visa"
                                >
                                    <KTIcon iconName='airplane' className='fs-3 me-2 text-primary' /> Visa Status
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-dark d-flex align-items-center mb-2"
                                    data-bs-toggle="tab"
                                    href="#status_complted"
                                >
                                    <KTIcon iconName='shield-tick' className='fs-3 me-2 text-primary' /> Admission Status
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-9 tab-content" id="myTabContent">
                        <div className="tab-pane fade show active"
                            id="student_details"
                            role="tabpanel"
                        >
                            <div className="card mt-5">
                                <div className='card-body'>
                                    <div className='d-flex justify-content-between mb-7'>
                                        <h3 className=''>Student Information</h3>
                                    </div>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className='col-md-5'>
                                                    <h6 className='mb-5'>ID</h6>
                                                    <h6 className='mb-5'>Name</h6>
                                                    <h6 className='mb-5'>Source</h6>
                                                    <h6 className='mb-5'>Phone Number</h6>
                                                    <h6 className='mb-5'>Email Address</h6>
                                                    <h6 className='mb-5'>Country</h6>
                                                    <h6 className='mb-5'>Course </h6>
                                                    <h6 className='mb-5'>Date Created</h6>
                                                </div>
                                                <div className='col-md-7'>
                                                    <h6 className='mb-5'>{admissionView?.admission_id ? admissionView?.admission_id : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView?.admission_student_name ? admissionView?.admission_student_name : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_source_from ? admissionView.admission_source_from : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_student_mobile ? admissionView.admission_student_mobile : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_student_email ? admissionView.admission_student_email : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_country_interested ? admissionView.admission_country_interested === "Others" ? admissionView.admission_other_country : admissionView.admission_country_interested : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_course_intereseted ? admissionView.admission_course_intereseted : "-"}</h6>
                                                    <h6 className='mb-5'>{admissionView.admission_created_on ? admissionView.admission_created_on : "-"}</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <h5 className='mb-5 text-gray-500'>Processing Team</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <h5> {admissionView?.admission_processing_team ? admissionView?.admission_processing_team : "-"} </h5>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <h5 className='mb-5 text-gray-500'>Lead Counselor</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <h5> {admissionView?.admission_lead_from ? admissionView?.admission_lead_from : "-"} </h5>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <h5 className='mb-5 text-gray-500'>Agent</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <h5> {admissionView?.admission_processing_team ? admissionView?.admission_processing_team : "-"} </h5>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <h5 className='mb-5 text-gray-500'>Created By</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="symbol symbol-circle symbol-35px">
                                                            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                                        </div>
                                                        <div>
                                                            <h5> {admissionView.admission_created_by} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade"
                            id="document_submission"
                            role="tabpanel"
                        >
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (documentsData && documentsData.length > 0 && documentsData.some((file: any) => file.doc_type === "general_documents") ? (
                                        <>
                                            {isDocVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsDocVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} onSubmit={handleSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <div className="modal-body">
                                                                    <div className='form-group mb-3'>
                                                                        <label className='form-label mb-3'>
                                                                            Application Processing
                                                                        </label>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className="form-check form-check-custom form-check-solid ">
                                                                                <Field
                                                                                    type="radio"
                                                                                    name="doc_app_process"
                                                                                    value="1"
                                                                                    id="sop"
                                                                                    className="form-check-input"
                                                                                />
                                                                                <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="sop">SOP</label>
                                                                            </div>
                                                                            <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                                <Field
                                                                                    type="radio"
                                                                                    name="doc_app_process"
                                                                                    value="2"
                                                                                    id="lor"
                                                                                    className="form-check-input"
                                                                                />
                                                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="lor">LOR</label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name='doc_app_process' component="div" className="text-danger" />
                                                                    </div>
                                                                    <div className='form-group mb-3'>
                                                                        <div className='mt-5'>
                                                                            <label className="form-label mb-5">Document Submission (Upload Documents)</label>
                                                                            <label className="file-attachment text-primary">
                                                                                <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                                <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                            </label>
                                                                        </div>
                                                                        {uploadError.length > 0 && (
                                                                            <div className="text-danger mt-5">
                                                                                {uploadError.map((error: any, index: any) => (
                                                                                    <div key={index}>{error}</div>
                                                                                ))}
                                                                            </div>
                                                                        )}

                                                                        {images.length > 0 ? (
                                                                            <table className="table table-hover table-sm">
                                                                                <thead>
                                                                                    <tr className='fw-bold fs-6'>
                                                                                        <th className='ps-8'>File Name</th>
                                                                                        <th className='text-center'> Size</th>
                                                                                        <th className='text-center'>Remove</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {images.map((image: any, index: any) => (
                                                                                        <tr key={index}>
                                                                                            <td className='ps-8'>
                                                                                                {image.file.name}
                                                                                            </td>
                                                                                            <td className='text-center'>
                                                                                                {formatFileSize(image.file.size)}
                                                                                            </td>
                                                                                            <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                                <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        ) : <></>}
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Manage Documents :</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleDocEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {admissionView.admission_doc_process_type == "1" || admissionView.admission_doc_process_type == "2" ? (
                                                        <>
                                                            <span className='fw-bold fs-5'>Application Processing :<span className='text-primary'> {admissionView.admission_doc_process_type == "1" ? "SOP" : admissionView.admission_doc_process_type == "2" ? "LOR" : ''} </span></span>
                                                        </>
                                                    ) : ""}
                                                    <table className="table table-hover table-sm mt-3">
                                                        <thead>
                                                            <tr className='fw-bold fs-6'>
                                                                <th>File Name</th>
                                                                <th className='text-center'>Download</th>
                                                                <th className='text-center'>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {documentsData
                                                                .filter((file: any) => file.doc_type === "general_documents")
                                                                .map((file: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{getFileName(file.document_url)}</td>
                                                                        <td className='text-center' onClick={() => handleFileDownload(file.document_url, getFileName(file.document_url))}>
                                                                            <i className="bi bi-download btn btn-light-primary btn-sm p-2 fs-1" />
                                                                        </td>
                                                                        <td className='text-center text-hover-primary'>
                                                                            <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <DocumentForm loading={loading} handleImageChange={handleImageChange} initialValues={initialValues} uploadError={uploadError}
                                            images={images} setImages={setImages} formatFileSize={formatFileSize} handleRemoveImage={handleRemoveImage} admissionView={admissionView}
                                            userID={userID} handleSubmit={handleSubmit}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="applied_univercity" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (appliedUnivercity.length > 0 ? (
                                        <>
                                            {isUnvercityVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsUnivercityVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} validationSchema={UniversityvalidationSchema} onSubmit={handleUniversitySubmit}>
                                                        {({ values, errors }) => (
                                                            <Form>
                                                                <FieldArray name="universities">
                                                                    {({ remove, push }) => (
                                                                        <>
                                                                            <div className="form-group">
                                                                                <input type="hidden" name="doc_applied_id" className="form-control" />
                                                                            </div>
                                                                            <div className='d-flex justify-content-between mb-5'>
                                                                                <label className="form-label mb-3 required">Applied University </label>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-sm"
                                                                                    onClick={() => {
                                                                                        if (values.universities.length < 5) {
                                                                                            push({ doc_applied_university: '', doc_applied_status: '' });
                                                                                        } else {
                                                                                            alert('You can add a maximum of 5 universities.');
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    Add University
                                                                                </button>
                                                                            </div>
                                                                            {values.universities && values.universities.length > 0 &&
                                                                                values.universities.map((university, index) => (
                                                                                    <div className="input-group form-group mb-5" key={index}>
                                                                                        <Field
                                                                                            name={`universities.${index}.doc_applied_university`}
                                                                                            type="text"
                                                                                            className="form-control form-control-sm w-50"
                                                                                            placeholder="Applied University"
                                                                                        />
                                                                                        <Field
                                                                                            as="select"
                                                                                            name={`universities.${index}.doc_applied_status`}
                                                                                            className="form-select form-select-sm"
                                                                                        >
                                                                                            <option value="">Select..</option>
                                                                                            <option value="Applied">Applied</option>
                                                                                            <option value="All Documents Received">All Documents Received</option>
                                                                                            <option value="Rejected">Rejected</option>
                                                                                            <option value="Finalized">Finalized</option>
                                                                                            <option value="Dropped">Dropped</option>
                                                                                        </Field>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            onClick={() => remove(index)}
                                                                                        >
                                                                                            X
                                                                                        </button>
                                                                                        {errors.universities && errors.universities[index] && (
                                                                                            <div className="invalid-feedback d-block">
                                                                                                {(errors.universities[index] as FormikErrors<University>).doc_applied_university || (errors.universities[index] as FormikErrors<University>).doc_applied_status}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                ))}
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                                {values.universities && values.universities.length > 0 &&
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className="indicator-progress" style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'> Applied University Details :</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleUniversityEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                        <table className="table table-hover table-sm">
                                                            <thead>
                                                                <tr className='fw-bold fs-6'>
                                                                    <th>S.No</th>
                                                                    <th>Unversity Name</th>
                                                                    <th>Status</th>
                                                                    <th>Applied On</th>
                                                                    <th>Applied By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {appliedUnivercity.length > 0 ? (
                                                                    appliedUnivercity.map((item: any, index: any) => (
                                                                        <tr key={index}>
                                                                            <td> {item.applied_university_id}</td>
                                                                            <td> {item.applied_university_name ? item.applied_university_name : "-"}</td>
                                                                            {/* <td>{item.applied_status ? item.applied_status : "-"}</td> */}
                                                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                                                {item.applied_status === 'Finalized' ? (
                                                                                    <span className='badge bg_badge_color3 p-2 text-white'>Finalized</span>
                                                                                ) : item.applied_status === 'Rejected' ? (
                                                                                    <span className='badge bg-danger p-2 text-white'>Rejected</span>
                                                                                ) : item.applied_status === 'All Documents Received' ? (
                                                                                    <span className='badge bg-info p-2 text-white'>All Documents Received</span>
                                                                                ) : item.applied_status === 'Applied' ? (
                                                                                    <span className='badge bg_badge_color3 p-2 text-white'>Applied</span>
                                                                                ) : item.applied_status === 'Dropped' ? (
                                                                                    <span className='badge bg_badge_color1 text-white'>	Dropped</span>
                                                                                ) : (
                                                                                    <>-</>
                                                                                )}
                                                                            </td>
                                                                            <td>{item.applied_on}</td>
                                                                            <td>{item.applied_by}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : ""}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="form-group mb-3">
                                            <Formik initialValues={initialValues} validationSchema={UniversityvalidationSchema} onSubmit={handleUniversitySubmit}>
                                                {({ values, errors }) => (
                                                    <Form>
                                                        <FieldArray name="universities">
                                                            {({ remove, push }) => (
                                                                <>
                                                                    <div className='d-flex justify-content-between mb-5'>
                                                                        <label className="form-label mb-3 required">Applied University </label>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm"
                                                                            onClick={() => {
                                                                                if (values.universities.length < 5) {
                                                                                    push({ doc_applied_university: '', doc_applied_status: '' });
                                                                                } else {
                                                                                    alert('You can add a maximum of 5 universities.');
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add University
                                                                        </button>
                                                                    </div>
                                                                    {values.universities && values.universities.length > 0 &&
                                                                        values.universities.map((university, index) => (
                                                                            <div className="input-group form-group mb-5" key={index}>
                                                                                <Field
                                                                                    name={`universities.${index}.doc_applied_university`}
                                                                                    type="text"
                                                                                    className="form-control form-control-sm w-50"
                                                                                    placeholder="Applied University"
                                                                                />
                                                                                <Field
                                                                                    as="select"
                                                                                    name={`universities.${index}.doc_applied_status`}
                                                                                    className="form-select form-select-sm"
                                                                                >
                                                                                    <option value="">Select..</option>
                                                                                    <option value="Applied">Applied</option>
                                                                                    <option value="All Documents Received">All Documents Received</option>
                                                                                    <option value="Rejected">Rejected</option>
                                                                                    <option value="Finalized">Finalized</option>
                                                                                    <option value="Dropped">Dropped</option>
                                                                                </Field>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-sm"
                                                                                    onClick={() => remove(index)}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                                {errors.universities && errors.universities[index] && (
                                                                                    <div className="invalid-feedback d-block">
                                                                                        {(errors.universities[index] as FormikErrors<University>).doc_applied_university || (errors.universities[index] as FormikErrors<University>).doc_applied_status}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                        {values.universities && values.universities.length > 0 &&
                                                            <div className="d-flex justify-content-end mt-3">
                                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                                    {!loading && 'Submit'}
                                                                    {loading && (
                                                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                                                            Please wait...{' '}
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        }
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="offer_letter" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_offer_letter != '' || documentsData && documentsData.length > 0 && documentsData.some((file: any) => file.doc_type === "offer_document") ? (
                                        <>
                                            {isOfferDocVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsOfferDocVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} validationSchema={validationSchema} onSubmit={handleOfferLetterSubmit}>
                                                        {({ values }) => (
                                                            <Form>
                                                                <div className='form-group mb-3'>
                                                                    <label className='form-label mb-3'>
                                                                        Condition Offer Letter
                                                                    </label>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className="form-check form-check-custom form-check-solid ">
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_offer_letter"
                                                                                value="1"
                                                                                id="yes"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="yes">Conditional Offer</label>
                                                                        </div>
                                                                        <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_offer_letter"
                                                                                value="2"
                                                                                id="no"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="no">Unconditional Offer</label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorMessage name='doc_offer_letter' component="div" className="text-danger" />
                                                                </div>
                                                                {values.doc_offer_letter == "1" && (
                                                                    <div className='form-group mb-3'>
                                                                        <div className='mt-5'>
                                                                            <label className="form-label mb-5">Upload Offer Letter</label>
                                                                            <label className="file-attachment text-primary">
                                                                                <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                                <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                            </label>
                                                                        </div>
                                                                        {uploadError.length > 0 && (
                                                                            <div className="text-danger mt-5">
                                                                                {uploadError.map((error: any, index: any) => (
                                                                                    <div key={index}>{error}</div>
                                                                                ))}
                                                                            </div>
                                                                        )}

                                                                        {images.length > 0 ? (
                                                                            <table className="table table-hover table-sm">
                                                                                <thead>
                                                                                    <tr className='fw-bold fs-6'>
                                                                                        <th className='ps-8'>File Name</th>
                                                                                        <th className='text-center'> Size</th>
                                                                                        <th className='text-center'>Remove</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {images.map((image: any, index: any) => (
                                                                                        <tr key={index}>
                                                                                            <td className='ps-8'>
                                                                                                {image.file.name}
                                                                                            </td>
                                                                                            <td className='text-center'>
                                                                                                {formatFileSize(image.file.size)}
                                                                                            </td>
                                                                                            <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                                <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        ) : <></>}
                                                                    </div>
                                                                )}
                                                                <div className='d-flex justify-content-end'>
                                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                        {!loading && 'Submit'}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                Please wait...{' '}
                                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Condition Offer Letter :</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleOfferDocEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {admissionView.admission_offer_letter == "1" || admissionView.admission_offer_letter == "2" ? (
                                                        <>
                                                            Offer Letter : <span className='fw-bold'> {admissionView.admission_offer_letter == "1" ? "Conditional Offer" : admissionView.admission_offer_letter == "2" ? "Unconditional Offer" : ''} </span>
                                                        </>
                                                    ) : ""}
                                                 {documentsData && documentsData.length > 0 && documentsData.some((file: any) => file.doc_type === "offer_document")
                                                        ? (

                                                        <table className="table table-hover table-sm">
                                                            <thead>
                                                                <tr className='fw-bold fs-6'>
                                                                    <th>File Name</th>
                                                                    <th className='text-center'>Download</th>
                                                                    <th className='text-center'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {documentsData
                                                                    .filter((file: any) => file.doc_type === "offer_document")
                                                                    .map((file: any, index: any) => (
                                                                        <tr key={index}>
                                                                            <td>{getFileName(file.document_url)}</td>
                                                                            <td className='text-center' onClick={() => handleFileDownload(file.document_url, getFileName(file.document_url))}>
                                                                                <i className="bi bi-download btn btn-light-primary btn-sm p-2 fs-1" />
                                                                            </td>
                                                                            <td className='text-center text-hover-primary'>
                                                                                <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                        ) : ""
                                                    }
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOfferLetterSubmit}>
                                            {({ values }) => (
                                                <Form>
                                                    <div className="modal-body">
                                                        <div className='form-group mb-3'>
                                                            <label className='form-label mb-3 required'>
                                                                Offer Letter
                                                            </label>
                                                            <div className='d-flex align-items-center'>
                                                                <div className="form-check form-check-custom form-check-solid ">
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_offer_letter"
                                                                        value="1"
                                                                        id="yes"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="yes">Conditional Offer</label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_offer_letter"
                                                                        value="2"
                                                                        id="no"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="no">Unconditional Offer</label>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name='doc_offer_letter' component="div" className="text-danger" />
                                                        </div>
                                                        {values.doc_offer_letter == "1" && (
                                                            <div className='form-group mb-3'>
                                                                <div className='mt-5'>
                                                                    <label className="form-label mb-5">Upload Offer Letter</label>
                                                                    <label className="file-attachment text-primary">
                                                                        <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                        <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                    </label>
                                                                </div>
                                                                {uploadError.length > 0 && (
                                                                    <div className="text-danger mt-5">
                                                                        {uploadError.map((error: any, index: any) => (
                                                                            <div key={index}>{error}</div>
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                {images.length > 0 ? (
                                                                    <table className="table table-hover table-sm">
                                                                        <thead>
                                                                            <tr className='fw-bold fs-6'>
                                                                                <th className='ps-8'>File Name</th>
                                                                                <th className='text-center'> Size</th>
                                                                                <th className='text-center'>Remove</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {images.map((image: any, index: any) => (
                                                                                <tr key={index}>
                                                                                    <td className='ps-8'>
                                                                                        {image.file.name}
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {formatFileSize(image.file.size)}
                                                                                    </td>
                                                                                    <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                        <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : <></>}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="modal-footer">
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="funding_method" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_fund_method != '' ? (
                                        <>
                                            {isFundVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsFundVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} validationSchema={FundingvalidationSchema} onSubmit={handleFundSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <div className="modal-body">
                                                                    <div className="form-group mb-3">
                                                                        <Field as="select" name="doc_funding_method" aria-label="Select example" className="form-select form-select-sm mb-5">
                                                                            <option value="">Select method</option>
                                                                            <option value="Self fund">Self fund</option>
                                                                            <option value="Education Loan">Education Loan</option>
                                                                        </Field>
                                                                        <ErrorMessage name="doc_funding_method" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Fund method:</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleFundEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                        Fund method : <span className='fw-bold'> {admissionView.admission_fund_method ? admissionView.admission_fund_method : '-'} </span>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="form-group mb-3">
                                            <Formik initialValues={initialValues} validationSchema={FundingvalidationSchema} onSubmit={handleFundSubmit}>
                                                {() => (
                                                    <Form>
                                                        <div className="modal-body">
                                                            <div className="form-group mb-3">
                                                                <Field as="select" name="doc_funding_method" aria-label="Select example" className="form-select form-select-sm mb-5">
                                                                    <option value="">Select method</option>
                                                                    <option value="Self fund">Self fund</option>
                                                                    <option value="Education Loan">Education Loan</option>
                                                                </Field>
                                                                <ErrorMessage name="doc_funding_method" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <div className='d-flex justify-content-end'>
                                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                    {!loading && 'Submit'}
                                                                    {loading && (
                                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                                            Please wait...{' '}
                                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="deposit" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_deposit_amount != '' ? (
                                        <>
                                            {isDepositDocVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsDepositDocVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} onSubmit={handleDepositSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <div className="modal-body">
                                                                    <div className="form-group mb-3">
                                                                        <label className="form-label mb-3" htmlFor="doc_deposit_amount">Deposit Amount</label>
                                                                        <Field type="number" name="doc_deposit_amount" className="form-control form-control-sm" />
                                                                        <ErrorMessage name="doc_deposit_amount" component="div" className="text-danger" />
                                                                    </div>
                                                                    <div className="form-group mb-5">
                                                                        <label htmlFor="doc_deposit_date" className="form-label mb-3 required">Date of Payment</label>
                                                                        <Field name="doc_deposit_date" type="date" className="form-control form-control-sm" />
                                                                        <ErrorMessage name="doc_deposit_date" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Deposit Amount:</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleDepositEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                        <div> Deposit Amount : <span className='fw-bold'> {admissionView.admission_deposit_amount ? admissionView.admission_deposit_amount : '-'} </span></div>
                                                        <div className='mt-5'> Date of Payment : <span className='fw-bold'> {admissionView.admission_deposit_date ? admissionView.admission_deposit_date : '-'} </span></div>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <Formik initialValues={initialValues} validationSchema={DepositvalidationSchema} onSubmit={handleDepositSubmit}>
                                            {() => (
                                                <Form>
                                                    <div className="modal-body">
                                                        <div className="modal-body">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label mb-3 required" htmlFor="doc_deposit_amount">Deposit Amount</label>
                                                                <Field type="number" name="doc_deposit_amount" className="form-control form-control-sm" />
                                                                <ErrorMessage name="doc_deposit_amount" component="div" className="text-danger" />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="doc_deposit_date" className="form-label mb-3 required">Date of Payment</label>
                                                                <Field name="doc_deposit_date" type="date" className="form-control form-control-sm" />
                                                                <ErrorMessage name="doc_deposit_date" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal-footer">
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="loan_process" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (documentsData && documentsData.length > 0 && documentsData.some((file: any) => file.doc_type === "loan_document") ? (
                                        <>
                                            {isLoanDocVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsLoanDocVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} onSubmit={handleLoanSubmit}>
                                                        {({ values }) => (
                                                            <Form>
                                                                <div className="modal-body">
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="doc_loan_process_type" className="form-label mb-3 required">Loan Process Status</label>
                                                                        <Field as="select" name="doc_loan_process_type" aria-label="Select example" className="form-select form-select-sm">
                                                                            <option value="">Select Loan Process</option>
                                                                            <option value="Documents Submitted">Documents Submitted</option>
                                                                            <option value="Loan Offered"> Loan Offered</option>
                                                                            <option value="Started"> Started</option>
                                                                            <option value="Loan Approved"> Loan Approved</option>
                                                                            <option value="Rejected"> Rejected</option>
                                                                        </Field>
                                                                        <ErrorMessage name="doc_loan_process_type" component="div" className="text-danger" />
                                                                    </div>
                                                                    <div className='form-group mb-3'>
                                                                        <label className='form-label mb-3'>
                                                                            Loan Processing
                                                                        </label>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className="form-check form-check-custom form-check-solid ">
                                                                                <Field
                                                                                    type="radio"
                                                                                    name="doc_loan_process"
                                                                                    value="1"
                                                                                    id="loan_yes"
                                                                                    className="form-check-input"
                                                                                />
                                                                                <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="loan_yes">Yes</label>
                                                                            </div>
                                                                            <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                                <Field
                                                                                    type="radio"
                                                                                    name="doc_loan_process"
                                                                                    value="2"
                                                                                    id="loan_no"
                                                                                    className="form-check-input"
                                                                                />
                                                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="loan_no">No</label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name='doc_loan_process' component="div" className="text-danger" />
                                                                    </div>

                                                                    {values.doc_loan_process == "1" ? (
                                                                        <>
                                                                            <div className='form-group mb-3'>
                                                                                <div className='mt-5'>
                                                                                    <label className="form-label mb-5">Upload Loan Document</label>
                                                                                    <label className="file-attachment text-primary">
                                                                                        <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                                        <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                                    </label>
                                                                                </div>
                                                                                {uploadError.length > 0 && (
                                                                                    <div className="text-danger mt-5">
                                                                                        {uploadError.map((error: any, index: any) => (
                                                                                            <div key={index}>{error}</div>
                                                                                        ))}
                                                                                    </div>
                                                                                )}

                                                                                {images.length > 0 ? (
                                                                                    <table className="table table-hover table-sm">
                                                                                        <thead>
                                                                                            <tr className='fw-bold fs-6'>
                                                                                                <th className='ps-8'>File Name</th>
                                                                                                <th className='text-center'> Size</th>
                                                                                                <th className='text-center'>Remove</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {images.map((image: any, index: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td className='ps-8'>
                                                                                                        {image.file.name}
                                                                                                    </td>
                                                                                                    <td className='text-center'>
                                                                                                        {formatFileSize(image.file.size)}
                                                                                                    </td>
                                                                                                    <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                                        <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                ) : <></>}
                                                                            </div>
                                                                        </>
                                                                    ) : ""} 
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Loan Processing :</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleLoanEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {admissionView.admission_loan === "1" || admissionView.admission_loan === "2" ? (
                                                        <>
                                                            <div>  Loan Processing : <span className='fw-bold'> {admissionView.admission_loan == "1" ? "Yes" : admissionView.admission_loan == "2" ? "No" : ''} </span></div>
                                                            <div className='mt-5'> Loan Process Status : <span className='fw-bold'> {admissionView.admission_loan_process_type ? admissionView.admission_loan_process_type : ''} </span></div>
                                                        </>
                                                    ) : ''}
                                                    <table className="table table-hover table-sm">
                                                        <thead>
                                                            <tr className='fw-bold fs-6'>
                                                                <th>File Name</th>
                                                                <th className='text-center'>Download</th>
                                                                <th className='text-center'>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {documentsData
                                                                .filter((file: any) => file.doc_type === "loan_document")
                                                                .map((file: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td>{getFileName(file.document_url)}</td>
                                                                        <td className='text-center' onClick={() => handleFileDownload(file.document_url, getFileName(file.document_url))}>
                                                                            <i className="bi bi-download btn btn-light-primary btn-sm p-2 fs-1" />
                                                                        </td>
                                                                        <td className='text-center text-hover-primary'>
                                                                            <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <Formik initialValues={initialValues} validationSchema={LoanvalidationSchema} onSubmit={handleLoanSubmit}>
                                            {({ values }) => (
                                                <Form>
                                                    <div className="modal-body">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="doc_loan_process_type" className="form-label mb-3 required">Loan Process Status</label>
                                                            <Field as="select" name="doc_loan_process_type" aria-label="Select example" className="form-select form-select-sm">
                                                                <option value="">Select Loan Process</option>
                                                                <option value="Documents Submitted">Documents Submitted</option>
                                                                <option value="Loan Offered"> Loan Offered</option>
                                                                <option value="Started"> Started</option>
                                                                <option value="Loan Approved"> Loan Approved</option>
                                                                <option value="Rejected"> Rejected</option>
                                                            </Field>
                                                            <ErrorMessage name="doc_loan_process_type" component="div" className="text-danger" />
                                                        </div>
                                                        <div className='form-group mb-3'>
                                                            <label className='form-label mb-3 required'>
                                                                Loan Processing
                                                            </label>
                                                            <div className='d-flex align-items-center'>
                                                                <div className="form-check form-check-custom form-check-solid ">
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_loan_process"
                                                                        value="1"
                                                                        id="loan_yes"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="loan_yes">Yes</label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_loan_process"
                                                                        value="2"
                                                                        id="loan_no"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="loan_no">No</label>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name='doc_loan_process' component="div" className="text-danger" />
                                                        </div>

                                                        {values.doc_loan_process == "1" ? (
                                                            <>
                                                                <div className='form-group mb-3'>
                                                                    <div className='mt-5'>
                                                                        <label className="form-label mb-5">Upload Loan Document</label>
                                                                        <label className="file-attachment text-primary">
                                                                            <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                                                            <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                        </label>
                                                                    </div>
                                                                    {uploadError.length > 0 && (
                                                                        <div className="text-danger mt-5">
                                                                            {uploadError.map((error: any, index: any) => (
                                                                                <div key={index}>{error}</div>
                                                                            ))}
                                                                        </div>
                                                                    )}

                                                                    {images.length > 0 ? (
                                                                        <table className="table table-hover table-sm">
                                                                            <thead>
                                                                                <tr className='fw-bold fs-6'>
                                                                                    <th className='ps-8'>File Name</th>
                                                                                    <th className='text-center'> Size</th>
                                                                                    <th className='text-center'>Remove</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {images.map((image: any, index: any) => (
                                                                                    <tr key={index}>
                                                                                        <td className='ps-8'>
                                                                                            {image.file.name}
                                                                                        </td>
                                                                                        <td className='text-center'>
                                                                                            {formatFileSize(image.file.size)}
                                                                                        </td>
                                                                                        <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                                                            <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    ) : <></>}
                                                                </div>
                                                            </>
                                                        ) : ""} 
                                                    </div>
                                                    <div className="modal-footer">
                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="status_complted" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_status != '' ? (
                                        <>
                                            {isStatusVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsStatusVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} validationSchema={AdmissionStatusSchema} onSubmit={handleAdmissionSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <div className='form-group mb-3'>
                                                                    <label className='form-label mb-3 required'>
                                                                        Admission Status
                                                                    </label>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className="form-check form-check-custom form-check-solid ">
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_admission_status"
                                                                                value="1"
                                                                                id="completed"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="completed">Completed</label>
                                                                        </div>
                                                                        <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_admission_status"
                                                                                value="0"
                                                                                id="not"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="not">Not completed</label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorMessage name='doc_admission_status' component="div" className="text-danger" />
                                                                </div>
                                                                <div className='d-flex justify-content-end mt-5'>
                                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                        {!loading && 'Submit'}
                                                                        {loading && (
                                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                Please wait...{' '}
                                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                            </span>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Admission Status :</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleAdmissionEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                        {admissionView.admission_status == "1" ? (
                                                            <div className="d-flex justify-content-center p-10">
                                                                <div>
                                                                    <img src={toAbsoluteUrl('/media/stock/completed.png')} className='w-250px' />
                                                                    <h3 className="text-center mt-5">Admission Completed</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex justify-content-center p-10">
                                                                <div>
                                                                    <img src={toAbsoluteUrl('/media/stock/not_completed.png')} className='w-350px' />
                                                                    <h3 className="text-center">Admission Not Completed</h3>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="form-group mb-3">
                                            <Formik initialValues={initialValues} validationSchema={AdmissionStatusSchema} onSubmit={handleAdmissionSubmit}>
                                                {() => (
                                                    <Form>
                                                        <div className='form-group mb-3'>
                                                            <label className='form-label mb-3 required'>
                                                                Admission Status
                                                            </label>
                                                            <div className='d-flex align-items-center'>
                                                                <div className="form-check form-check-custom form-check-solid ">
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_admission_status"
                                                                        value="1"
                                                                        id="completed"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="completed">Completed</label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                    <Field
                                                                        type="radio"
                                                                        name="doc_admission_status"
                                                                        value="0"
                                                                        id="not"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="not">Not completed</label>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name='doc_admission_status' component="div" className="text-danger" />
                                                        </div>
                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="cas" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_cas_status != '' ? (
                                        <>
                                            {isCasVisible ? (
                                                <div>
                                                    <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsCasVisible(false)}>
                                                        Back
                                                    </button>
                                                    <Formik initialValues={initialEditValues} validationSchema={CASvalidationSchema} onSubmit={handleCASSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <div className="modal-body">
                                                                    <div className='d-flex align-items-center mb-5'>
                                                                        <div className="form-check form-check-custom form-check-solid ">
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_cas_status"
                                                                                value="Received"
                                                                                id="received"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="received">Received</label>
                                                                        </div>
                                                                        <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                            <Field
                                                                                type="radio"
                                                                                name="doc_cas_status"
                                                                                value="Not yet"
                                                                                id="yet"
                                                                                className="form-check-input"
                                                                            />
                                                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="yet">Not yet</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>CAS i/20:</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleCASEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                       <span className='fw-bold fs-4'> {admissionView.admission_cas_status ? admissionView.admission_cas_status : '-'} </span>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="form-group mb-3">
                                            <label htmlFor="doc_visa_status" className="form-label mb-3 required">CAS/I-20 </label>
                                            <Formik initialValues={initialValues} validationSchema={CASvalidationSchema} onSubmit={handleCASSubmit}>
                                                {() => (
                                                    <Form>
                                                        <div className='d-flex align-items-center'>
                                                            <div className="form-check form-check-custom form-check-solid ">
                                                                <Field
                                                                    type="radio"
                                                                    name="doc_cas_status"
                                                                    value="Received"
                                                                    id="received"
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="received">Received</label>
                                                            </div>
                                                            <div className='form-check form-check-custom form-check-solid ms-5'>
                                                                <Field
                                                                    type="radio"
                                                                    name="doc_cas_status"
                                                                    value="Not yet"
                                                                    id="yet"
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="yet">Not yet</label>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name='doc_cas_status' component="div" className="text-danger" />
                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="visa" role="tabpanel">
                            <div className="card mt-5">
                                <div className='card-body'>
                                    {loadingView ? (
                                        <Loader />
                                    ) : (admissionView.admission_visa_status != '' ? (
                                        <>
                                            {isVisaVisible ? (
                                                <div>
                                                    <Formik initialValues={initialEditValues} validationSchema={VisavalidationSchema} onSubmit={handleVisaSubmit}>
                                                        {() => (
                                                            <Form>
                                                                <button className='btn btn-sm btn-secondary mb-5' onClick={() => setIsVisaVisible(false)}>
                                                                    Back
                                                                </button>
                                                                <div className="modal-body">
                                                                    <div className="form-group mb-3">
                                                                        <Field as="textarea" name="doc_visa_status" type="text" className="form-control form-control-sm" />
                                                                        <ErrorMessage name="doc_visa_status" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                            {!loading && 'Submit'}
                                                                            {loading && (
                                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                                    Please wait...{' '}
                                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className="mb-10">
                                                            <h5 className='fw-bolder'>Visa Status:</h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-sm btn-primary' onClick={handleVisaEdit}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <>
                                                        <span className='fw-bold fs-4'> {admissionView.admission_visa_status ? admissionView.admission_visa_status : '-'} </span>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="form-group mb-3">
                                            <label htmlFor="doc_visa_status" className="form-label mb-3 required">Visa Status</label>
                                            <Formik initialValues={initialValues} validationSchema={VisavalidationSchema} onSubmit={handleVisaSubmit}>
                                                {() => (
                                                    <Form>
                                                        <Field as="textarea" name="doc_visa_status" type="text" className="form-control form-control-sm" />
                                                        <ErrorMessage name="doc_visa_status" component="div" className="text-danger" />
                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AdmissionView