import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import CourseForm from './CourseForm'
import { COURSE_DELETE_DATA, GET_ALL_COURSE, COURSE_EDIT_DATA } from '../auth/core/_requests'
import { CourseDetails } from '../../pages/Interface'
import { Image } from '../../pages/Interface'
import { CourseMaterial } from '../../pages/Interface'
import Swal from 'sweetalert2'
import Pagination from "../../pages/Pagination"
import Loader from '../../pages/Loader'
import CourseEdit from './CourseEdit'
import toast, { Toaster } from "react-hot-toast";

const Courses = () => {
    const [courseModal, setCourseModal] = useState(false)
    const [refreshCourse, setRefreshCourse] = useState(false);
    const [courseData, setCourseData] = useState<CourseDetails[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [filesData, setFilesData] = useState<CourseMaterial[]>([]);
    const [uploadError, setUploadError] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredMembers, setFilteredMembers] = useState<CourseDetails[]>([]);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [initialEditValues, setInitialEditValues] = useState(
        {
            course_id_upd: '',
            course_name_upd: '',
            course_fee_upd: '',
            course_type_upd: '',
            course_remarks_upd: ''
        }
    )

    const handleCourseModal = () => {
        setCourseModal(true)
    }

    const fetchCourseData = async (all_courses: any) => {
        try {
            const response = await GET_ALL_COURSE(all_courses);
            setCourseData(response.data.course_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    useEffect(() => {
        fetchCourseData("0");
    }, []);

    const RefreshCourseData = () => {
        setRefreshCourse(true);
        fetchCourseData("0")
            .then(response => {
                setRefreshCourse(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const ALLOWED_FORMATS = ['image/png', 'image/jpeg', 'video/mp4', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files) as File[];
            const errors: string[] = [];
            const selectedImages = files.filter(file => {
                const isValidFormat = ALLOWED_FORMATS.includes(file.type);
                const isValidSize = file.size <= MAX_FILE_SIZE;
                if (!isValidFormat) {
                    errors.push(`File ${file.name} has invalid format`);
                }
                if (!isValidSize) {
                    errors.push(`File ${file.type} exceeds the maximum allowed size of 5MB`);
                }
                return isValidFormat && isValidSize;
            }).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
                size: file.size,
            }));

            setUploadError(errors);

            if (selectedImages.length > 0) {
                setImages((prevImages) => [...prevImages, ...selectedImages]);
            }
        }
    };

    const handleRemoveImage = (index: number) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const handleEditCustomers = async (course_view_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true)
        try {
            const response = await COURSE_EDIT_DATA(course_view_id);
            setLoadingEdit(false)
            const initialData = response.data.course_details
            const materialData = response.data.course_material
            setFilesData(materialData)
            setInitialEditValues({
                course_id_upd: initialData.length > 0 ? initialData[0].course_id : '',
                course_name_upd: initialData.length > 0 ? initialData[0].course_name : '',
                course_fee_upd: initialData.length > 0 ? initialData[0].course_fee : '',
                course_type_upd: initialData.length > 0 ? initialData[0].course_type : '',
                course_remarks_upd: initialData.length > 0 ? initialData[0].course_remarks : '',
            })
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const CourseDelete = (course_del_id: string) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await COURSE_DELETE_DATA(course_del_id);
                const filterdata = courseData.filter(
                    (item) => item.course_id !== course_del_id
                );
                setCourseData(filterdata);
            } catch (error) {
                console.error("Error deleting course:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (course_del_id: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this course..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                CourseDelete(course_del_id);
                Swal.fire(
                    'Deleted!',
                    'Your course has been deleted.',
                    'success'
                )
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const filtered = courseData?.filter((app: any) => {
            const CourseSearchData = `${app.course_id} ${app.course_name} ${app.course_fee} ${app.course_type} ${app.course_created_by} ${app.course_created_on}`.toLowerCase();
            return CourseSearchData.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, courseData]);

    const sortByColumn = (column: string) => {
        const sortedData = [...courseData];

        if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
            sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
            setSortOrder('desc');
        } else {
            sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
            setSortOrder('asc');
        }
        setSortColumn(column);
        setCourseData(sortedData);
    };

    const itemsPerPage = 10;
    const totalItems = courseData.length;
    const filterDataTotal = filteredMembers.length
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const CustomerDetails = filteredMembers.slice(startIndex, startIndex + itemsPerPage);

    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Courses</span>
                        <span className='mt-1 fw-bold fs-7'>Total {courseData.length}  Courses</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshCourseData}>{!refreshCourse ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>
                        <div className="d-flex align-items-center me-5">
                            <div className="input-group">
                                <input type="search" placeholder='Search anything...' className="form-control w-50"
                                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button type="button" className="btn btn-primary">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleCourseModal}>
                                <KTIcon iconName='plus-circle' className='fs-2' />
                                Create Course
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (CustomerDetails.length > 0 ? (
                <div className={`table-responsive mt-5 ${refreshCourse ? 'd opacity-50' : ''}`} >
                    <table className='table align-middle gs-0 gy-4'>
                        <thead className='table_bg_color'>
                            <tr className='fw-bold fs-6'>
                                <th className={`ps-5 text-center ${sortColumn === 'course_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'course_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('course_id')}>
                                    S.No
                                </th>
                                <th className={`${sortColumn === 'course_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'course_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('course_name')}>
                                    Course Name
                                </th>
                                <th className={`${sortColumn === 'course_type' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'course_type' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('course_type')}>
                                    Course Type
                                </th>
                                <th className={`${sortColumn === 'course_fee' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'course_fee' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('course_fee')}>
                                    Course Fees
                                </th>
                                <th className={`${sortColumn === 'course_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'course_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('course_created_on')}>
                                    Created By
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='bg-light'>
                            {CustomerDetails.map((course, index) => (
                                <tr key={index} className='border-bottom  border-gray-300'>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 ps-4 text-center'>{course.course_id}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 w-200px'>
                                        {course.course_name}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{course.course_type ? course.course_type : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{course.course_fee ? course.course_fee : "-"}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{course.course_created_by}
                                        <span className="fw-semibold d-block fs-7">{course.course_created_on}</span>
                                    </td>
                                    <td style={{ paddingTop: "10px" }}>
                                        <div className="dropdown">
                                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots fs-5"></i>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleEditCustomers(course.course_id)}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleDeleteConfirmation(course.course_id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {searchQuery ? (
                        <Pagination currentPage={currentPage} totalPages={filterTotalPage} handlePageChange={handlePageChange} />
                    ) : (
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    )}
                </div>
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">No course found</h3>
                    </div>
                </div>
            )}

            <div className={`modal${courseModal ? ' show' : ''}`} role="dialog" style={{ display: courseModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg  modal-dialog-centered" role="document">
                    <CourseForm setCourseModal={setCourseModal} fetchCourseData={fetchCourseData} />
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <CourseEdit loadingEdit={loadingEdit} setShowEditModal={setShowEditModal} initialEditValues={initialEditValues}
                            fetchCourseData={fetchCourseData} handleImageChange={handleImageChange} handleRemoveImage={handleRemoveImage}
                            uploadError={uploadError} images={images} setImages={setImages} filesData={filesData} setFilesData={setFilesData}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Courses