import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { LEADS_BULK_IMPORT } from '../../modules/auth/core/_requests';
import toast from 'react-hot-toast';

const LeadsImport = ({ setImportModal, Login_user_id, fetchLeadsData,setLeadsData, setIsLoading }: any) => {
    const [loading, setLoading] = useState(false)

    const [fileName, setFileName] = useState('');
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setUploadFile(file);
            setFileName(file.name);
        }
    };

    const handleImportSubmit = async () => {
        if (!uploadFile) {
            setShowWarning(true);
            return;
        }
        setLoading(true);
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (!jsonData.length) {
                    // Handle case where jsonData is empty

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'The uploaded file is missing required headers.',
                        showConfirmButton: true,
                        timer: 5000,
                    });
                    setLoading(false);
                    return;
                }

                // Check if the required headers are present
                const requiredHeaders = ['leads_first_name', 'leads_phone_number',];

                // Type assertion to specify the type of fileHeaders
                const fileHeaders: string[] = jsonData[0] as string[];

                // Check if all required headers are present in the file
                const headersAreValid = requiredHeaders.every(header => fileHeaders.includes(header));

                if (!headersAreValid) {
                    toast.error("Name & Phone Number field is missing", {
                        style: {
                            background: '#F13637',
                            color: 'white',
                        }, duration: 5000
                    });
                    setLoading(false);
                    return;
                }

                // If the headers are valid, proceed with sending the JSON data
                sendJSONData(jsonData);
            };
            reader.readAsArrayBuffer(uploadFile);
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };

    const sendJSONData = async (jsonData: any) => {
        try {
            const formattedLeadsData = jsonData.slice(1).map((row: any) => ({
                leads_first_name: row[0],
                leads_last_name: row[1],
                leads_phone_number: row[2],
                leads_email: row[3],
                leads_current_qualification: row[4],
                leads_address_city: row[5],
                leads_country_interested: row[6],
                cccccc: row[7],
                leads_tags: row[8],
                leads_description: row[9],
            }));
            const response = await LEADS_BULK_IMPORT(formattedLeadsData, Login_user_id);
            if (response.data.message === 'success') {
                toast.success("Leads Imported Successfullyss", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setUploadFile(null);
                setFileName('');
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Resetting the value
                }
                fetchLeadsData("0",setLeadsData, setIsLoading)
                setShowWarning(false);
                setImportModal(false)
            } else {
                toast.error("Failed to import leads", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error("Failed to import leads", {
                style: {
                    background: '#F13637',
                    color: 'white',
                }, duration: 5000
            });
            setLoading(false);
        }
    };
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Bulk Import Leads <br></br>
                        <span className='fs-7 fw-normal'> <i className="bi bi-file-earmark-spreadsheet"></i>   Download Sample Format - <a href='https://api.digitalfactoryindia.com/kco/sample_bulk_leads_import_new.xlsx' className='text-primary' download>Click Here </a></span></h5>
                    <button type="button" className="btn-close" onClick={() => setImportModal(false)} aria-label="Close"></button>
                </div>
                <Formik
                    initialValues={{ file: null }}
                    onSubmit={handleImportSubmit}
                >
                    {() => (
                        <Form id="bulk-import">
                            <div className="modal-body">
                                <div className='p-10 border border-dashed rounded-2 border-primary d-flex justify-content-center w-100' style={{ cursor: 'pointer' }} onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                                    <input type="file" className='w-100' accept=".xlsx, .xls" onChange={handleFileUpload} ref={fileInputRef} style={{ display: 'none' }} />
                                    <span className=''>
                                        {fileName ? (
                                            fileName
                                        ) : (
                                            <span>
                                                <i className="bi bi-upload fs-2 me-2"></i> Click here to upload excel file (.xls,.xlsx)
                                            </span>
                                        )}
                                    </span>
                                </div>
                                {showWarning && <div className="text-danger mt-2">Please upload a file.</div>}
                            </div>
                            <div className="modal-footer">
                                <div className='row col-12'>
                                    <button type='submit' className='btn btn-primary btn-block' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default LeadsImport