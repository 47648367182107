import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import Loader from '../Loader';

const LeadsView = ({ leadsViewDatas, logViewDatas, leadsOwnerName, loadingEdit,
    expandedItemId, showAllTags, toggleExpand
}: any) => {
    return (
        <>
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">Leads Details :</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
            </div>
            <div className="offcanvas-body">
                <div className='card'>
                    <div className='card-header border-0'>
                        <div className='d-flex align-items-center p-5'>
                            <div className="symbol symbol-circle symbol-75px">
                                <img src={leadsViewDatas.leads_profile ? leadsViewDatas.leads_profile : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                            </div>
                            <div>
                                <h3> {leadsViewDatas.leads_first_name} {leadsViewDatas.leads_last_name}</h3>
                                <h6 className='d-flex align-items-center me-5 mb-2'>
                                    {
                                        (leadsViewDatas.leads_address_street ||
                                            leadsViewDatas.leads_address_city ||
                                            leadsViewDatas.leads_address_state ||
                                            leadsViewDatas.leads_address_country) && (
                                            <>
                                                <KTIcon iconName='geolocation' className='fs-4 text-dark me-1' />
                                                {leadsViewDatas.leads_address_street && (
                                                    <span>{leadsViewDatas.leads_address_street}, </span>
                                                )}
                                                {leadsViewDatas.leads_address_city && (
                                                    <span>{leadsViewDatas.leads_address_city}, </span>
                                                )}
                                                {leadsViewDatas.leads_address_state && (
                                                    <span>{leadsViewDatas.leads_address_state}, </span>
                                                )}
                                                {leadsViewDatas.leads_address_country && (
                                                    <span>{leadsViewDatas.leads_address_country}</span>
                                                )}
                                            </>
                                        )
                                    }
                                </h6>
                            </div>
                        </div>

                        <div className='card-toolbar'>
                            {leadsViewDatas.leads_status === '1' ? (
                                <span className='badge bg_badge_color3 p-2 text-white'>Interested</span>
                            ) : leadsViewDatas.leads_status === '2' ? (
                                <span className='badge bg-danger p-2 text-white'>Not interested</span>
                            ) : leadsViewDatas.leads_status === '3' ? (
                                <span className='badge bg-info p-2 text-white'>Beyond Intake</span>
                            ) : leadsViewDatas.leads_status === '4' ? (
                                <span className='badge bg_badge_color2 p-2 text-white'>Lost to competitor</span>
                            ) : leadsViewDatas.leads_status === '5' ? (
                                <span className='badge bg_badge_color1 text-white'>Financially not sound</span>
                            ) : (
                                <span className='badge badge-warning text-white'>None</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-5'>
                        <div className="card contact-sidebar mt-5" style={{ height: "75vh" }}>
                            <h3 className='mb-7'>Lead Information</h3>
                            {loadingEdit ? (
                                <Loader />
                            ) : (
                                <>
                                    <div className="row">
                                        <div className='col-md-5'>
                                            <h6 className='mb-5'>Name</h6>
                                            <h6 className='mb-5'>Source</h6>
                                            <h6 className='mb-5'>Phone Number</h6>
                                            <h6 className='mb-5'>Email Address</h6>
                                            <h6 className='mb-5'>Date Created</h6>
                                            <h6 className='mb-5'>Tags</h6>
                                        </div>
                                        <div className='col-md-7'>
                                            <h6 className='mb-5'>{leadsViewDatas.leads_first_name} {leadsViewDatas.leads_last_name}</h6>
                                            <h6 className='mb-5'>{leadsViewDatas.leads_source ? leadsViewDatas.leads_source : "-"}</h6>
                                            <h6 className='mb-5'>{leadsViewDatas.leads_phone_number ? leadsViewDatas.leads_phone_number : "-"}</h6>
                                            <h6 className='mb-5'>{leadsViewDatas.leads_email ? leadsViewDatas.leads_email : "-"}</h6>
                                            <h6 className='mb-5'>{leadsViewDatas.leads_created_on}</h6>
                                             
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {typeof leadsViewDatas.leads_tags === 'string' ? (() => {
                                                    const tags = leadsViewDatas.leads_tags.replace(/[\[\]"]+/g, '').split(',').map((tag:any) => tag.trim());
                                                    return (
                                                        <>
                                                            {tags.length > 0 && (
                                                                <span className="badge badge-success me-2 p-1">{tags[0]}</span>
                                                            )}
                                                            <div className="dropdown d-inline">
                                                                <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    {tags.length > 1 && (
                                                                        <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(leadsViewDatas.leads_id)}>
                                                                            {expandedItemId === leadsViewDatas.leads_id ? "Hide" : "+"}
                                                                            {expandedItemId === leadsViewDatas.leads_id ? "" : tags.length - 1}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    {showAllTags && tags.slice(1).map((tag:any) => (
                                                                        <li>
                                                                            <button
                                                                                className="dropdown-item"
                                                                                type="button"
                                                                            >
                                                                                <span key={tag}>{tag}</span>
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </>
                                                    );
                                                })() : '-'}
                                            </td>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <h5 className='mb-5'>Owner</h5>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className="symbol symbol-circle symbol-35px">
                                            <img src={leadsViewDatas.leads_profile ? leadsViewDatas.leads_profile : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                        </div>
                                        <div>
                                            <h5> {leadsOwnerName} </h5>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <h5 className='mb-5'>Description</h5>
                                    </div>
                                    <div className='overflow-y-scroll h-200px'>
                                        <p> {leadsViewDatas.leads_description ? leadsViewDatas.leads_description : <p className='text-center'>No description found </p>} </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div className="card contact-sidebar mt-5">
                            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-4">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        href="#activity_log"
                                    >
                                        <KTIcon iconName='user-tick' className='fs-4 text-primary' /> Activities
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#calls"
                                    >
                                        <i className="bi bi-telephone fs-4 text-primary me-2" />Calls
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            {loadingEdit ? (
                                <Loader />
                            ) : (
                                <>

                                    <div
                                        className="tab-pane fade show active"
                                        id="activity_log"
                                        role="tabpanel"
                                    >
                                        <div className="card mt-5" style={{ height: "62vh" }}>
                                            <div className='card-header border-0'>
                                                <h5 className='card-title align-items-start flex-column'>
                                                    <span className='card-label fw-bold fs-5 mb-1'>Activities</span>
                                                </h5>
                                                {/* <div className='card-toolbar'>
                                            <button
                                                type='button'
                                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                            >
                                                <KTIcon iconName='category' className='fs-2' />
                                            </button>
                                            <DropdownFilter />
                                        </div> */}
                                            </div>
                                            <div className='separator border-gray-200'></div>

                                            {/* <div className='card-body'>
                                        {logViewDatas?.filter((log: any) => log.log_type !== "scheduled_task" && log.log_type !== "call_task") 
                                            .map((log: any, index: any) => (
                                                <div key={index} >
                                                    <div className="badge-day">
                                                        <i className="fa-regular fa-calendar-check me-5 text-info"></i>{log.log_created_on}
                                                    </div>
                                                    <li className="activity-wrap">
                                                        <div className='symbol symbol-50px me-2'>
                                                            <span className='symbol-label bg-light-success'>
                                                                {log?.log_type === "general_task" && (
                                                                    <KTIcon iconName='graph-up' className='fs-2x text-success' />
                                                                )}
                                                                {log?.log_type === "leads" && (
                                                                    <KTIcon iconName="user-tick" className='fs-2x text-success' />
                                                                )}
                                                                {log?.log_type === "admission" && (
                                                                    <KTIcon iconName="teacher" className='fs-2x text-success' />
                                                                )}
                                                            </span>
                                                        </div>

                                                        <div className="activity-info">
                                                            <h6>{log.log_message}</h6>
                                                            <p>10:25 pm</p>
                                                        </div>
                                                    </li>
                                                </div>
                                            ))}
                                    </div> */}

                                            <div className='card-body overflow-y-scroll h-350px'>
                                                {logViewDatas.length > 0 ? (
                                                    logViewDatas
                                                        ?.filter((log: any) => log.log_type !== "scheduled_task" && log.log_type !== "call_task")
                                                        .reduce((groupedLogs: any, log: any) => {
                                                            const logDate = log.log_created_on.split(' ')[0];
                                                            const existingGroup = groupedLogs.find((group: any) => group.logDate === logDate);
                                                            if (existingGroup) {
                                                                existingGroup.logs.push(log);
                                                            } else {
                                                                groupedLogs.push({ logDate, logs: [log] });
                                                            }
                                                            return groupedLogs;
                                                        }, [])
                                                        .map((group: any, index: number) => (
                                                            <div key={index}>
                                                                <div className="badge-day">
                                                                    <i className="fa-regular fa-calendar-check me-5 text-info"></i>{group.logDate}
                                                                </div>
                                                                {group.logs.map((log: any, logIndex: number) => (
                                                                    <li key={logIndex} className="activity-wrap">
                                                                        <div className='symbol symbol-50px me-2'>
                                                                            <span className={`symbol-label ${log?.log_type === "general_task" ? 'bg-light-success' : log?.log_type === "leads" ? 'bg-light-info' : log?.log_type === "admission" ? 'bg-light-danger' : ''}`}>
                                                                                {log?.log_type === "general_task" && (
                                                                                    <KTIcon iconName='graph-up' className='fs-2x text-success' />
                                                                                )}
                                                                                {log?.log_type === "leads" && (
                                                                                    <KTIcon iconName="user-tick" className='fs-2x text-info' />
                                                                                )}
                                                                                {log?.log_type === "admission" && (
                                                                                    <KTIcon iconName="teacher" className='fs-2x text-danger' />
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="activity-info">
                                                                            <h6>{log.log_message}</h6>
                                                                            <p>Time-{log.log_created_on.split(' ')[1]} {log.log_created_on.split(' ')[2]}</p>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        ))
                                                ) :
                                                    <div className="d-flex justify-content-center">
                                                        <div>
                                                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-300px' />
                                                            <h3 className="text-center">No activity found</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="calls" role="tabpanel">
                                        <div className="card mt-5" style={{ height: "62vh" }}>
                                            <div className='card-header border-0'>
                                                <h5 className='card-title align-items-start flex-column'>
                                                    <span className='card-label fw-bold fs-5 mb-1'>Calls</span>
                                                </h5>
                                                {/* <div className='card-toolbar'>
                                            <button
                                                type='button'
                                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                            >
                                                <KTIcon iconName='category' className='fs-2' />
                                            </button>
                                            <DropdownFilter />
                                        </div> */}
                                            </div>
                                            <div className='separator border-gray-200'></div>

                                            <div className='card-body overflow-y-scroll h-350px'>
                                                {logViewDatas
                                                    ?.filter((log: any) => log.log_type === "scheduled_task" || log.log_type === "call_task")
                                                    .length > 0 ? (
                                                    logViewDatas
                                                        ?.filter((log: any) => log.log_type === "scheduled_task" || log.log_type === "call_task")
                                                        .reduce((groupedLogs: any, log: any) => {
                                                            const logDate = log.log_created_on.split(' ')[0];
                                                            const existingGroup = groupedLogs.find((group: any) => group.logDate === logDate);
                                                            if (existingGroup) {
                                                                existingGroup.logs.push(log);
                                                            } else {
                                                                groupedLogs.push({ logDate, logs: [log] });
                                                            }
                                                            return groupedLogs;
                                                        }, [])
                                                        .map((group: any, index: number) => (
                                                            <div key={index}>
                                                                <div className="badge-day">
                                                                    <i className="fa-regular fa-calendar-check me-5 text-info"></i>{group.logDate}
                                                                </div>
                                                                {group.logs.map((log: any, logIndex: number) => (
                                                                    <li key={logIndex} className="activity-wrap">
                                                                        <div className='symbol symbol-50px me-2'>
                                                                            <span className={`symbol-label ${log?.log_type === "scheduled_task" ? 'bg-light-success' : log?.log_type === "call_task" ? 'bg-light-info' : ''}`}>
                                                                                {log?.log_type === "scheduled_task" && (
                                                                                    <i className="bi-calendar2-check fs-2x text-success" />
                                                                                )}
                                                                                {log?.log_type === "call_task" && (
                                                                                    <i className="bi bi-telephone fs-2x text-info" />
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="activity-info">
                                                                            <h6>{log.log_message}</h6>
                                                                            <p>Time-{log.log_created_on.split(' ')[1]} {log.log_created_on.split(' ')[2]}</p>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        ))
                                                ) :
                                                    <div className="d-flex justify-content-center">
                                                        <div>
                                                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-300px' />
                                                            <h3 className="text-center">No calls found</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeadsView