import React, { useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import { useDropzone } from "react-dropzone";
import Select from 'react-select';
import { CONTACTS_PUT_DATA } from '../../modules/auth/core/_requests';
import AllUser from '../AllUser';
import { handleImageDrop } from '../CompressImage';
import Loader from '../Loader';
import toast from 'react-hot-toast';

const ContactEditForm = ({ setShowEditModal, loadingEdit, imageUpload, setImageUpload, initialEditValues,
    tagsSelected, tagsAdd, setTagsAdd, getTags, newTags, setNewTags, handleAddTags, handleTags, tagsError, setTagsError,
    leadsOwnerSelected, setLeadsOwnerSelected, fetchContactsList }: any) => {
    const [loading, setLoading] = useState(false)



    const validationSchemaEdit = Yup.object().shape({
        contact_first_name_upd: Yup.string().required('First Name is required'),
        contact_email_upd: Yup.string().email('Invalid email'),
        contact_phone_number_upd: Yup.string().matches(/^[0-9]{12}$/, 'Phone number must be exactly 12 digits').required('Phone number is required'),
        contact_assistant_phone_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    });

    const [imageModal, setImageModal] = useState(false)


    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";

    const handleImageModal = () => {
        if (imageUpload.length > 0) {
            setImageModal(false)
        } else {
            setImageModal(true)
        }
    }

    const handleRemoveImage = () => {
        setImageUpload([]);
    }

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const handleLeadsChange = (selected: any) => {
        setLeadsOwnerSelected(selected);
    };

    const handleContactUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const CustomerData = new FormData();
            if (imageUpload.length > 0) {
                CustomerData.append("contact_profile_upd", imageUpload[0]);
            }
            const Contact_tags = tagsSelected.map((option: any) => option.value);
            CustomerData.append('contact_tags_upd', JSON.stringify(Contact_tags));
            const ContactOwner = leadsOwnerSelected?.value;
            const response = await CONTACTS_PUT_DATA(
                CustomerData,
                values.contact_id_upd,
                ContactOwner ? ContactOwner : "",
                values.contact_lead_source_upd,
                values.contact_title_upd,
                values.contact_first_name_upd,
                values.contact_last_name_upd,
                values.contact_phone_number_upd,
                values.contact_email_upd,
                values.contact_dob_upd,
                values.contact_department_upd,
                values.contact_assistant_upd,
                values.contact_assistant_phone_upd,
                values.contact_current_qualification_upd,
                values.contact_country_interested_upd,
                values.contact_course_interested_upd,
                values.contact_status_upd,
                values.contact_address_line_1_upd,
                values.contact_address_line_2_upd,
                values.contact_address_street_upd,
                values.contact_address_city_upd,
                values.contact_address_state_upd,
                values.contact_address_zipcode_upd,
                values.contact_address_country_upd,
                values.contact_description_upd,
                userId
            )
            if (response.data.message === 'success') {
                fetchContactsList("0")
                setLoading(false);
                setImageUpload([])
                setLeadsOwnerSelected(null as any)
                actions.resetForm(); 
                setShowEditModal(false);
                setTimeout(() => {
                    toast.success("Contact Updated successfully", {
                        style: {
                            background: '#3DC13C',
                            color: 'white',
                        }, duration: 2000
                    }); 
                }, 1000); 
            } else {
                toast.error("Failed to update contact", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <Formik validationSchema={validationSchemaEdit} initialValues={initialEditValues} onSubmit={handleContactUpdate}>
                        {() => (
                            <Form>
                                <div className="modal-body form_content">
                                    <div className="form-group">
                                        <input type="hidden" name="contact_id_upd" className="form-control" />
                                    </div>

                                    <h5 className='ms-5'>Contact Image</h5>
                                    <div className='symbol symbol-50px symbol-circle ms-5 mt-3 cursor-pointer'>
                                        {imageUpload.length > 0 ? (
                                            (imageUpload.map((file: any) => (
                                                <>
                                                    <span onClick={handleRemoveImage} className='cursor-pointer'>
                                                        <KTIcon iconName='cross-square' className='fs-1 text-danger float-end' />
                                                    </span>
                                                    <div key={file.name} className='symbol symbol-50px symbol-circle ms-5'>
                                                        <img
                                                            src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                            alt={file.name}
                                                        />
                                                    </div>
                                                </>
                                            )))
                                        ) :
                                            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='blank_image' onClick={handleImageModal} />
                                        }
                                    </div>
                                    {imageUpload.length > 0 ? null : (
                                        <div className='w-25 mt-5'>
                                            {imageModal && (
                                                <div {...getRootProps1({ className: "dropzone" })} >
                                                    <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                    <div className="text-center">
                                                        <p className="dropzone-content">
                                                            Choose JPG or PNG File
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <h5 className='mt-5 forms_heading'>Contact Information</h5>
                                    <div className='ms-5 me-5'>
                                        <div className='row mb-3'>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label htmlFor="contact_owner_upd" className='form-label mb-3 required'>Contact Owner</label>
                                                    <Select
                                                        id="dropdown"
                                                        options={AllUser()}
                                                        value={leadsOwnerSelected}
                                                        name="contact_owner_upd"
                                                        placeholder="Search or select..."
                                                        isSearchable
                                                        menuPosition="fixed"
                                                        onChange={(leadsOwnerSelected) => {
                                                            handleLeadsChange(leadsOwnerSelected);
                                                        }}
                                                    />
                                                    <ErrorMessage name="contact_owner_upd" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_lead_source_upd" >Lead Source</label>
                                                    <Field as="select" name="contact_lead_source_upd" aria-label="Select example" className="form-select form-select-sm">
                                                        <option value="None">-None-</option>
                                                        <option value="Advertisement">Advertisement</option>
                                                        <option value="Employee Referral">Employee Referral</option>
                                                        <option value="Facebook">Facebook</option>
                                                        <option value="Google">Google</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Direct Walking">Direct Walking</option>
                                                        <option value="Cold Call">Cold Call</option>
                                                        <option value="External Referral">External Referral</option>
                                                        <option value="Partner">Partner</option>
                                                        <option value="Public Relations">Public Relations</option>
                                                        <option value="Web Research">Web Research</option>
                                                    </Field>
                                                </div>
                                            </div>

                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label htmlFor="contact_title_upd" className='form-label mb-3'>Title</label>
                                                    <Field type="text" name="contact_title_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="contact_first_name_upd" className='form-label mb-3 required'>First Name</label>
                                                    <Field type="text" name="contact_first_name_upd" className="form-control form-control-sm" />
                                                    <ErrorMessage name="contact_first_name_upd" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor="contact_last_name_upd" className='form-label mb-3'>Last Name</label>
                                                    <Field type="text" name="contact_last_name_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3 required" htmlFor="contact_phone_number_upd" >Phone Number</label>
                                                    <Field type="number" name="contact_phone_number_upd" className="form-control form-control-sm" readOnly />
                                                    <ErrorMessage name="contact_phone_number_upd" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_email_upd" >Email Address</label>
                                                    <Field type="text" name="contact_email_upd" className="form-control form-control-sm" />
                                                    <ErrorMessage name="contact_email_upd" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_dob_upd">Date of Birth</label>
                                                    <Field type="date" name="contact_dob_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_department_upd" >Department</label>
                                                    <Field type="text" name="contact_department_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_assistant_upd">Assistant Name</label>
                                                    <Field type="text" name="contact_assistant_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_assistant_phone_upd" >Assistant Phone</label>
                                                    <Field type="number" name="contact_assistant_phone_upd" className="form-control form-control-sm" />
                                                    <ErrorMessage name="contact_assistant_phone_upd" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_current_qualification_upd" >Current Qualification</label>
                                                    <Field type="text" name="contact_current_qualification_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'> 
                                                <div className="form-group mb-3">
                                                        <label htmlFor="contact_country_interested_upd" className="form-label mb-3 required">Country Interested</label>
                                                        <Field as="select" name="contact_country_interested_upd" aria-label="Select example" className="form-select form-select-sm">
                                                            <option value="">Select Country</option>
                                                            <option value="US">US</option>
                                                            <option value="UK">UK</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Dubai">Dubai</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Europe">Europe</option>
                                                        </Field>
                                                    </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_course_interested_upd" >Course Interested</label>
                                                    <Field type="text" name="contact_course_interested_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_status_upd" >Status</label>
                                                    <Field as="select" name="contact_status_upd" aria-label="Select example" className="form-select form-select-sm" >
                                                        <option value="0">-None-</option>
                                                        <option value="1">Interested</option>
                                                        <option value="2">Not interested</option>
                                                        <option value="3">Beyond Intake</option>
                                                        <option value="4">Lost to competitor</option>
                                                        <option value="5">Financially not sound</option>
                                                        <option value="6">Loan Rejected</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <h5 className='mt-5 forms_heading'>Address Information</h5>
                                    <div className='ms-5 me-5'>
                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_line_1_upd">Address Line 1</label>
                                                    <Field
                                                        name="contact_address_line_1_upd"
                                                        className="form-control form-control-sm"
                                                        as="textarea"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_line_2_upd" >Address Line 2</label>
                                                    <Field
                                                        name="contact_address_line_2_upd"
                                                        className="form-control form-control-sm"
                                                        as="textarea"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_street_upd" >Street</label>
                                                    <Field type="text" name="contact_address_street_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_city_upd" >City</label>
                                                    <Field type="text" name="contact_address_city_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_state_upd" >State</label>
                                                    <Field type="text" name="contact_address_state_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_zipcode_upd" >Zipcode</label>
                                                    <Field type="text" name="contact_address_zipcode_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label className="form-label mb-3" htmlFor="contact_address_country_upd" >Country</label>
                                                    <Field type="text" name="contact_address_country_upd" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className='mt-5 forms_heading'>Tags & Description Information</h5>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mt-5 mb-3" htmlFor="contact_tags_upd">Tags</label>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                    onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                                >
                                                    <i className="bi bi-plus plus_icon" />Add New
                                                </button>
                                                {tagsError && <div className="text-danger">{tagsError}</div>}
                                                <button type='button'
                                                    className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                    onClick={() => {
                                                        setTagsError(''); // Clear the error message
                                                        setTagsAdd(false); // Close the tags add section
                                                    }}
                                                >
                                                    <i className="bi bi-x plus_icon" />Close
                                                </button>
                                                {tagsAdd && (
                                                    <div className='d-flex'>
                                                        <Field
                                                            type="text"
                                                            value={newTags}
                                                            name='tags'
                                                            onChange={(e: any) => {
                                                                setNewTags(e.target.value);
                                                                // Clear the error message when the user starts typing
                                                                setTagsError('');
                                                            }}
                                                            className="form-control mt-5"
                                                            placeholder='Enter New Tags'
                                                        />
                                                        <button
                                                            type='button'
                                                            onClick={() => handleAddTags({ tags: newTags })}
                                                            className="btn btn-primary Add_new_cate_btn"
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                )}
                                                <Select
                                                    isMulti
                                                    options={getTags && getTags.length > 0 ? getTags.map((item: any) => ({
                                                        value: item.tag_name,
                                                        label: item.tag_name
                                                    })) : []}
                                                    value={tagsSelected}
                                                    name='contact_tags_upd'
                                                    placeholder='Select Tags'
                                                    onChange={(tagsSelected) => {
                                                        handleTags(tagsSelected);
                                                    }}
                                                    className='w-100 mt-2'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="contact_description_upd" >Description</label>
                                                <Field
                                                    name="contact_description_upd"
                                                    className="form-control form-control-sm"
                                                    as="textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    )
}

export default ContactEditForm