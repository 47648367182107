import React from 'react'
import { DashboardStats } from '../Interface'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Loader from '../Loader'


type Props = {
    stats: DashboardStats | undefined,
    loading: boolean
}

const TaskDetails: React.FC<Props> = ({ stats, loading }: any) => {
    return (
        <>
            <div className={`card`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-dark'>Daily Task Transactions</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Latest 10 tasks</span>
                    </h3>
                </div>
                <div className='card-body pt-5'>
                    <div className='row'>
                        <div className='col-md-2'>
                            <h5>Name</h5>
                        </div>
                        <div className='col-md-2'>
                            <h5>Priority</h5>
                        </div>
                        <div className='col-md-2'>
                            <h5>Due Date</h5>
                        </div>
                        <div className='col-md-2'>
                            <h5>Status</h5>
                        </div>
                        <div className='col-md-2'>
                            <h5>Assigned By</h5>
                        </div>
                        <div className='col-md-2'>
                            <h5>Created By</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className='h-350px bg-light-primary'>
                <div style={{ overflowY: 'auto', maxHeight: '350px' }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        stats?.user_tasks?.length === 0 ? (
                            <div style={{ minHeight: "45vh" }} className='d-flex align-items-center justify-content-center'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            <div className='text-center'>
                                                <img src={toAbsoluteUrl('/media/stock/task.png')} className='w-150px' />
                                                <h4>No Task Found</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            stats?.user_tasks.map((item: any, index: any) => (
                                <div className='card mt-3' key={index}>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 text-dark text-hover-primary fs-6 fw-bold'>
                                                {item.task_customer_name}
                                            </div>
                                            <div className='col-md-2 text-dark text-hover-primary fs-6 fw-bold'>
                                                {item.task_priority === '1' ? (
                                                    <span className='badge badge-primary text-white'>High</span>
                                                ) : item.task_priority === '2' ? (
                                                    <span className='badge badge-dark text-white'>Highest</span>
                                                ) : item.task_priority === '3' ? (
                                                    <span className='badge badge-warning text-white'>Low</span>
                                                ) : item.task_priority === '4' ? (
                                                    <span className='badge bg-danger text-white'>Lowest</span>
                                                ) : (
                                                    <span className='badge badge-success text-white'>Normal</span>
                                                )}
                                            </div>
                                            <div className='col-md-2 text-dark text-hover-primary fs-6 fw-bold'>
                                                {item.task_due_date}
                                                <span className='text-muted fw-semibold d-block fs-7'> {item.task_due_time}</span>
                                            </div>
                                            <div className='col-md-2'>
                                                {item.task_status === '1' ? (
                                                    <span className='badge badge-light-primary fs-7 fw-bold'>Not started</span>
                                                ) : item.task_status === '2' ? (
                                                    <span className='badge badge-light-dark fs-7 fw-bold text-white'>Pending</span>
                                                ) : item.task_status === '3' ? (
                                                    <span className='badge badge-light-warning fs-7 fw-bold'>In progress</span>
                                                ) : item.task_status === '4' ? (
                                                    <span className='badge badge-light-danger fs-7 fw-bold'>Waiting For Input</span>
                                                ) : (
                                                    <span className='badge badge-light-success fs-7 fw-bold'>Completed</span>
                                                )}
                                            </div>
                                            <div className='col-md-2 text-dark text-hover-primary fs-6 fw-bold'>
                                                {item.task_assignee}
                                            </div>
                                            <div className='col-md-2 text-dark text-hover-primary fs-6 fw-bold'>
                                                <span className='text-dark fw-bold d-block fs-6'> {item.task_created_by}</span>
                                                <span className='text-muted fw-semibold d-block fs-7'> {item.task_created_on}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))))}
                </div>
            </div>
        </>
    )
}

export default TaskDetails