import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import LogCallForm from './LogCallForm'
import ScheduleCallForm from './ScheduleCallForm'
import { CallTaskDetails, ContactDetails, SelectOption, LeadDatas } from '../../../pages/Interface'
import { CALL_DELETE_DATA, CALL_EDIT_DATA, CALL_GET_DATA, CONTACTS_GET_data, GET_ALL_LEADS } from '../../auth/core/_requests'
import Pagination from '../../../pages/Pagination';
import Loader from '../../../pages/Loader';
import Swal from 'sweetalert2';
import LogCallEdit from './LogCallEdit';
import { useAuth } from '../../auth';
import PaginationNew from '../../../pages/PaginationNew';


const Call = () => {
    const { currentUser } = useAuth()
    const [userRole] = useState(currentUser?.user_role || '')
    const { task_status } = useParams<{ task_status: string }>();
    const [refreshCall, setRefreshCall] = useState(false);
    const [callModal, setCallModal] = useState(false)
    const [scheduleCallModal, setScheduleCallModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState('Contact');
    const [taskAssignby, setTaskAssignby] = useState<SelectOption[] | null>(null);
    const [customers, setCustomers] = useState<ContactDetails[]>([]);
    const [leadsData, setLeadsData] = useState<LeadDatas[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [callData, setCallData] = useState<CallTaskDetails[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPerPage, setShowPerPage] = useState(10);
    const [totalCallData, setTotalCallData] = useState<CallTaskDetails[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState(task_status)
    const [filterSource, setFilterSource] = useState('')
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            call_task_id_upd: '',
            call_task_type_upd: '',
            task_user_number_upd: '',
            task_user_email_upd: '',
            call_status_upd: '',
            call_start_date_upd: '',
            call_start_time_upd: '',
            call_end_time_upd: '',
            call_duration_upd: '',
            call_subject_upd: '',
            call_purpose_upd: '',
            call_agenda_upd: '',
            call_description_upd: '',
            call_result_upd: '',
            call_reminder_upd: '',
        }
    )


    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const handleCallModal = () => {
        setCallModal(true)
        setTaskAssignby(null as any)
    }

    const handleScheduleModal = () => {
        setScheduleCallModal(true)
        setTaskAssignby(null as any)
    }

    const handleRelatedChange = (selected: any) => {
        setTaskAssignby(selected);
    };

    const toggleExpand = (id: any) => {
        setExpandedItemId(prevId => (prevId === id ? null : id));
        setShowAllTags(!showAllTags)
    };

    const fetchContactsList = async (all_contacts: any) => {
        try {
            const response = await CONTACTS_GET_data(all_contacts);
            setCustomers(response.data.contact_details);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        fetchContactsList("0");
    }, []);

    const fetchLeadsData = async (all_leads: any) => {
        try {
            const response = await GET_ALL_LEADS(all_leads);
            setLeadsData(response.data.leads_details);
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    useEffect(() => {
        fetchLeadsData("0",);
    }, []);

    const contactOptions = customers.map((item: any) => ({
        value: item.contact_id,
        label: `${item.contact_first_name} ${item.contact_last_name} (${item.contact_tag_sep})`,
    }));

    const leadsOptions = leadsData.map((item: any) => ({
        value: item.leads_id,
        label: `${item.leads_first_name} ${item.leads_last_name} (${item.leads_tags_sep})`,
    }));

    // const CustomOption = (props: any) => {
    //     const { innerRef, innerProps, data } = props;
    //     return (
    //         <div ref={innerRef} {...innerProps} style={{ padding: '10px' }}>
    //             <div>{data.displayLabel}</div>
    //             <div style={{ fontSize: '12px', color: '#ff6a56' }}>
    //                 {data.subLabel ? "Tags :" : ""} {data.subLabel}
    //             </div>
    //         </div>
    //     );
    // };

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
        setTaskAssignby(null as any)
    };

    const fetchCallList = async (get_all_call_task: any, task_user_id: any) => {
        try {
            const response = await CALL_GET_DATA(get_all_call_task, task_user_id);
            setCallData(response.data.call_task_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching call:', error);
        }
    };

    useEffect(() => {
        fetchCallList("0", user_id);
    }, []);

    const RefreshCallData = () => {
        setRefreshCall(true);
        fetchCallList("0", user_id)
            .then(response => {
                setRefreshCall(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const handleCallLogEdit = async (task_id: any, task_user_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true)
        try {
            const response = await CALL_EDIT_DATA(task_id, task_user_id);
            setLoadingEdit(false)
            const initialData = response.data.call_task_details
            const TaskReletedSelect = response.data.call_task_details[0].task_source_from;
            setSelectedOption(TaskReletedSelect)
            const TaskReletedTo = response.data.call_task_details[0].task_callto_user_name.trim().toLowerCase();

            let taskRelatedContact: ContactDetails | undefined; // Define the type of taskRelatedContact
            let taskRelatedLead: LeadDatas | undefined; // Define the type of taskRelatedLead

            if (Array.isArray(customers) && customers.length > 0) {
                taskRelatedContact = customers.find((item: ContactDetails) => {
                    const contactFirstName = `${item.contact_first_name} ${item.contact_last_name}`.trim().toLowerCase();
                    return contactFirstName === TaskReletedTo;
                });
            }

            if (Array.isArray(leadsData) && leadsData.length > 0) {
                taskRelatedLead = leadsData.find((item: LeadDatas) => {
                    const leadsName = `${item.leads_first_name} ${item.leads_last_name}`.trim().toLowerCase();
                    return leadsName === TaskReletedTo;
                });
            }

            if (TaskReletedSelect === "Contact") {
                if (taskRelatedContact) {
                    setTaskAssignby([{
                        value: taskRelatedContact.contact_id,
                        label: `${taskRelatedContact.contact_first_name} ${taskRelatedContact.contact_last_name}`
                    }]);
                }
            } else {
                if (taskRelatedLead) {
                    setTaskAssignby([{
                        value: taskRelatedLead.leads_id,
                        label: `${taskRelatedLead.leads_first_name} ${taskRelatedLead.leads_last_name}`
                    }]);
                }
            }
            setInitialEditValues({
                call_task_id_upd: initialData.length > 0 ? initialData[0].task_id : '',
                call_task_type_upd: initialData.length > 0 ? initialData[0].task_type : '',
                task_user_number_upd: initialData.length > 0 ? initialData[0].task_user_number : '',
                task_user_email_upd: initialData.length > 0 ? initialData[0].task_user_email : '',
                call_status_upd: initialData.length > 0 ? initialData[0].task_status : '',
                call_start_date_upd: initialData.length > 0 ? initialData[0].task_start_date : '',
                call_start_time_upd: initialData.length > 0 ? initialData[0].task_start_time : '',
                call_end_time_upd: initialData.length > 0 ? initialData[0].task_end_time : '',
                call_duration_upd: initialData.length > 0 ? initialData[0].task_duration : '',
                call_subject_upd: initialData.length > 0 ? initialData[0].task_subject : '',
                call_purpose_upd: initialData.length > 0 ? initialData[0].task_purpose : '',
                call_agenda_upd: initialData.length > 0 ? initialData[0].task_agenda : '',
                call_description_upd: initialData.length > 0 ? initialData[0].task_description : '',
                call_result_upd: initialData.length > 0 ? initialData[0].task_result : '',
                call_reminder_upd: initialData.length > 0 ? initialData[0].task_reminder : '',
            })
        } catch (error) {
            console.error('Error fetching call:', error);
        }
    };

    const handleCallLogDelete = (call_task_delete_id: string, call_task_deleted_by: string) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await CALL_DELETE_DATA(call_task_delete_id, call_task_deleted_by);
                const filterdata = callData.filter(
                    (item) => item.task_id !== call_task_delete_id
                );
                setCallData(filterdata);
            } catch (error) {
                console.error("Error deleting call:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (call_task_delete_id: string, call_task_deleted_by: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this call log..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                handleCallLogDelete(call_task_delete_id, call_task_deleted_by);
                Swal.fire(
                    'Deleted!',
                    'Your call log has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleItemsPerPageChange = (perPage: number) => {
        setShowPerPage(perPage);
        setCurrentPage(1); // Reset to first page when changing items per page
    };

    useEffect(() => {
        const filtered = callData?.filter((app: any) => {
            const appData = `${app.task_id} ${app.task_callto_user_name} ${app.task_type} ${app.task_status} ${app.task_creator_name}`.toLowerCase();
            return appData.includes(searchQuery.toLowerCase());
        });
        setTotalCallData(filtered);
    }, [searchQuery, callData]);

    const sortByColumn = (column: string) => {
        const sortedData = [...callData];

        if (sortColumn === column && (sortOrder === 'asc' || sortOrder === '')) {
            sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
            setSortOrder('desc');
        } else {
            sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
            setSortOrder('asc');
        }
        setSortColumn(column);
        setCallData(sortedData);
    };

    useEffect(() => {
        const filteredOrders = callData.filter((item) => {
            if (!filterStatus && !filterSource) {
                return true;
            }
            return (
                (!filterStatus || item.task_status == filterStatus) &&
                (!filterSource || item.task_type == filterSource)
            );
        });
        setTotalCallData(filteredOrders);
    }, [filterStatus, filterSource, callData]);

    const handleCallStatusFilter = (e: any) => {
        setFilterStatus(e.target.value);
    };

    const handleCallTypeFilter = (e: any) => {
        setFilterSource(e.target.value);
    };

    const itemsPerPage = 10;
    const totalItems = callData.length;
    const filterDataTotal = totalCallData.length
    const totalPages = Math.ceil(totalItems / showPerPage);
    const filterTotalPage = Math.ceil(filterDataTotal / showPerPage);
    const startIndex = (currentPage - 1) * showPerPage;
    const endIndex = startIndex + showPerPage;
    const calllDataDetails = totalCallData.slice(startIndex, endIndex);

    const showCallNotification = (CallTo: any, CallTime: any) => {
        Swal.fire({
            title: "Call Reminder!",
            text: `You have a scheduled call to ${CallTo} at ${CallTime}`,
            icon: "warning",
            width: 450,
            padding: "3em",
            color: "#716add",
            background: "#fff url(/images/trees.png)",
            backdrop: `
                rgba(195,22,255,0.4)
                url("/images/nyan-cat.gif")
                left top
                no-repeat
            `,
            showCancelButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Dismiss',
            allowOutsideClick: false,
        });
    };

    useEffect(() => {
        callData.forEach((item: any) => {
            if (userRole === "3" && item.task_status === "2") {

                const CallDateTimeCombined = item.task_start_date + ' ' + item.task_start_time;
                const reminderCallTime = new Date(CallDateTimeCombined);
                const taskTime = item.task_start_time;
                const timeUntilReminder = reminderCallTime.getTime() - Date.now() - 10 * 60 * 1000; // Adjusted for 10 minutes
                if (timeUntilReminder > 0) {
                    const timerId = setTimeout(() => {
                        showCallNotification(item.task_callto_user_name, taskTime); // Changed function call
                    }, timeUntilReminder);
                    return () => clearTimeout(timerId);
                }
            }
        });
    }, [callData]);
    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Calls</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {callData.length} Calls</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshCallData}>{!refreshCall ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>
                        <button className="btn btn-primary refresh_icon me-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <i className="las la-filter fs-2" />
                        </button>
                        <div className="drowndown">
                            <button type="button" className="btn btn-primary  refresh_icon  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <KTIcon iconName='plus' className='fs-2' />
                                Create Call
                            </button>
                            <ul className="dropdown-menu">
                                <li><button className="dropdown-item" onClick={handleScheduleModal}>Schedule a call</button></li>
                                <li><button className="dropdown-item" onClick={handleCallModal}>Log a call</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div className="collapse" id="collapseExample">
                <div className='card bg_none'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        </h3>
                        <div className='card-toolbar'>
                            <div className='me-5'>
                                <select className='form-select' onChange={handleCallTypeFilter}
                                    value={filterSource}>
                                    <option value="">Call Type</option>
                                    <option value="1">Outbound</option>
                                    <option value="2">Inbound</option>
                                    <option value="3">Missed</option>
                                </select>
                            </div>
                            <div className='me-5'>
                                <select
                                    className='form-select'
                                    onChange={handleCallStatusFilter}
                                    value={filterStatus}
                                >
                                    <option value="">Status</option>
                                    <option value="1">Completed</option>
                                    <option value="2">Scheduled</option>
                                    <option value="3">Not Connected</option>
                                    <option value="4">Unable to Pick Call</option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center position-relative">
                                <div className="input-group">
                                    <input type="search" placeholder='Search Name...'
                                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                        className="form-control w-50" />
                                    <button type="button" className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />

            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (calllDataDetails.length > 0 ? (
                <div className={`mt-5 ${refreshCall ? 'd opacity-50' : ''}`} >
                    <table className='table gs-0 gy-4'>
                        <thead className='table_bg_color'>
                            <tr className='fw-bold fs-6'>
                                <th className={`ps-5 ${sortColumn === 'task_id' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_id' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_id')}>
                                    S.No
                                </th>
                                <th className={`${sortColumn === 'task_callto_user_name' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_callto_user_name' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_callto_user_name')}>
                                    Call To
                                </th>
                                <th className={`${sortColumn === 'task_user_number' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_user_number' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_user_number')}>
                                    Phone
                                </th>
                                <th className={`${sortColumn === 'task_user_email' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_user_email' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_user_email')}>
                                    Email
                                </th>
                                <th className={`${sortColumn === 'task_source_from' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_source_from' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_source_from')}>
                                    Source
                                </th>
                                <th className={`${sortColumn === 'task_tags' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_tags' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_tags')}>
                                    Tags
                                </th>
                                <th className={`${sortColumn === 'task_start_date' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_start_date' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_start_date')}>
                                    Call Start Date & Time
                                </th>
                                {/* <th className={`${sortColumn === 'task_type' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_type' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_type')}>
                                    Call Type
                                </th> */}
                                <th className={`${sortColumn === 'task_status' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_status' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_status')}>
                                    Status
                                </th>
                                <th className={`${sortColumn === 'task_created_on' && sortOrder === 'asc' ? 'table-sort-asc' : sortColumn === 'task_created_on' && sortOrder === 'desc' ? 'table-sort-desc' : ''}`} onClick={() => sortByColumn('task_created_on')}>
                                    Created by
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='bg-light'>
                            {calllDataDetails.map((task, index) => (
                                <tr key={index} className='border-bottom  border-gray-300'>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>{task.task_id}</td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 d-flex border-0'>
                                        <div className="symbol symbol-circle symbol-35px">
                                            <img src={task.task_callto_user_pic ? task.task_callto_user_pic : toAbsoluteUrl('/media/avatars/blank.png')} alt="" className='me-2' />
                                        </div>
                                        <div>
                                            {task.task_callto_user_name}
                                        </div>
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_user_number ? task.task_user_number : "-"}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_user_email ? task.task_user_email : "-"}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_source_from ? task.task_source_from : "-"}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {typeof task.task_tags === 'string' ? (() => {
                                            const tags = task.task_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => tag.trim());
                                            return (
                                                <>
                                                    {tags.length > 0 && (
                                                        <span className="badge badge-success me-2 p-1">{tags[0]}</span>
                                                    )}
                                                    <div className="dropdown d-inline">
                                                        <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {tags.length > 1 && (
                                                                <span className="badge badge-info ms-2 p-1 cursor-pointer" onClick={() => toggleExpand(task.task_id)}>
                                                                    {expandedItemId === task.task_id ? "Hide" : "+"}
                                                                    {expandedItemId === task.task_id ? "" : tags.length - 1}
                                                                </span>
                                                            )}
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            {showAllTags && tags.slice(1).map(tag => (
                                                                <li>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                    >
                                                                        <span key={tag}>{tag}</span>
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            );
                                        })() : '-'}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_start_date ? task.task_start_date : "-"}
                                        <span className="fw-semibold d-block fs-7">
                                            {task.task_start_time ? task.task_start_time : "-"} to {task.task_end_time ? task.task_end_time : "-"}
                                        </span>
                                    </td>
                                    {/* <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_type === '1' ? (
                                            <span className=''>Outbound</span>
                                        ) : task.task_type === '2' ? (
                                            <span className=''>Inbound</span>
                                        ) : task.task_type === '3' ? (
                                            <span className=''>Missed</span>
                                        ) : ""}
                                    </td> */}

                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {task.task_status === '2' ? (
                                            <span className='badge badge-info text-white'>Scheduled</span>
                                        ) : task.task_status === '3' ? (
                                            <span className='badge bg-danger text-white'>Not Connected</span>
                                        ) : task.task_status === '4' ? (
                                            <span className='badge badge-warning text-white'>Unable to Pick Call</span>
                                        ) : task.task_status === '1' ? (
                                            <span className='badge bg_badge_color3 text-white'>Completed</span>
                                        ) : ""}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{task.task_creator_name}
                                        <span className="fw-semibold d-block fs-7">{task.task_created_on}</span>
                                    </td>
                                    <td style={{ paddingTop: "10px" }}>
                                        <div className="dropdown">
                                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots fs-5"></i>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleCallLogEdit(task.task_id, user_id)}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                                {userRole != "3" && (
                                                    <>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleDeleteConfirmation(task.task_id, user_id)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {filterStatus || filterSource || searchQuery ? (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={filterTotalPage}
                            itemsPerPage={itemsPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    ) : (
                        <PaginationNew
                            currentPage={currentPage}
                            totalPages={totalPages}
                            itemsPerPage={showPerPage}
                            handlePageChange={setCurrentPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                        />
                    )}
                </div>
            ) :
                <div style={{ minHeight: "55vh" }} className='d-flex align-items-center justify-content-center'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className='text-center'>
                                    <img src={toAbsoluteUrl('/media/stock/call.png')} className='w-250px' />
                                    <h4>No Calls Found</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className={`modal${callModal ? ' show' : ''}`} role="dialog" style={{ display: callModal ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <LogCallForm setCallModal={setCallModal} handleRelatedChange={handleRelatedChange} contactOptions={contactOptions} leadsOptions={leadsOptions}
                        handleSelectChange={handleSelectChange} selectedOption={selectedOption} taskAssignby={taskAssignby} setTaskAssignby={setTaskAssignby}
                        fetchCallList={fetchCallList}
                    />
                </div>
            </div>


            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <LogCallEdit loadingEdit={loadingEdit} setShowEditModal={setShowEditModal}
                            initialEditValues={initialEditValues}
                            fetchCallList={fetchCallList} setTaskAssignby={setTaskAssignby}
                            taskAssignby={taskAssignby} selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                            contactOptions={contactOptions} leadsOptions={leadsOptions}
                        />
                    </div>
                </div>
            )}

            <div className={`modal${scheduleCallModal ? ' show' : ''}`} role="dialog" style={{ display: scheduleCallModal ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <ScheduleCallForm setScheduleCallModal={setScheduleCallModal} handleRelatedChange={handleRelatedChange} contactOptions={contactOptions} leadsOptions={leadsOptions}
                        handleSelectChange={handleSelectChange} selectedOption={selectedOption} taskAssignby={taskAssignby} setTaskAssignby={setTaskAssignby}
                        fetchCallList={fetchCallList}
                    />
                </div>
            </div>
        </>
    )
}

export default Call