import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom' 
import Admission from './Admission'
 

const AdmissionPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      > 
        <Route
          path='new-admission'
          element={
            <>
              <Admission />
            </>
          }
        /> 
      </Route>
    </Routes>
  )
}

export default AdmissionPage
