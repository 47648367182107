/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { DashboardStats } from '../Interface'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
    className: string,
    stats: DashboardStats | undefined,
    loading: boolean
}

const RecentAdmission: React.FC<Props> = ({ className, stats, loading }) => {
    const colors = ['success', 'primary', 'warning', 'danger'];
    return (

        <div className={`card ${className}`}>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bold text-dark'>Recent Admissions</h3>
            </div>
            <div className='card-body pt-2' style={{ overflowY: "scroll", maxHeight: "400px" }}>
                {stats && stats?.recent_admissions.length > 0 ? (
                    stats?.recent_admissions.map((admission, index) => (
                        <div key={index} className='d-flex align-items-center mb-8'>
                            <span className={`bullet bullet-vertical h-80px bg-${colors[index % colors.length]} mx-5`}></span>
                            <div className='flex-grow-1'>
                                <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                    {admission.admission_student_name}
                                </span>
                                <span className='text-muted fw-semibold d-block'>
                                    Country - {admission.admission_country_interested}
                                </span>
                                <span className='text-muted fw-semibold d-block'>
                                    Course - {admission.admission_course_intereseted}
                                </span>
                                <small className='text-muted d-block'>
                                    Created By - {admission.admission_created_by}
                                </small>
                            </div>
                            <span className={`badge badge-light-${colors[index % colors.length]} fs-8 fw-bold`}>
                                {admission.admission_created_on}
                            </span>
                        </div>
                    ))
                ) :
                    <div style={{ minHeight: "45vh" }} className='d-flex align-items-center justify-content-center'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className='text-center'>
                                        <img src={toAbsoluteUrl('/media/stock/admission.png')} className='w-150px' />
                                        <h4>No Admission Found</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export { RecentAdmission }
