import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard/Dashboard'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import CoursesPage from '../modules/courses/CoursesPage'
import TaskPage from '../modules/task/components/TaskPage'
import AdmissionPage from '../modules/services/AdmissionPage'
import UserPage from '../modules/users/UserPage'
import Leads from '../pages/Leads/Leads'
import Contact from '../pages/Contact/Contact'
import WhatsappPage from '../modules/whatsapp/WhatsappPage'
import EmailConfigure from '../modules/whatsapp/pages/Integration/pages/EmailConfigure'
import SMSConfigure from '../modules/whatsapp/pages/Integration/pages/SMSConfigure'
import Razorpay from '../modules/whatsapp/pages/Integration/pages/Razorpay'
import WhatsappConfigure from '../modules/whatsapp/pages/Settings/pages/WhatsappConfigure'
import Catalogue from '../modules/whatsapp/pages/Settings/pages/CatalogueConfigure'
import WhatsappStore from '../modules/whatsapp/pages/Settings/pages/WhatsappStore'
import Task from '../modules/task/components/Task'
import Call from '../modules/task/components/Call'
import ReportPage from '../modules/report/ReportPage'
import { useAuth } from '../modules/auth'
import { UserDeatils_Post } from '../modules/auth/core/_requests'
import { AccountDetails } from '../pages/Interface'
import SubscriptionAlert from '../modules/subscription/SubscriptionAlert'


const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const [details, setDetails] = useState<AccountDetails>()
  const [isLoading, setIsLoading] = useState(true);
  const [userRole] = useState(currentUser?.user_role || '')
  const [userToken] = useState(currentUser?.api_token || '')
  const [userID] = useState(currentUser?.user_id || '')
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  const fetchUserDetails = async (user_token: any, user_id: any) => {
    try {
      const response = await UserDeatils_Post(user_token, user_id);
      setDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails(userToken, userID);
  }, []);

  const currentDate = new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
  const subscriptionEndDateStr: any = details?.subscription_end;
  // const newDateStr: any = "17-08-2024";
  function parseDate(dateStr: any) {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${year}-${month}-${day}`);
  }
  let CheckExpiryDate: any
  if (subscriptionEndDateStr && currentDate) {
    const subscriptionEndDate = parseDate(subscriptionEndDateStr);
    const newDate = parseDate(currentDate);
    CheckExpiryDate = newDate <= subscriptionEndDate;
  } else {
    <></>
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/task-status/:task_status" element={<Task />} />
        <Route path="/task-call-status/:task_status" element={<Call />} />
        {userRole == "1" ? (
          <>
            <Route path="/integration/email" element={<EmailConfigure />} />
            <Route path="/integration/sms" element={<SMSConfigure />} />
            <Route path="/integration/razorpay" element={<Razorpay />} />
            <Route path="/settings/whatsapp" element={<WhatsappConfigure />} />
            <Route path="/settings/catalogue" element={<Catalogue />} />
            <Route path="/settings/whatsapp-store" element={<WhatsappStore />} />
          </>
        ) : null}

        <Route path='courses/*'
          element={
            <SuspensedView>
              <CoursesPage />
            </SuspensedView>
          }
        />
        <Route path='task/*'
          element={
            <SuspensedView>
              <TaskPage />
            </SuspensedView>
          }
        />
        <Route path='admission/*'
          element={
            <SuspensedView>
              <AdmissionPage />
            </SuspensedView>
          }
        />

        {userRole == "1" ? (
          <Route path='user/*'
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
        ) : null}

        {userRole == "1" || userRole == "2" ? (
          <>
            <Route path='report/*'
              element={
                <SuspensedView>
                  <ReportPage />
                </SuspensedView>
              }
            />
          </>
        ) : null}

        <Route path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Conditionally render the Whatsapp route or the SubscriptionAlert */}
        {isLoading ? (
          <>
            {userRole == "1" ? (
              <Route
                path='whatsapp/*'
                element={
                  <SuspensedView>
                    <WhatsappPage />
                  </SuspensedView>
                }
              />
            ) : ""}
          </>
        ) : (
          <>
            {userRole == "1" ? (
              <Route
                path='whatsapp/*'
                element={
                  <SuspensedView>
                    {CheckExpiryDate == false ? (
                      <SubscriptionAlert details={details} />
                    ) : (
                      <WhatsappPage />
                    )}
                  </SuspensedView>
                }
              />
            ) : ""}
          </>
        )}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }