import { ADMISSION_GET_DATA, GET_ALL_LEADS, GET_ALL_USERS, TASK_GET_DATA, UserDeatils_Post } from "../../modules/auth/core/_requests";

export  const fetchLeadsData = async (all_leads: any,setLeadsData:any,setIsLoading:any) => {
    try {
      const response = await GET_ALL_LEADS(all_leads);
      setLeadsData(response.data.leads_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  export const GET_USER_DATA = async (all_users: any,setUserData:any) => {
    try {
      const response = await GET_ALL_USERS(all_users);
      setUserData(response.data.user_details);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  export  const GET_TASK_LIST = async (get_all_task: any, task_user_id: any,setTaskData:any,setIsLoading:any) => {
    try {
      const response = await TASK_GET_DATA(get_all_task, task_user_id);
      setTaskData(response.data.task_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching task:', error);
    }
  };

  export const GET_ADMISSION_LIST = async (get_all_admission: any, ac_uid: any,setAdmissionData:any,setIsLoading:any) => {
    try {
      const response = await ADMISSION_GET_DATA(get_all_admission, ac_uid);
      setAdmissionData(response.data.admission_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching admission:', error);
    }
  };

  export const fetchUserDetails = async (user_token: any, user_id: any,setDetails:any,setIsLoading:any) => {
    try {
      const response = await UserDeatils_Post(user_token, user_id);
      setDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };