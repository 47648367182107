import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import { handleImageDrop } from '../../pages/CompressImage';
import { USER_PUT_DATA } from '../auth/core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import Loader from '../../pages/Loader';


const UserEdit = ({ setShowEditModal, loadingEdit, imageUpload, setImageUpload, initialEditValues, validationEditSchema,fetchUserData }: any) => {
    const [loading, setLoading] = useState(false)
    const [imageModal, setImageModal] = useState(false)

    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";

    const handleImageModal = () => {
        if (imageUpload.length > 0) {
            setImageModal(false)
        } else {
            setImageModal(true)
        }
    }

    const handleRemoveImage = () => {
        setImageUpload([]);
    }

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const LeadsData = new FormData()
            if (imageUpload.length > 0) {
                LeadsData.append("user_pic_upd", imageUpload[0]);
            }
            const response = await USER_PUT_DATA(
                LeadsData,
                values.user_id_upd,
                values.user_name_upd,
                values.user_mobile_upd,
                values.user_email_upd,
                values.user_dob_upd,
                values.user_department_upd,
                values.user_role_upd,
                values.user_date_of_join_upd,
                values.user_address_upd,
                values.user_remarks_upd,
                values.user_status_upd,
                userId
            )
            if (response.data === 'Successfully Updated') {
                toast.success("User added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    },
                });
                setShowEditModal(false)
                setLoading(false)
                fetchUserData("0");
                actions.resetForm()
                setImageUpload([])
            } else {
                toast.error("Failed to add user");
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit User Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <>
                        <Formik validationSchema={validationEditSchema} initialValues={initialEditValues} onSubmit={handleSubmit}>
                            {() => (
                                <Form>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <input type="hidden" name="user_id_upd" className="form-control" />
                                        </div>
                                        <div className='ms-5 fw-bold'>Profile</div>
                                        <div className='symbol symbol-50px symbol-circle ms-5 mt-3'>
                                            {imageUpload.length > 0 ? (
                                                (imageUpload.map((file: any) => (
                                                    <>
                                                        <span onClick={handleRemoveImage} className='cursor-pointer'>
                                                            <KTIcon iconName='cross-square' className='fs-1 text-danger float-end' />
                                                        </span>
                                                        <div key={file.name} className='symbol symbol-50px symbol-circle ms-5'>
                                                            <img
                                                                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                                alt={file.name}
                                                            />
                                                        </div>
                                                    </>
                                                )))
                                            ) :
                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='blank_image' onClick={handleImageModal} />
                                            }
                                        </div>
                                        {imageUpload.length > 0 ? null : (
                                            <div className='w-25 mt-5'>
                                                {imageModal && (
                                                    <div {...getRootProps1({ className: "dropzone" })} >
                                                        <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                        <div className="text-center">
                                                            <p className="dropzone-content">
                                                                Choose JPG or PNG File
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className='ms-5 me-5'>
                                            <div className='row mb-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="user_name_upd" className='form-label mb-3 required'>Name</label>
                                                        <Field type="text" name="user_name_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="user_name_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3 required">Status</label>
                                                        <Field
                                                            as='select'
                                                            name='user_status_upd'
                                                            className='form-select form-select-sm'
                                                        >
                                                            <option value='1'>Active</option>
                                                            <option value='2'>Deactivate</option>
                                                        </Field>
                                                        <ErrorMessage name="user_status_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_mobile_upd" >Phone Number</label>
                                                        <Field type="number" name="user_mobile_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="user_mobile_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_email_upd" >Email Address</label>
                                                        <Field type="text" name="user_email_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="user_email_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_dob_upd">Date of Birth</label>
                                                        <Field type="date" name="user_dob_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_department_upd">Department</label>
                                                        <Field type="text" name="user_department_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="user_department_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_role">Role</label>
                                                        <Field
                                                            as='select'
                                                            name='user_role_upd'
                                                            className='form-select form-select-sm'
                                                        >
                                                            <option value='2'>Admin</option>
                                                            <option value='3'>Employee</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_date_of_join_upd" >Date of Joining</label>
                                                        <Field type="date" name="user_date_of_join_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="user_date_of_join_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ms-5 me-5'>
                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_address_upd">Address</label>
                                                        <Field
                                                            name="user_address_upd"
                                                            className="form-control form-control-sm"
                                                            as="textarea"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="user_remarks_upd" >Remarks</label>
                                                        <Field
                                                            name="user_remarks_upd"
                                                            className="form-control form-control-sm"
                                                            as="textarea"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className='d-flex justify-content-end'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        </>
    )
}

export default UserEdit