import axios from 'axios'
import { AuthModel, UserModel } from './_models'
const API_URL = process.env.REACT_APP_API_URL

const API_URL_NEW = 'https://api.digitalfactoryindia.com/kco/api' // CRM API
axios.defaults.headers.common['Authorization'] = 'Bearer YOUR_ACCESS_TOKEN';

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL_NEW}/forgot_password`
export const LOGIN_URL_NEW = `${API_URL_NEW}/login`
export const OTP_VALIDATE_URL = `${API_URL_NEW}/validate`
export const GET_USER_BY_ACCESSTOKEN_URL_NEW = `${API_URL_NEW}/verify_token`
export const DASHBOARD = `${API_URL_NEW}/dashboard`
export const PROFILE_URL = `${API_URL_NEW}/profile`
export const CUSTOMERS = `${API_URL_NEW}/customers`
export const CONTACTS = `${API_URL_NEW}/contacts`
export const TAGS = `${API_URL_NEW}/contact_tags`
export const USER_ACCOUNT = `${API_URL_NEW}/user_account`
export const LEADS = `${API_URL_NEW}/leads`
export const COURSE = `${API_URL_NEW}/courses`
export const TASK = `${API_URL_NEW}/task`
export const CALL_TASK = `${API_URL_NEW}/call_task`
export const ADMISSION = `${API_URL_NEW}/admission`

//WHATSAPP API//
const WHATSAPP_API = 'https://api.digitalfactoryindia.com/kco/api' /// Whatsapp API
const GRAPH_API = 'https://graph.facebook.com/v18.0/234524643086539'
const GRAPH_CATALOGUE_API = 'https://graph.facebook.com/v18.0/393580190141583'

export const SMS = `${WHATSAPP_API}/addons`
export const WHATSAPP_CHAT_URL = `${WHATSAPP_API}/whatsapp_chat`
export const BROADCAST = `${WHATSAPP_API}/broadcast`
export const WABA_TEMPLATE = `${GRAPH_API}/message_templates`
export const WABA_PRODUCT_CATALOGUES = `${GRAPH_CATALOGUE_API}/products`



// Server should return AuthModel
export function login(user_email: string, user_password: string) {
  return axios.post<AuthModel>(LOGIN_URL_NEW, {
    user_email,
    user_password,
  })
}

export function ProfileUpdates(
  formData: FormData,
  user_id_upd: string,
  user_name_upd: string,
  user_mobile_upd: string,
  user_dob_upd: string,
  user_address_upd: string,
  user_last_updated_by: string,
) {
  formData.append('user_id_upd', user_id_upd);
  formData.append('user_name_upd', user_name_upd);
  formData.append('user_mobile_upd', user_mobile_upd);
  formData.append('user_dob_upd', user_dob_upd);
  formData.append('user_address_upd', user_address_upd);
  formData.append('user_last_updated_by', user_last_updated_by);
  return axios.post<AuthModel>(PROFILE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}


export function resetPassword(
  password: string,
  password_confirmation: string,
  user_email: string
) {
  return axios.post(REQUEST_PASSWORD_URL, {
    password,
    password_confirmation,
    user_email
  })
}


export function updateNewPassword(
  current_password: string,
  new_password: string,
  user_email: string
) {
  return axios.post(PROFILE_URL, {
    current_password,
    new_password,
    user_email
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestOTP(
  user_otp: string,
  user_mobile: string,
) {
  return axios.post(OTP_VALIDATE_URL, {
    user_otp,
    user_mobile
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL_NEW, {
    api_token: token,
  })
}

export function DASHBOARD_GET_STATS(dashboard_stats: string, dashboard_user_id: string
) {
  return axios.get(DASHBOARD, {
    params: {
      dashboard_stats: dashboard_stats,
      dashboard_user_id: dashboard_user_id,
    },
  });
}

export function UserDeatils_Post(verify_user_token: string, verify_user_id: string) {
  return axios.post(PROFILE_URL, {
    verify_user_token,
    verify_user_id
  })
}


export function Tags_POST_data(tag_name: string, tag_created_by: string) {
  return axios.post(TAGS, {

    tag_name,
    tag_created_by,
  })
}

export function Tags_GET_data(get_all_tags: string) {
  return axios.get(TAGS, {
    params: {
      get_all_tags: get_all_tags,
    },
  });
}

export function CONTACTS_POST_DATA(
  formData: FormData,
  contact_owner: string,
  contact_lead_source: string,
  contact_title: string,
  contact_first_name: string,
  contact_last_name: string,
  contact_phone_number: string,
  contact_email: string,
  contact_dob: string,
  contact_department: string,
  contact_assistant: string,
  contact_assistant_phone: string,
  contact_current_qualification: string,
  contact_country_interested: string,
  contact_course_interested: string,
  contact_status: string,
  contact_address_line_1: string,
  contact_address_line_2: string,
  contact_address_street: string,
  contact_address_city: string,
  contact_address_state: string,
  contact_address_zipcode: string,
  contact_address_country: string,
  contact_description: string,
  contact_created_by: string,
) {
  formData.append('contact_owner', contact_owner);
  formData.append('contact_lead_source', contact_lead_source);
  formData.append('contact_title', contact_title);
  formData.append('contact_first_name', contact_first_name);
  formData.append('contact_last_name', contact_last_name);
  formData.append('contact_phone_number', contact_phone_number);
  formData.append('contact_email', contact_email);
  formData.append('contact_dob', contact_dob);
  formData.append('contact_department', contact_department);
  formData.append('contact_assistant', contact_assistant);
  formData.append('contact_assistant_phone', contact_assistant_phone);
  formData.append('contact_current_qualification', contact_current_qualification);
  formData.append('contact_country_interested', contact_country_interested);
  formData.append('contact_course_interested', contact_course_interested);
  formData.append('contact_status', contact_status);
  formData.append('contact_address_line_1', contact_address_line_1);
  formData.append('contact_address_line_2', contact_address_line_2);
  formData.append('contact_address_street', contact_address_street);
  formData.append('contact_address_city', contact_address_city);
  formData.append('contact_address_state', contact_address_state);
  formData.append('contact_address_zipcode', contact_address_zipcode);
  formData.append('contact_address_country', contact_address_country);
  formData.append('contact_description', contact_description);
  formData.append('contact_created_by', contact_created_by);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function CONTACTS_PUT_DATA(
  formData: FormData,
  contact_id_upd: string,
  contact_owner_upd: string,
  contact_lead_source_upd: string,
  contact_title_upd: string,
  contact_first_name_upd: string,
  contact_last_name_upd: string,
  contact_phone_number_upd: string,
  contact_email_upd: string,
  contact_dob_upd: string,
  contact_department_upd: string,
  contact_assistant_upd: string,
  contact_assistant_phone_upd: string,
  contact_current_qualification_upd: string,
  contact_country_interested_upd: string,
  contact_course_interested_upd: string,
  contact_status_upd: string,
  contact_address_line_1_upd: string,
  contact_address_line_2_upd: string,
  contact_address_street_upd: string,
  contact_address_city_upd: string,
  contact_address_state_upd: string,
  contact_address_zipcode_upd: string,
  contact_address_country_upd: string,
  contact_description_upd: string,
  contact_updated_by: string,
) {
  formData.append('contact_id_upd', contact_id_upd);
  formData.append('contact_owner_upd', contact_owner_upd);
  formData.append('contact_lead_source_upd', contact_lead_source_upd);
  formData.append('contact_title_upd', contact_title_upd);
  formData.append('contact_first_name_upd', contact_first_name_upd);
  formData.append('contact_last_name_upd', contact_last_name_upd);
  formData.append('contact_phone_number_upd', contact_phone_number_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_dob_upd', contact_dob_upd);
  formData.append('contact_department_upd', contact_department_upd);
  formData.append('contact_assistant_upd', contact_assistant_upd);
  formData.append('contact_assistant_phone_upd', contact_assistant_phone_upd);
  formData.append('contact_current_qualification_upd', contact_current_qualification_upd);
  formData.append('contact_country_interested_upd', contact_country_interested_upd);
  formData.append('contact_course_interested_upd', contact_course_interested_upd);
  formData.append('contact_status_upd', contact_status_upd);
  formData.append('contact_address_line_1_upd', contact_address_line_1_upd);
  formData.append('contact_address_line_2_upd', contact_address_line_2_upd);
  formData.append('contact_address_street_upd', contact_address_street_upd);
  formData.append('contact_address_city_upd', contact_address_city_upd);
  formData.append('contact_address_state_upd', contact_address_state_upd);
  formData.append('contact_address_zipcode_upd', contact_address_zipcode_upd);
  formData.append('contact_address_country_upd', contact_address_country_upd);
  formData.append('contact_description_upd', contact_description_upd);
  formData.append('contact_updated_by', contact_updated_by);

  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Customer_POST_data(
  formData: FormData,
  contact_name: string,
  contact_email: string,
  contact_mobile: string,
  contact_address: string,
  contact_user_id: number,
  user_token: string,
  contact_cust_id: string,
) {
  formData.append('contact_name', contact_name);
  formData.append('contact_email', contact_email);
  formData.append('contact_mobile', contact_mobile);
  formData.append('contact_address', contact_address);
  formData.append('contact_user_id', contact_user_id.toString());
  formData.append('user_token', user_token);
  formData.append('contact_cust_id', contact_cust_id);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function CONTACTS_GET_data(all_contacts: string) {
  return axios.get(CONTACTS, {
    params: {
      all_contacts: all_contacts,
    },
  });
}

export function CONTACTS_EDIT_DATA(contact_id: string) {
  return axios.get(CONTACTS, {
    params: {
      contact_id: contact_id,
    },
  });
}

export function CONTACTS_ADD_ADMISSION(contact_admission_id: string, contact_admission_add_by: string) {
  return axios.post(CONTACTS, {
    contact_admission_id: contact_admission_id,
    contact_admission_add_by: contact_admission_add_by
  });
}

export function CONTACTS_MULTIPLE_ADMISSION_ADD(contact_admission_ids: string, contact_admissions_add_by: string) {
  return axios.post(CONTACTS, {
    contact_admission_ids: contact_admission_ids,
    contact_admissions_add_by: contact_admissions_add_by
  });
}

export function Customer_UPDATE_data(
  formData: FormData,
  contact_id_upd: string,
  contact_name_upd: string,
  contact_email_upd: string,
  contact_mobile_upd: string,
  contact_address_upd: string,
  contact_user_id_upd: number,
  user_token_upd: string,
  contact_cust_id_upd: string,

) {
  formData.append('contact_id_upd', contact_id_upd);
  formData.append('contact_name_upd', contact_name_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_mobile_upd', contact_mobile_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('contact_user_id_upd', contact_user_id_upd.toString());
  formData.append('user_token_upd', user_token_upd);
  formData.append('contact_cust_id_upd', contact_cust_id_upd);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function CONTACTS_DELETE_DATA(contact_del_id: string) {
  return axios.post(CONTACTS, {
    contact_del_id: contact_del_id,
  })
}

export function CONTACTS_MULTIPLE_DELETE_DATA(contact_delete_ids: string,contact_delete_by:string) {
  return axios.post(CONTACTS, {
    contact_delete_ids: contact_delete_ids,
    contact_delete_by: contact_delete_by,
  })
}

export function CONTACTS_BULK_IMPORT(contact_xl_data: string, contact_xl_created_by: string) {
  return axios.post(CONTACTS, {
    contact_xl_data: contact_xl_data,
    contact_xl_created_by: contact_xl_created_by
  })
}

export function LEADS_BULK_IMPORT(lead_xl_data: string, lead_xl_created_by: string) {
  return axios.post(LEADS, {
    lead_xl_data: lead_xl_data,
    lead_xl_created_by: lead_xl_created_by
  })
}

export function LEADS_POST_DATA(
  formData: FormData,
  leads_owner: string,
  leads_title: string,
  leads_first_name: string,
  leads_last_name: string,
  leads_phone_number: string,
  leads_email: string,
  leads_current_qualification: string,
  leads_country_interested: string,
  leads_course_interested: string,
  leads_source: string,
  leads_status: string,
  leads_address_street: string,
  leads_address_city: string,
  leads_address_state: string,
  leads_address_country: string,
  leads_description: string,
  leads_created_by: string,
) {
  formData.append('leads_owner', leads_owner);
  formData.append('leads_title', leads_title);
  formData.append('leads_first_name', leads_first_name);
  formData.append('leads_last_name', leads_last_name);
  formData.append('leads_phone_number', leads_phone_number);
  formData.append('leads_email', leads_email);
  formData.append('leads_current_qualification', leads_current_qualification);
  formData.append('leads_country_interested', leads_country_interested);
  formData.append('leads_course_interested', leads_course_interested);
  formData.append('leads_source', leads_source);
  formData.append('leads_status', leads_status);
  formData.append('leads_address_street', leads_address_street);
  formData.append('leads_address_city', leads_address_city);
  formData.append('leads_address_state', leads_address_state);
  formData.append('leads_address_country', leads_address_country);
  formData.append('leads_description', leads_description);
  formData.append('leads_created_by', leads_created_by);
  return axios.post(LEADS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function GET_ALL_LEADS(all_leads: number) {
  return axios.get(LEADS, {
    params: {
      all_leads: all_leads
    },
  });
}

export function LEADS_EDIT_DATA(lead_view_id: string) {
  return axios.get(LEADS, {
    params: {
      lead_view_id: lead_view_id
    },
  });
}

export function LEADS_PUT_DATA(
  formData: FormData,
  leads_id_upd: string,
  leads_owner_upd: string,
  leads_title_upd: string,
  leads_first_name_upd: string,
  leads_last_name_upd: string,
  leads_phone_number_upd: string,
  leads_email_upd: string,
  leads_current_qualification_upd: string,
  leads_country_interested_upd: string,
  leads_course_interested_upd: string,
  leads_source_upd: string,
  leads_status_upd: string,
  leads_address_street_upd: string,
  leads_address_city_upd: string,
  leads_address_state_upd: string,
  leads_address_country_upd: string,
  leads_description_upd: string,
  leads_updated_by: string,
) {
  formData.append('leads_id_upd', leads_id_upd);
  formData.append('leads_owner_upd', leads_owner_upd);
  formData.append('leads_title_upd', leads_title_upd);
  formData.append('leads_first_name_upd', leads_first_name_upd);
  formData.append('leads_last_name_upd', leads_last_name_upd);
  formData.append('leads_phone_number_upd', leads_phone_number_upd);
  formData.append('leads_email_upd', leads_email_upd);
  formData.append('leads_current_qualification_upd', leads_current_qualification_upd);
  formData.append('leads_country_interested_upd', leads_country_interested_upd);
  formData.append('leads_course_interested_upd', leads_course_interested_upd);
  formData.append('leads_source_upd', leads_source_upd);
  formData.append('leads_status_upd', leads_status_upd);
  formData.append('leads_address_street_upd', leads_address_street_upd);
  formData.append('leads_address_city_upd', leads_address_city_upd);
  formData.append('leads_address_state_upd', leads_address_state_upd);
  formData.append('leads_address_country_upd', leads_address_country_upd);
  formData.append('leads_description_upd', leads_description_upd);
  formData.append('leads_updated_by', leads_updated_by);
  return axios.post(LEADS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function LEADS_DELETE_DATA(lead_trash_id: string) {
  return axios.post(LEADS, {
    lead_trash_id: lead_trash_id,
  })
}

export function TASK_POST_DATA(
  task_assignee: string,
  task_related_to: string,
  task_related_to_id: string,
  task_type: string,
  task_due_date: string,
  task_due_time: string,
  task_status: string,
  task_priority: string,
  task_reminder_mode: string,
  task_reminder_on: string,
  task_description: string,
  task_subject: string,
  task_created_by: string,
) {
  return axios.post(TASK, {
    task_assignee,
    task_related_to,
    task_related_to_id,
    task_type,
    task_due_date,
    task_due_time,
    task_status,
    task_priority,
    task_reminder_mode,
    task_reminder_on,
    task_description,
    task_subject,
    task_created_by,
  })
}

export function TASK_GET_DATA(get_all_task: number, task_user_id: number) {
  return axios.get(TASK, {
    params: {
      get_all_task: get_all_task,
      task_user_id: task_user_id,
    },
  });
}

export function TASK_EDIT_DATA(task_id: string, task_user_id: string) {
  return axios.get(TASK, {
    params: {
      task_id: task_id,
      task_user_id: task_user_id,
    },
  });
}

export function TASK_DELETE_DATA(task_delete_id: string, task_deleted_by: string) {
  return axios.post(TASK, {
    task_delete_id: task_delete_id,
    task_deleted_by: task_deleted_by,
  })
}

export function TASK_PUT_DATA(
  task_id_upd: string,
  task_assignee_upd: string,
  task_related_to_upd: string,
  task_related_to_id_upd: string,
  task_type_upd: string,
  task_due_date_upd: string,
  task_due_time_upd: string,
  task_status_upd: string,
  task_priority_upd: string,
  task_reminder_mode_upd: string,
  task_reminder_on_upd: string,
  task_description_upd: string,
  task_subject_upd: string,
  task_updated_by: string,
) {
  return axios.post(TASK, {
    task_id_upd,
    task_assignee_upd,
    task_related_to_upd,
    task_related_to_id_upd,
    task_type_upd,
    task_due_date_upd,
    task_due_time_upd,
    task_status_upd,
    task_priority_upd,
    task_reminder_mode_upd,
    task_reminder_on_upd,
    task_description_upd,
    task_subject_upd,
    task_updated_by,
  })
}

export function LOG_CALL_POST_DATA(
  call_task_source: string,
  call_task_source_user_id: string,
  call_task_type: string,
  call_status: string,
  call_start_date: string,
  call_start_time: string,
  call_end_time: string,
  call_duration: string,
  call_subject: string,
  call_purpose: string,
  call_agenda: string,
  call_result: string,
  call_description: string,
  call_reminder: string,
  call_created_by: string,
) {
  return axios.post(CALL_TASK, {
    call_task_source,
    call_task_source_user_id,
    call_task_type,
    call_status,
    call_start_date,
    call_start_time,
    call_end_time,
    call_duration,
    call_subject,
    call_purpose,
    call_agenda,
    call_result,
    call_description,
    call_reminder,
    call_created_by,
  })
}

export function CALL_GET_DATA(get_all_call_task: number, task_user_id: number) {
  return axios.get(CALL_TASK, {
    params: {
      get_all_call_task: get_all_call_task,
      task_user_id: task_user_id,
    },
  });
}

export function CALL_DELETE_DATA(call_task_delete_id: string, call_task_deleted_by: string) {
  return axios.post(CALL_TASK, {
    call_task_delete_id: call_task_delete_id,
    call_task_deleted_by: call_task_deleted_by,
  })
}

export function CALL_EDIT_DATA(call_task_id: string, task_user_id: string) {
  return axios.get(CALL_TASK, {
    params: {
      call_task_id: call_task_id,
      task_user_id: task_user_id,
    },
  });
}

export function LOG_CALL_PUT_DATA(
  call_task_id_upd: string,
  call_task_source_upd: string,
  call_task_source_user_id_upd: string,
  call_task_type_upd: string,
  call_status_upd: string,
  call_start_date_upd: string,
  call_start_time_upd: string,
  call_end_time_upd: string,
  call_duration_upd: string,
  call_subject_upd: string,
  call_purpose_upd: string,
  call_agenda_upd: string,
  call_result_upd: string,
  call_description_upd: string,
  call_reminder_upd: string,
  call_created_by_upd: string,
) {
  return axios.post(CALL_TASK, {
    call_task_id_upd,
    call_task_source_upd,
    call_task_source_user_id_upd,
    call_task_type_upd,
    call_status_upd,
    call_start_date_upd,
    call_start_time_upd,
    call_end_time_upd,
    call_duration_upd,
    call_subject_upd,
    call_purpose_upd,
    call_agenda_upd,
    call_result_upd,
    call_description_upd,
    call_reminder_upd,
    call_created_by_upd,
  })
}

export function COURSE_POST_DATA(formData: FormData) {
  return axios.post(COURSE, formData);
}

export function GET_ALL_COURSE(all_courses: number) {
  return axios.get(COURSE, {
    params: {
      all_courses: all_courses
    },
  });
}

export function COURSE_EDIT_DATA(course_view_id: string) {
  return axios.get(COURSE, {
    params: {
      course_view_id: course_view_id,
    },
  });
}

export function COURSE_DELETE_DATA(course_del_id: string) {
  return axios.post(COURSE, {
    course_del_id: course_del_id,
  })
}

export function COURSE_PUT_DATA(formData: FormData) {
  return axios.post(COURSE, formData);
}

export function COURSE_DOCUMENTS_POST_DATA(formData: FormData) {
  return axios.post(COURSE, formData);
}

export function COURSE_DOCUMENTS_DELETE_DATA(material_del_id: string) {
  return axios.post(COURSE, {
    material_del_id: material_del_id,
  })
}

export function USER_POST_DATA(
  formData: FormData,
  user_name: string,
  user_password: string,
  user_phone_number: string,
  user_email_address: string,
  user_dob: string,
  user_department: string,
  user_role: string,
  user_doj: string,
  user_address: string,
  user_remarks: string,
  user_created_by: string,

) {
  formData.append('user_name', user_name);
  formData.append('user_password', user_password);
  formData.append('user_phone_number', user_phone_number);
  formData.append('user_email_address', user_email_address);
  formData.append('user_dob', user_dob);
  formData.append('user_department', user_department);
  formData.append('user_role', user_role);
  formData.append('user_doj', user_doj);
  formData.append('user_address', user_address);
  formData.append('user_remarks', user_remarks);
  formData.append('user_created_by', user_created_by);
  return axios.post(USER_ACCOUNT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function GET_ALL_USERS(all_users: number) {
  return axios.get(USER_ACCOUNT, {
    params: {
      all_users: all_users
    },
  });
}

export function USER_EDIT_DATA(edit_user_id: string) {
  return axios.get(USER_ACCOUNT, {
    params: {
      edit_user_id: edit_user_id
    },
  });
}

export function USER_PUT_DATA(
  formData: FormData,
  user_id_upd: string,
  user_name_upd: string,
  user_mobile_upd: string,
  user_email_upd: string,
  user_dob_upd: string,
  user_department_upd: string,
  user_role_upd: string,
  user_doj_upd: string,
  user_address_upd: string,
  user_remarks_upd: string,
  user_status_upd: string,
  user_last_updated_by: string,

) {
  formData.append('user_id_upd', user_id_upd);
  formData.append('user_name_upd', user_name_upd);
  formData.append('user_mobile_upd', user_mobile_upd);
  formData.append('user_email_upd', user_email_upd);
  formData.append('user_dob_upd', user_dob_upd);
  formData.append('user_department_upd', user_department_upd);
  formData.append('user_role_upd', user_role_upd);
  formData.append('user_doj_upd', user_doj_upd);
  formData.append('user_address_upd', user_address_upd);
  formData.append('user_remarks_upd', user_remarks_upd);
  formData.append('user_status_upd', user_status_upd);
  formData.append('user_last_updated_by', user_last_updated_by);
  return axios.post(USER_ACCOUNT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function ADMISSION_POST_DATA(
  admission_lead_from: string,
  admission_source_from: string,
  admission_source_id: string,
  admission_student_name: string,
  admission_student_mobile: string,
  admission_student_email: string,
  admission_processing_team: string,
  admission_course_intereseted: string,
  admission_country_interested: string,
  admission_other_country: string,
  admission_fee: string,
  admission_exam_status: string,
  admission_for_course: string,
  admission_course_fee: string,
  admission_remarks: string,
  admission_created_by: string,
) {
  return axios.post(ADMISSION, {
    admission_lead_from,
    admission_source_from,
    admission_source_id,
    admission_student_name,
    admission_student_mobile,
    admission_student_email,
    admission_processing_team,
    admission_course_intereseted,
    admission_country_interested,
    admission_other_country,
    admission_fee,
    admission_exam_status,
    admission_for_course,
    admission_course_fee,
    admission_remarks,
    admission_created_by,
  })
}

export function ADMISSION_PUT_DATA(
  admission_id_upd: string,
  admission_lead_from_upd: string,
  admission_source_from_upd: string,
  admission_source_id_upd: string,
  admission_student_name_upd: string,
  admission_student_mobile_upd: string,
  admission_student_email_upd: string,
  admission_processing_team_upd: string,
  admission_course_intereseted_upd: string,
  admission_agent_upd: string,
  admission_country_interested_upd: string,
  admission_other_country_upd: string,
  admission_fee_upd: string,
  admission_exam_status_upd: string,
  admission_status_upd: string,
  admission_for_course_upd: string,
  admission_course_fee_upd: string,
  admission_remarks_upd: string,
  admission_created_by_upd: string,
) {
  return axios.post(ADMISSION, {
    admission_id_upd,
    admission_lead_from_upd,
    admission_source_from_upd,
    admission_source_id_upd,
    admission_student_name_upd,
    admission_student_mobile_upd,
    admission_student_email_upd,
    admission_processing_team_upd,
    admission_course_intereseted_upd,
    admission_agent_upd,
    admission_country_interested_upd,
    admission_other_country_upd,
    admission_fee_upd,
    admission_exam_status_upd,
    admission_status_upd,
    admission_for_course_upd,
    admission_course_fee_upd,
    admission_remarks_upd,
    admission_created_by_upd,
  })
}

export function ADMISSION_GET_DATA(get_all_admission: number, ac_uid: number) {
  return axios.get(ADMISSION, {
    params: {
      get_all_admission: get_all_admission,
      ac_uid: ac_uid,
    },
  });
}

export function ADMISSION_EDIT_DATA(admission_id: string, ac_uid: string) {
  return axios.get(ADMISSION, {
    params: {
      admission_id: admission_id,
      ac_uid: ac_uid,
    },
  });
}

export function ADMISSION_DOC_POST_DATA(formData: FormData) {
  return axios.post(ADMISSION, formData);
}

export function ADMISSION_DOC_GET_DATA(admission_doc_get_id: string, admission_doc_type: string) {
  return axios.get(ADMISSION, {
    params: {
      admission_doc_get_id: admission_doc_get_id,
      admission_doc_type: admission_doc_type,
    },
  });
}

export function ADMISSION_OFFER_LETTER_POST_DATA(formData: FormData) {
  return axios.post(ADMISSION, formData);
}

export function ADMISSION_DELETE_DATA(admission_delete_id: string, admission_deleted_by: string) {
  return axios.post(ADMISSION, {
    admission_delete_id: admission_delete_id,
    admission_deleted_by: admission_deleted_by,
  })
}

// Whatsapp API


export function SMS_config_post_data(
  sms_user_name: string,
  sms_password: string,
  sms_sender_id: string,
  sms_website_url: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    sms_user_name,
    sms_password,
    sms_sender_id,
    sms_website_url,
    addon_type,
  })
}

export function sms_addons_update(
  addon_id: string,
  sms_user_name_upd: string,
  sms_password_upd: string,
  sms_website_url_upd: string,
  sms_sender_id_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    sms_user_name_upd,
    sms_password_upd,
    sms_website_url_upd,
    sms_sender_id_upd,
    addon_type_upd,
  })
}

export function Addons(addon: number) {
  return axios.get(SMS, {
    params: {
      get_addon_details: addon
    },
  });
}

export function SMS_setting_post_data(
  formData: FormData,
  sms_admin_mobile_number: string,
) {
  formData.append('sms_admin_mobile_number', sms_admin_mobile_number);
  return axios.post(SMS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Wp_config_post_data(
  wp_phone_number_id: string,
  wp_business_account_id: string,
  access_token: string,
  wp_number: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    wp_phone_number_id,
    wp_business_account_id,
    access_token,
    wp_number,
    addon_type,
  })
}

export function Wp_catalogue_post_data(
  wp_catalogue_id: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    wp_catalogue_id,
    addon_type,
  })
}

export function Wp_store_post_data(
  wp_business_id: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    wp_business_id,
    addon_type,
  })
}

export function Wp_store_upd_data(
  addon_id: string,
  wp_business_id_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    wp_business_id_upd,
    addon_type_upd,
  })
}

export function Wp_catalogue_upd_data(
  addon_id: string,
  wp_catalogue_id_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    wp_catalogue_id_upd,
    addon_type_upd,
  })
}

export function Wp_config_upd_data(
  addon_id: string,
  wp_phone_number_id_upd: string,
  wp_business_account_id_upd: string,
  access_token_upd: string,
  wp_number_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    wp_phone_number_id_upd,
    wp_business_account_id_upd,
    access_token_upd,
    wp_number_upd,
    addon_type_upd,
  })
}

export function Wp_setting_post_data(
  formData: FormData,
  admin_wp_number: string,
) {
  formData.append('admin_wp_number', admin_wp_number);
  return axios.post(SMS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Email__config_post_data(
  email_user_name: string,
  email_user_password: string,
  email_smtp_address: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    email_user_name,
    email_user_password,
    email_smtp_address,
    addon_type,
  })
}

export function Email_config_upd_data(
  addon_id: string,
  email_user_name_upd: string,
  email_user_password_upd: string,
  email_smtp_address_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    email_user_name_upd,
    email_user_password_upd,
    email_smtp_address_upd,
    addon_type_upd,
  })
}

export function WABUDATA(wacustData: string) {
  return axios.get(WHATSAPP_CHAT_URL, {
    params: {
      wacustData: wacustData,
    },
  });
}

export function WACHAT_MESSAGES(wa_message_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    wa_message_id
  })
}

export function WACHAT_MESSAGE_SEND(
  wa_message_receiver_id: string,
  wa_individual_message: string,
  wa_user_id: string,
  wa_cust_id: string,
) {
  return axios.post(WHATSAPP_CHAT_URL, {
    wa_message_receiver_id,
    wa_individual_message,
    wa_user_id,
    wa_cust_id
  })
}

export function GET_WA_MESSAGE_TEMPLATE(access_token: string) {
  return axios.get(WABA_TEMPLATE, {
    params: {
      access_token: access_token
    },
  });
}

export function GET_WA_PRODUCT_CATALOGUES(access_token: string, fields: any, limit: number, before: string, after: any) {
  return axios.get(WABA_PRODUCT_CATALOGUES, {
    params: {
      access_token: access_token,
      fields: fields,
      limit: limit,
      before: before,
      after: after,
    },
  });
}

interface CustomersData {
  order_person_name: string;
  order_contact_number: string;
  delivery_address_1: string;
  delivery_address_2: string;
}

export function whatsapp_broadcast_segment_POST(
  segment_name: string,
  segment_customers: CustomersData[],
  segment_created_by: string,
) {
  return axios.post(BROADCAST, {
    segment_name,
    segment_customers,
    segment_created_by,
  })
}

export function whatsapp_broadcast_segment_GET(all_segments: string) {
  return axios.get(BROADCAST, {
    params: {
      all_segments: all_segments
    },
  });
}

export function whatsapp_broadcast_GET(all_broadcast: string) {
  return axios.get(BROADCAST, {
    params: {
      all_broadcast: all_broadcast
    },
  });
}

export function whatsapp_RECIPIENTS_GET(broadcast_segment_id: number) {
  return axios.get(BROADCAST, {
    params: {
      broadcast_segment_id: broadcast_segment_id
    },
  });
}

export function whatsapp_broadcast_filters(
  broadcast_source_type: string,
  broadcast_select_type: string,
  broadcast_select_format: string,
  broadcast_search_type: string,
  broadcastmode: number,
) {
  return axios.post(CUSTOMERS, {
    broadcast_source_type,
    broadcast_select_type,
    broadcast_select_format,
    broadcast_search_type,
    broadcastmode,
  })
}
export function whatsapp_broadcast_template(
  // templateId: string,
  variable: string[],
  template_segment_id: string[],
) {
  return axios.post(BROADCAST, {
    // templateId,
    variable,
    template_segment_id,
  })
}

export function whatspp_broadcast_post(
  formData: FormData,
  variable: string[],
  template_segment_id: string[],
) {
  variable.forEach((value) => {
    formData.append(`variable`, value);
  });
  template_segment_id.forEach(id => {
    formData.append('template_segment_id[]', id);
  });
  return axios.post(BROADCAST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatsapp_broadcast_create(
  broadcast_name: string,
  broadcast_type: string,
  schedule_date: string,
  schedule_time: string,
  broadcast_variables: any,
  broadcast_recipients: number,
  broadcast_segment_id: any,
  broadcast_template: any,
) {
  return axios.post(BROADCAST, {
    broadcast_name,
    broadcast_type,
    schedule_date,
    schedule_time,
    broadcast_variables,
    broadcast_recipients,
    broadcast_segment_id,
    broadcast_template
  })
}

export function Customer_EDIT_Details(contact_id: string, contact_cust_id: string) {
  return axios.get(CONTACTS, {
    params: {
      contact_id: contact_id,
      contact_cust_id: contact_cust_id
    },
  });
}

export function wp_Customer_UPDATE_data(
  formData: FormData,
  contact_id_upd: string,
  contact_name_upd: string,
  contact_email_upd: string,
  contact_mobile_upd: string,
  contact_address_upd: string,
  contact_user_id_upd: string,
  user_token_upd: string,
  contact_cust_id_upd: string,

) {
  formData.append('contact_id_upd', contact_id_upd);
  formData.append('contact_name_upd', contact_name_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_mobile_upd', contact_mobile_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('contact_user_id_upd', contact_user_id_upd);
  formData.append('user_token_upd', user_token_upd);
  formData.append('contact_cust_id_upd', contact_cust_id_upd);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Customer_DELETE_data(contact_del_id: string, contact_cust_id: string) {
  return axios.post(CONTACTS, {
    contact_del_id: contact_del_id,
    contact_cust_id: contact_cust_id
  })
}

export function Chat_Tags_POST_data(cust_id: string, tag_name: string, tag_created_by: string, user_token: string) {
  return axios.post(TAGS, {
    cust_id,
    tag_name,
    tag_created_by,
    user_token
  })
}

export function Chat_Tags_GET_data(get_all_tags: string, tag_cust_id: string) {
  return axios.get(TAGS, {
    params: {
      get_all_tags: get_all_tags,
      tag_cust_id: tag_cust_id,
    },
  });
}

export function customer_wp_update_data(
  formData: FormData,
  contact_name_upd: string,
  // contact_phone_upd: string,
  contact_email_upd: string,
  contact_address_upd: string,
  user_id: number,
) {
  formData.append('contact_name_upd', contact_name_upd);
  // formData.append('contact_phone_upd', contact_phone_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('user_id', user_id.toString());
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatsapp_broadcast_download(broadcast_log_data: string, broadcast_log_cid: string) {
  return axios.get(BROADCAST, {
    params: {
      broadcast_log_data: broadcast_log_data,
      broadcast_log_cid: broadcast_log_cid,
    },
  });
}

export function Segment_DELETE_data(segment_del_id: string, segment_cust_id: string) {
  return axios.post(BROADCAST, {
    segment_del_id: segment_del_id,
    segment_cust_id: segment_cust_id
  })
}

export function CustomerName_UPDATE(quick_customer_name_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_customer_name_upd: quick_customer_name_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function DeliveryDate_UPDATE(quick_delivery_date_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_delivery_date_upd: quick_delivery_date_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function Notes_UPDATE(quick_notes_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_notes_upd: quick_notes_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function customerXLData(contact_xl_data: string, contact_cust_id: number, contact_user_id: string) {
  return axios.post(CONTACTS, {
    contact_xl_data: contact_xl_data,
    contact_cust_id: contact_cust_id,
    contact_user_id: contact_user_id
  })
}

export function whatspp_chat_template_post(
  message_chat_media: FormData,
) {
  return axios.post(WHATSAPP_CHAT_URL, message_chat_media, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatspp_chat_message_post(message_chat_user_id: string, message_chat_template: string, message_chat_details: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    message_chat_user_id: message_chat_user_id,
    message_chat_template: message_chat_template,
    message_chat_details: message_chat_details,
  })
}

export function whatspp_chat_image_post(
  chat_media: FormData,
  chat_media_type: string,
  chat_media_caption: string,
  chat_receiver_id: string,
  chat_sender_id: string,
) {
  chat_media.append('chat_media_type', chat_media_type);
  chat_media.append('chat_media_caption', chat_media_caption);
  chat_media.append('chat_receiver_id', chat_receiver_id);
  chat_media.append('chat_sender_id', chat_sender_id);
  return axios.post(WHATSAPP_CHAT_URL, chat_media, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatspp_chat_image(message_chat_template: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    message_chat_template: message_chat_template,
  })
}

export function PAYMENT_REQUEST_SEND(payment: string, notes: string, chat_number: string, chat_sender_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    payment: payment,
    notes: notes,
    chat_number: chat_number,
    chat_sender_id: chat_sender_id,
  })
}

export function CONTACTS_ADD_CHAT(contact_name: string, contact_mobile: string, contact_cust_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    contact_name: contact_name,
    contact_mobile: contact_mobile,
    contact_cust_id: contact_cust_id,
  })
}

export function Razorpay_config_post_data(
  razorpay_key_id: string,
  razorpay_key_secret: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    razorpay_key_id,
    razorpay_key_secret,
    addon_type,
  })
}

export function Razorpay_config_upd_data(
  addon_id: string,
  razorpay_key_id_upd: string,
  razorpay_key_secret_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    razorpay_key_id_upd,
    razorpay_key_secret_upd,
    addon_type_upd,
  })
}

export function Segment_Recipients_Update(
  segment_id_upd: string,
  segment_customers_upd: any,
  segment_updated_by: string,
) {
  return axios.post(BROADCAST, {
    segment_id_upd,
    segment_customers_upd,
    segment_updated_by,
  })
}

export function LEADS_ADD_CONTACTS(leads_id: string, leads_moved_by: string) {
  return axios.post(CONTACTS, {
    leads_id: leads_id,
    leads_moved_by: leads_moved_by
  });
}