import React, { useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select';
import { useDropzone } from "react-dropzone";
import { handleImageDrop } from '../CompressImage';
import { CONTACTS_POST_DATA } from '../../modules/auth/core/_requests';
import AllUser from '../AllUser';
import toast from 'react-hot-toast';


const ContactForm = ({
    setOpenModal, tagsSelected, setTagsSelected, tagsAdd, setTagsAdd, getTags, newTags, setNewTags, handleAddTags, handleTags, tagsError,
    leadsOwnerSelected, setLeadsOwnerSelected, fetchContactsList }: any) => {
    const [loading, setLoading] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const [imageUpload, setImageUpload] = useState<File[]>([]);



    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const handleLeadsChange = (selected: any) => {
        setLeadsOwnerSelected(selected);
    };

    const handleImageModal = () => {
        if (imageUpload.length > 0) {
            setImageModal(false)
        } else {
            setImageModal(true)
        }
    }

    const handleRemoveImage = () => {
        setImageUpload([]);
    }

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const initialValues = {
        contact_owner: '',
        contact_lead_source: '',
        contact_title: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_phone_number: '',
        country_phone_code: '91',
        contact_email: '',
        contact_dob: '',
        contact_department: '',
        contact_assistant: '',
        contact_assistant_phone: '',
        contact_current_qualification: '',
        contact_country_interested: '',
        contact_course_interested: '',
        contact_status: '',
        contact_address_line_1: '',
        contact_address_line_2: '',
        contact_address_street: '',
        contact_address_city: '',
        contact_address_state: '',
        contact_address_zipcode: '',
        contact_address_country: '',
        contact_description: '',
    }

    const validationSchema = Yup.object().shape({
        contact_first_name: Yup.string().required('First Name is required'),
        contact_email: Yup.string().email('Invalid email'),
        contact_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone number is required'),
        country_phone_code: Yup.string().required('Country code is required'),
        contact_assistant_phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const CustomerData = new FormData()
            if (imageUpload.length > 0) {
                CustomerData.append("contact_profile", imageUpload[0]);
            }
            const CustomerNumber = `${values.country_phone_code}${values.contact_phone_number}`;
            const Contact_tags = tagsSelected.map((option: any) => option.value);
            CustomerData.append('contact_tags', JSON.stringify(Contact_tags));
            const LeadOwner = leadsOwnerSelected?.value;
            const response = await CONTACTS_POST_DATA(
                CustomerData,
                LeadOwner ? LeadOwner : "",
                values.contact_lead_source,
                values.contact_title,
                values.contact_first_name,
                values.contact_last_name,
                CustomerNumber,
                values.contact_email,
                values.contact_dob,
                values.contact_department,
                values.contact_assistant,
                values.contact_assistant_phone,
                values.contact_current_qualification,
                values.contact_country_interested,
                values.contact_course_interested,
                values.contact_status,
                values.contact_address_line_1,
                values.contact_address_line_2,
                values.contact_address_street,
                values.contact_address_city,
                values.contact_address_state,
                values.contact_address_zipcode,
                values.contact_address_country,
                values.contact_description,
                user_id
            )
            if (response.data.message === 'success') {
                toast.success("Contact Added successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setImageUpload([])
                setTagsSelected([]);
                setLeadsOwnerSelected(null as any)
                actions.resetForm()
                fetchContactsList('0')
                setOpenModal(false)
            } else if (response.data.message === 'mobile_already_exist') {
                toast.error("Mobile Number Already Exit", {
                    style: {
                        background: '#FFFF00',
                        color: 'black',
                    }, duration: 5000
                });
                setLoading(false);
            } else {
                toast.error("Failed to add user", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Add New Contact:</h5>
                    <button type="button" className="btn-close" onClick={() => setOpenModal(false)} aria-label="Close"></button>
                </div>
                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <div className="modal-body form_content">
                                <h5 className='ms-5'>Contact Image</h5>
                                <div className='symbol symbol-50px symbol-circle ms-5 mt-3 cursor-pointer'>
                                    {imageUpload.length > 0 ? (
                                        (imageUpload.map(file => (
                                            <>
                                                <span onClick={handleRemoveImage} className='cursor-pointer'>
                                                    <KTIcon iconName='cross-square' className='fs-1 text-danger float-end' />
                                                </span>
                                                <div key={file.name} className='symbol symbol-50px symbol-circle ms-5'>
                                                    <img
                                                        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='w-100'
                                                    />
                                                </div>
                                            </>
                                        )))
                                    ) :
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='blank_image' onClick={handleImageModal} />
                                    }
                                </div>
                                {imageUpload.length > 0 ? null : (
                                    <div className='w-25 mt-5'>
                                        {imageModal && (
                                            <div {...getRootProps1({ className: "dropzone" })} >
                                                <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Choose JPG or PNG File
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <h5 className='mt-5 forms_heading'>Contact Information</h5>
                                <div className='ms-5 me-5'>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="contact_owner" className='form-label mb-3 required'>Contact Owner</label>
                                                <Select
                                                    id="dropdown"
                                                    options={AllUser()}
                                                    value={leadsOwnerSelected}
                                                    name="contact_owner"
                                                    placeholder="Search or select..."
                                                    isSearchable
                                                    menuPosition="fixed"
                                                    onChange={(leadsOwnerSelected) => {
                                                        handleLeadsChange(leadsOwnerSelected);
                                                    }}
                                                />
                                                <ErrorMessage name="contact_owner" component="div" className="text-danger" />
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_lead_source" >Lead Source</label>
                                                <Field as="select" name="contact_lead_source" aria-label="Select example" className="form-select form-select-sm">
                                                    <option value="None">-None-</option>
                                                    <option value="Advertisement">Advertisement</option>
                                                    <option value="Employee Referral">Employee Referral</option>
                                                    <option value="Facebook">Facebook</option>
                                                    <option value="Google">Google</option>
                                                    <option value="Instagram">Instagram</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Direct Walking">Direct Walking</option>
                                                    <option value="Cold Call">Cold Call</option>
                                                    <option value="External Referral">External Referral</option>
                                                    <option value="Partner">Partner</option>
                                                    <option value="Public Relations">Public Relations</option>
                                                    <option value="Web Research">Web Research</option>
                                                </Field>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label htmlFor="contact_title" className='form-label mb-3'>Title</label>
                                                <Field type="text" name="contact_title" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="contact_first_name" className='form-label mb-3 required'>First Name</label>
                                                <Field type="text" name="contact_first_name" className="form-control form-control-sm" />
                                                <ErrorMessage name="contact_first_name" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="contact_last_name" className='form-label mb-3'>Last Name</label>
                                                <Field type="text" name="contact_last_name" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3 required" htmlFor="contact_phone_number" >Phone Number</label>
                                                <div className="input-group">
                                                    <Field name="country_phone_code" as="select" className="form-select form-select-sm w-25" id="phone">
                                                        <option value="">select</option>
                                                        <option value="93">Afghanistan +93</option>
                                                        <option value="358">Aland Islands +358</option>
                                                        <option value="355">Albania +355</option>
                                                        <option value="213">Algeria +213</option>
                                                        <option value="1684">American Samoa +1684</option>
                                                        <option value="376">Andorra +376</option>
                                                        <option value="244">Angola +244</option>
                                                        <option value="1264">Anguilla +1264</option>
                                                        <option value="672">Antarctica +672</option>
                                                        <option value="1268">Antigua and Barbuda +1268</option>
                                                        <option value="54">Argentina +54</option>
                                                        <option value="374">Armenia +374</option>
                                                        <option value="297">Aruba +297</option>
                                                        <option value="61">Australia +61</option>
                                                        <option value="43">Austria +43</option>
                                                        <option value="994">Azerbaijan +994</option>
                                                        <option value="1242">Bahamas +1242</option>
                                                        <option value="973">Bahrain +973</option>
                                                        <option value="880">Bangladesh +880</option>
                                                        <option value="1246">Barbados +1246</option>
                                                        <option value="375">Belarus +375</option>
                                                        <option value="32">Belgium +32</option>
                                                        <option value="501">Belize +501</option>
                                                        <option value="229">Benin +229</option>
                                                        <option value="1441">Bermuda +1441</option>
                                                        <option value="975">Bhutan +975</option>
                                                        <option value="591">Bolivia +591</option>
                                                        <option value="599">Bonaire, Sint Eustatius and Saba +599</option>
                                                        <option value="387">Bosnia and Herzegovina +387</option>
                                                        <option value="267">Botswana +267</option>
                                                        <option value="55">Bouvet Island +55</option>
                                                        <option value="55">Brazil +55</option>
                                                        <option value="246">British Indian Ocean Territory +246</option>
                                                        <option value="673">Brunei Darussalam +673</option>
                                                        <option value="359">Bulgaria +359</option>
                                                        <option value="226">Burkina Faso +226</option>
                                                        <option value="257">Burundi +257</option>
                                                        <option value="855">Cambodia +855</option>
                                                        <option value="237">Cameroon +237</option>
                                                        <option value="1">Canada +1</option>
                                                        <option value="238">Cape Verde +238</option>
                                                        <option value="1345">Cayman Islands +1345</option>
                                                        <option value="236">Central African Republic +236</option>
                                                        <option value="235">Chad +235</option>
                                                        <option value="56">Chile +56</option>
                                                        <option value="86">China +86</option>
                                                        <option value="61">Christmas Island +61</option>
                                                        <option value="672">Cocos (Keeling) Islands +672</option>
                                                        <option value="57">Colombia +57</option>
                                                        <option value="269">Comoros +269</option>
                                                        <option value="242">Congo +242</option>
                                                        <option value="242">Congo, Democratic Republic of the Congo +242</option>
                                                        <option value="682">Cook Islands +682</option>
                                                        <option value="506">Costa Rica +506</option>
                                                        <option value="225">Cote D'Ivoire +225</option>
                                                        <option value="385">Croatia +385</option>
                                                        <option value="53">Cuba +53</option>
                                                        <option value="599">Curacao +599</option>
                                                        <option value="357">Cyprus +357</option>
                                                        <option value="420">Czech Republic +420</option>
                                                        <option value="45">Denmark +45</option>
                                                        <option value="253">Djibouti +253</option>
                                                        <option value="1767">Dominica +1767</option>
                                                        <option value="1809">Dominican Republic +1809</option>
                                                        <option value="593">Ecuador +593</option>
                                                        <option value="20">Egypt +20</option>
                                                        <option value="503">El Salvador +503</option>
                                                        <option value="240">Equatorial Guinea +240</option>
                                                        <option value="291">Eritrea +291</option>
                                                        <option value="372">Estonia +372</option>
                                                        <option value="251">Ethiopia +251</option>
                                                        <option value="500">Falkland Islands (Malvinas) +500</option>
                                                        <option value="298">Faroe Islands +298</option>
                                                        <option value="679">Fiji +679</option>
                                                        <option value="358">Finland +358</option>
                                                        <option value="33">France +33</option>
                                                        <option value="594">French Guiana +594</option>
                                                        <option value="689">French Polynesia +689</option>
                                                        <option value="262">French Southern Territories +262</option>
                                                        <option value="241">Gabon +241</option>
                                                        <option value="220">Gambia +220</option>
                                                        <option value="995">Georgia +995</option>
                                                        <option value="49">Germany +49</option>
                                                        <option value="233">Ghana +233</option>
                                                        <option value="350">Gibraltar +350</option>
                                                        <option value="30">Greece +30</option>
                                                        <option value="299">Greenland +299</option>
                                                        <option value="1473">Grenada +1473</option>
                                                        <option value="590">Guadeloupe +590</option>
                                                        <option value="1671">Guam +1671</option>
                                                        <option value="502">Guatemala +502</option>
                                                        <option value="44">Guernsey +44</option>
                                                        <option value="224">Guinea +224</option>
                                                        <option value="245">Guinea-Bissau +245</option>
                                                        <option value="592">Guyana +592</option>
                                                        <option value="509">Haiti +509</option>
                                                        <option value="39">Holy See (Vatican City State) +39</option>
                                                        <option value="504">Honduras +504</option>
                                                        <option value="852">Hong Kong +852</option>
                                                        <option value="36">Hungary +36</option>
                                                        <option value="354">Iceland +354</option>
                                                        <option value="91">India +91</option>
                                                        <option value="62">Indonesia +62</option>
                                                        <option value="98">Iran, Islamic Republic of +98</option>
                                                        <option value="964">Iraq +964</option>
                                                        <option value="353">Ireland +353</option>
                                                        <option value="44">Isle of Man +44</option>
                                                        <option value="972">Israel +972</option>
                                                        <option value="39">Italy +39</option>
                                                        <option value="1876">Jamaica +1876</option>
                                                        <option value="81">Japan +81</option>
                                                        <option value="44">Jersey +44</option>
                                                        <option value="962">Jordan +962</option>
                                                        <option value="7">Kazakhstan +7</option>
                                                        <option value="254">Kenya +254</option>
                                                        <option value="686">Kiribati +686</option>
                                                        <option value="850">Korea, Democratic People's Republic of +850</option>
                                                        <option value="82">Korea, Republic of +82</option>
                                                        <option value="383">Kosovo +383</option>
                                                        <option value="965">Kuwait +965</option>
                                                        <option value="996">Kyrgyzstan +996</option>
                                                        <option value="856">Lao People's Democratic Republic +856</option>
                                                        <option value="371">Latvia +371</option>
                                                        <option value="961">Lebanon +961</option>
                                                        <option value="266">Lesotho +266</option>
                                                        <option value="231">Liberia +231</option>
                                                        <option value="218">Libyan Arab Jamahiriya +218</option>
                                                        <option value="423">Liechtenstein +423</option>
                                                        <option value="370">Lithuania +370</option>
                                                        <option value="352">Luxembourg +352</option>
                                                        <option value="853">Macao +853</option>
                                                        <option value="389">Macedonia, the Former Yugoslav Republic of +389</option>
                                                        <option value="261">Madagascar +261</option>
                                                        <option value="265">Malawi +265</option>
                                                        <option value="60">Malaysia +60</option>
                                                        <option value="960">Maldives +960</option>
                                                        <option value="223">Mali +223</option>
                                                        <option value="356">Malta +356</option>
                                                        <option value="692">Marshall Islands +692</option>
                                                        <option value="596">Martinique +596</option>
                                                        <option value="222">Mauritania +222</option>
                                                        <option value="230">Mauritius +230</option>
                                                        <option value="262">Mayotte +262</option>
                                                        <option value="52">Mexico +52</option>
                                                        <option value="691">Micronesia, Federated States of +691</option>
                                                        <option value="373">Moldova, Republic of +373</option>
                                                        <option value="377">Monaco +377</option>
                                                        <option value="976">Mongolia +976</option>
                                                        <option value="382">Montenegro +382</option>
                                                        <option value="1664">Montserrat +1664</option>
                                                        <option value="212">Morocco +212</option>
                                                        <option value="258">Mozambique +258</option>
                                                        <option value="95">Myanmar +95</option>
                                                        <option value="264">Namibia +264</option>
                                                        <option value="674">Nauru +674</option>
                                                        <option value="977">Nepal +977</option>
                                                        <option value="31">Netherlands +31</option>
                                                        <option value="599">Netherlands Antilles +599</option>
                                                        <option value="687">New Caledonia +687</option>
                                                        <option value="64">New Zealand +64</option>
                                                        <option value="505">Nicaragua +505</option>
                                                        <option value="227">Niger +227</option>
                                                        <option value="234">Nigeria +234</option>
                                                        <option value="683">Niue +683</option>
                                                        <option value="672">Norfolk Island +672</option>
                                                        <option value="1670">Northern Mariana Islands +1670</option>
                                                        <option value="47">Norway +47</option>
                                                        <option value="968">Oman +968</option>
                                                        <option value="92">Pakistan +92</option>
                                                        <option value="680">Palau +680</option>
                                                        <option value="970">Palestinian Territory, Occupied +970</option>
                                                        <option value="507">Panama +507</option>
                                                        <option value="675">Papua New Guinea +675</option>
                                                        <option value="595">Paraguay +595</option>
                                                        <option value="51">Peru +51</option>
                                                        <option value="63">Philippines +63</option>
                                                        <option value="64">Pitcairn +64</option>
                                                        <option value="48">Poland +48</option>
                                                        <option value="351">Portugal +351</option>
                                                        <option value="1787">Puerto Rico +1787</option>
                                                        <option value="974">Qatar +974</option>
                                                        <option value="262">Reunion +262</option>
                                                        <option value="40">Romania +40</option>
                                                        <option value="7">Russian Federation +7</option>
                                                        <option value="250">Rwanda +250</option>
                                                        <option value="590">Saint Barthelemy +590</option>
                                                        <option value="290">Saint Helena +290</option>
                                                        <option value="1869">Saint Kitts and Nevis +1869</option>
                                                        <option value="1758">Saint Lucia +1758</option>
                                                        <option value="590">Saint Martin +590</option>
                                                        <option value="508">Saint Pierre and Miquelon +508</option>
                                                        <option value="1784">Saint Vincent and the Grenadines +1784</option>
                                                        <option value="684">Samoa +684</option>
                                                        <option value="378">San Marino +378</option>
                                                        <option value="239">Sao Tome and Principe +239</option>
                                                        <option value="966">Saudi Arabia +966</option>
                                                        <option value="221">Senegal +221</option>
                                                        <option value="381">Serbia +381</option>
                                                        <option value="381">Serbia and Montenegro +381</option>
                                                        <option value="248">Seychelles +248</option>
                                                        <option value="232">Sierra Leone +232</option>
                                                        <option value="65">Singapore +65</option>
                                                        <option value="721">Sint Maarten +721</option>
                                                        <option value="421">Slovakia +421</option>
                                                        <option value="386">Slovenia +386</option>
                                                        <option value="677">Solomon Islands +677</option>
                                                        <option value="252">Somalia +252</option>
                                                        <option value="27">South Africa +27</option>
                                                        <option value="500">South Georgia and the South Sandwich Islands +500</option>
                                                        <option value="211">South Sudan +211</option>
                                                        <option value="34">Spain +34</option>
                                                        <option value="94">Sri Lanka +94</option>
                                                        <option value="249">Sudan +249</option>
                                                        <option value="597">Suriname +597</option>
                                                        <option value="47">Svalbard and Jan Mayen +47</option>
                                                        <option value="268">Swaziland +268</option>
                                                        <option value="46">Sweden +46</option>
                                                        <option value="41">Switzerland +41</option>
                                                        <option value="963">Syrian Arab Republic +963</option>
                                                        <option value="886">Taiwan, Province of China +886</option>
                                                        <option value="992">Tajikistan +992</option>
                                                        <option value="255">Tanzania, United Republic of +255</option>
                                                        <option value="66">Thailand +66</option>
                                                        <option value="670">Timor-Leste +670</option>
                                                        <option value="228">Togo +228</option>
                                                        <option value="690">Tokelau +690</option>
                                                        <option value="676">Tonga +676</option>
                                                        <option value="1868">Trinidad and Tobago +1868</option>
                                                        <option value="216">Tunisia +216</option>
                                                        <option value="90">Turkey +90</option>
                                                        <option value="7370">Turkmenistan +7370</option>
                                                        <option value="1649">Turks and Caicos Islands +1649</option>
                                                        <option value="688">Tuvalu +688</option>
                                                        <option value="256">Uganda +256</option>
                                                        <option value="380">Ukraine +380</option>
                                                        <option value="971">United Arab Emirates +971</option>
                                                        <option value="44">United Kingdom +44</option>
                                                        <option value="1">United States +1</option>
                                                        <option value="1">United States Minor Outlying Islands +1</option>
                                                        <option value="598">Uruguay +598</option>
                                                        <option value="998">Uzbekistan +998</option>
                                                        <option value="678">Vanuatu +678</option>
                                                        <option value="58">Venezuela +58</option>
                                                        <option value="84">Viet Nam +84</option>
                                                        <option value="1284">Virgin Islands, British +1284</option>
                                                        <option value="1340">Virgin Islands, U.s. +1340</option>
                                                        <option value="681">Wallis and Futuna +681</option>
                                                        <option value="212">Western Sahara +212</option>
                                                        <option value="967">Yemen +967</option>
                                                        <option value="260">Zambia +260</option>
                                                        <option value="263">Zimbabwe +263</option>
                                                    </Field>
                                                    <Field type="number" name="contact_phone_number" className="form-control form-control-sm w-75" />
                                                </div>
                                                <ErrorMessage name="country_phone_code" component="div" className="text-danger" />
                                                <ErrorMessage name="contact_phone_number" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_email" >Email Address</label>
                                                <Field type="text" name="contact_email" className="form-control form-control-sm" />
                                                <ErrorMessage name="contact_email" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_dob">Date of Birth</label>
                                                <Field type="date" name="contact_dob" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_department" >Department</label>
                                                <Field type="text" name="contact_department" className="form-control form-control-sm" />
                                                <ErrorMessage name="contact_department" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_assistant">Assistant Name</label>
                                                <Field type="text" name="contact_assistant" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_assistant_phone" >Assistant Phone</label>
                                                <Field type="number" name="contact_assistant_phone" className="form-control form-control-sm" />
                                                <ErrorMessage name="contact_assistant_phone" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_current_qualification" >Current Qualification</label>
                                                <Field type="text" name="contact_current_qualification" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'> 
                                            <div className="form-group mb-3">
                                                <label htmlFor="contact_country_interested" className="form-label mb-3 required">Country Interested</label>
                                                <Field as="select" name="contact_country_interested" aria-label="Select example" className="form-select form-select-sm">
                                                    <option value="">Select Country</option>
                                                    <option value="US">US</option>
                                                    <option value="UK">UK</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Dubai">Dubai</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Europe">Europe</option>
                                                </Field> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_course_interested" >Course Interested</label>
                                                <Field type="text" name="contact_course_interested" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_status" >Contact Status</label>
                                                <Field as="select" name="contact_status" aria-label="Select example" className="form-select form-select-sm" >
                                                    <option value="0">-None-</option>
                                                    <option value="1">Interested</option>
                                                    <option value="2">Not interested</option>
                                                    <option value="3">Beyond Intake</option>
                                                    <option value="4">Lost to competitor</option>
                                                    <option value="5">Financially not sound</option>
                                                    <option value="6">Loan Rejected</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className='mt-5 forms_heading'>Address Information</h5>
                                <div className='ms-5 me-5'>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_line_1">Address Line 1</label>
                                                <Field
                                                    name="contact_address_line_1"
                                                    className="form-control form-control-sm"
                                                    as="textarea"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_line_2" >Address Line 2</label>
                                                <Field
                                                    name="contact_address_line_2"
                                                    className="form-control form-control-sm"
                                                    as="textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_street" >Street</label>
                                                <Field type="text" name="contact_address_street" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_city" >City</label>
                                                <Field type="text" name="contact_address_city" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_state" >State</label>
                                                <Field type="text" name="contact_address_state" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_zipcode" >Zipcode</label>
                                                <Field type="text" name="contact_address_zipcode" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label className="form-label mb-3" htmlFor="contact_address_country" >Country</label>
                                                <Field type="text" name="contact_address_country" className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 className='mt-5 forms_heading'>Tags & Description Information</h5>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="form-label mt-5 mb-3" htmlFor="contact_tags">Tags</label>
                                            <button type='button'
                                                className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                            >
                                                <i className="bi bi-plus plus_icon" />Add New
                                            </button>
                                            <button type='button'
                                                className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                onClick={() => {
                                                    setTagsAdd(false); // Close the tags add section
                                                }}
                                            >
                                                <i className="bi bi-x plus_icon" />Close
                                            </button>
                                            {tagsAdd && (
                                                <div className='d-flex'>
                                                    <Field
                                                        type="text"
                                                        value={newTags}
                                                        name='tags'
                                                        onChange={(e: any) => {
                                                            setNewTags(e.target.value);
                                                        }}
                                                        className="form-control mt-5"
                                                        placeholder='Enter New Tags'
                                                    />
                                                    <button
                                                        type='button'
                                                        onClick={() => handleAddTags({ tags: newTags })}
                                                        className="btn btn-primary Add_new_cate_btn"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            )}
                                            {tagsError && <div className="text-danger">{tagsError}</div>}
                                            <Select
                                                isMulti
                                                options={getTags && getTags.length > 0 ? getTags.map((item: any) => ({
                                                    value: item.tag_name,
                                                    label: item.tag_name
                                                })) : []}
                                                value={tagsSelected}
                                                name='contact_tags'
                                                placeholder='Select Tags'
                                                onChange={(tagsSelected) => {
                                                    handleTags(tagsSelected);
                                                }}
                                                className='w-100 mt-2'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="contact_description" >Description</label>
                                            <Field
                                                name="contact_description"
                                                className="form-control form-control-sm"
                                                as="textarea"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default ContactForm