import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import Select from 'react-select';
import { useDropzone } from "react-dropzone";
import { handleImageDrop } from '../CompressImage';
import { LEADS_PUT_DATA } from '../../modules/auth/core/_requests';
import Loader from '../Loader';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import AllUser from '../AllUser';
import toast from 'react-hot-toast';


const LeadsEdit = ({ fetchLeadsData, setLeadsData, setIsLoading, setShowEditModal, loadingEdit, imageUpload,
    setImageUpload, initialEditValues, validationEditSchema, leadsOwnerSelected, setLeadsOwnerSelected,
    tagsAdd, getTags, setTagsAdd, newTags, setNewTags, tagsSelected, handleAddTags, handleTags, tagsError, setTagsError,
}: any) => {
    const [loading, setLoading] = useState(false)
    const [imageModal, setImageModal] = useState(false)


    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const userId = userDetailsObject?.user_id ? userDetailsObject?.user_id : "";

    const handleImageModal = () => {
        if (imageUpload.length > 0) {
            setImageModal(false)
        } else {
            setImageModal(true)
        }
    }

    const handleRemoveImage = () => {
        setImageUpload([]);
    }

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const handleLeadsOwner = (selected: any) => {
        setLeadsOwnerSelected(selected);
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const LeadsData = new FormData()
            if (imageUpload.length > 0) {
                LeadsData.append("leads_profile_upd", imageUpload[0]);
            }
            const LeadOwner = leadsOwnerSelected?.value;
            const Leads_tags = tagsSelected.map((option: any) => option.value);
            LeadsData.append('leads_tags_upd', JSON.stringify(Leads_tags));
            const response = await LEADS_PUT_DATA(
                LeadsData,
                values.leads_id_upd,
                LeadOwner ? LeadOwner : "",
                values.leads_title_upd,
                values.leads_first_name_upd,
                values.leads_last_name_upd,
                values.leads_phone_number_upd,
                values.leads_email_upd,
                values.leads_current_qualification_upd,
                values.leads_country_interested_upd,
                values.leads_course_interested_upd,
                values.leads_source_upd,
                values.leads_status_upd,
                values.leads_address_street_upd,
                values.leads_address_city_upd,
                values.leads_address_state_upd,
                values.leads_address_country_upd,
                values.leads_description_upd,
                userId
            )
            if (response.data.message === 'success') {
                toast.success("Leads Updated successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                fetchLeadsData("0", setLeadsData, setIsLoading)
                setLoading(false);
                setShowEditModal(false);
                actions.resetForm();
            } else {
                toast.error("Failed to update leads", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Leads Details:</h5>
                    <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                </div>
                {loadingEdit ? (
                    <Loader />
                ) : (
                    <>
                        <Formik validationSchema={validationEditSchema} initialValues={initialEditValues} onSubmit={handleSubmit}>
                            {() => (
                                <Form>
                                    <div className="modal-body form_content">
                                        <div className="form-group">
                                            <input type="hidden" name="leads_id_upd" className="form-control" />
                                        </div>

                                        <h5>Lead Image</h5>
                                        <div className='symbol symbol-50px symbol-circle ms-5 mt-3'>
                                            {imageUpload.length > 0 ? (
                                                (imageUpload.map((file: any) => (
                                                    <>
                                                        <span onClick={handleRemoveImage} className='cursor-pointer'>
                                                            <KTIcon iconName='cross-square' className='fs-1 text-danger float-end' />
                                                        </span>
                                                        <div key={file.name} className='symbol symbol-50px symbol-circle ms-5'>
                                                            <img
                                                                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                                alt={file.name}
                                                            />
                                                        </div>
                                                    </>
                                                )))
                                            ) :
                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='blank_image' onClick={handleImageModal} />
                                            }
                                        </div>
                                        {imageUpload.length > 0 ? null : (
                                            <div className='w-25 mt-5'>
                                                {imageModal && (
                                                    <div {...getRootProps1({ className: "dropzone" })} >
                                                        <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                        <div className="text-center">
                                                            <p className="dropzone-content">
                                                                Choose JPG or PNG File
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <h5 className='mt-5 forms_heading'>Lead Information</h5>
                                        <div className='ms-5 me-5'>
                                            <div className='row mb-3'>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="leads_owner_upd" className='form-label mb-3 required'>Lead Owner</label>
                                                        <Select
                                                            id="dropdown"
                                                            options={AllUser()}
                                                            value={leadsOwnerSelected}
                                                            name="leads_owner_upd"
                                                            placeholder="Search or select..."
                                                            menuPosition="fixed"
                                                            isSearchable
                                                            onChange={(leadsOwnerSelected) => {
                                                                handleLeadsOwner(leadsOwnerSelected);
                                                            }}
                                                        />
                                                        <ErrorMessage name="leads_owner_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="leads_title_upd" className='form-label mb-3'>Lead Title</label>
                                                        <Field type="text" name="leads_title_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_source_upd" >Lead Source</label>
                                                        <Field as="select" name="leads_source_upd" aria-label="Select example" className="form-select form-select-sm">
                                                            <option value="None">-None-</option>
                                                            <option value="Advertisement">Advertisement</option>
                                                            <option value="Employee Referral">Employee Referral</option>
                                                            <option value="Facebook">Facebook</option>
                                                            <option value="Google">Google</option>
                                                            <option value="Instagram">Instagram</option>
                                                            <option value="Email">Email</option>
                                                            <option value="Direct Walking">Direct Walking</option>
                                                            <option value="Cold Call">Cold Call</option>
                                                            <option value="External Referral">External Referral</option>
                                                            <option value="Partner">Partner</option>
                                                            <option value="Public Relations">Public Relations</option>
                                                            <option value="Web Research">Web Research</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="leads_first_name_upd" className='form-label mb-3 required'>First Name</label>
                                                        <Field type="text" name="leads_first_name_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="leads_first_name_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="leads_last_name_upd" className='form-label mb-3'>Last Name</label>
                                                        <Field type="text" name="leads_last_name_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_phone_number_upd" >Phone Number</label>
                                                        <Field type="number" name="leads_phone_number_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="leads_phone_number_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_email_upd" >Email Address</label>
                                                        <Field type="text" name="leads_email_upd" className="form-control form-control-sm" />
                                                        <ErrorMessage name="leads_email_upd" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_current_qualification_upd" >Current Qualification</label>
                                                        <Field type="text" name="leads_current_qualification_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="leads_country_interested_upd" className="form-label mb-3 required">Country Interested</label>
                                                        <Field as="select" name="leads_country_interested_upd" aria-label="Select example" className="form-select form-select-sm">
                                                            <option value="">Select Country</option>
                                                            <option value="US">US</option>
                                                            <option value="UK">UK</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Dubai">Dubai</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Europe">Europe</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_course_interested_upd" >Course Interested</label>
                                                        <Field type="text" name="leads_course_interested_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_status_upd" >Lead Status</label>
                                                        <Field as="select" name="leads_status_upd" aria-label="Select example" className="form-select form-select-sm" >
                                                            <option value="0">-None-</option>
                                                            <option value="1">Interested</option>
                                                            <option value="2">Not interested</option>
                                                            <option value="3">Beyond Intake</option>
                                                            <option value="4">Lost to competitor</option>
                                                            <option value="5">Financially not sound</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className='mt-5 forms_heading'>Address Information</h5>
                                        <div className='ms-5 me-5'>
                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_address_street_upd" >Street</label>
                                                        <Field type="text" name="leads_address_street_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_address_city_upd" >City</label>
                                                        <Field type="text" name="leads_address_city_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_address_state_upd" >State</label>
                                                        <Field type="text" name="leads_address_state_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label mb-3" htmlFor="leads_address_country_upd" >Country</label>
                                                        <Field type="text" name="leads_address_country_upd" className="form-control form-control-sm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <h5 className='mt-5 forms_heading'>Tags & Description Information</h5>
                                        <div className='row mt-3'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label mt-5 mb-3" htmlFor="contact_tags_upd">Tags</label>
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                        onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                                    >
                                                        <i className="bi bi-plus plus_icon" />Add New
                                                    </button>
                                                    {tagsError && <div className="text-danger">{tagsError}</div>}
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                        onClick={() => {
                                                            setTagsError(''); // Clear the error message
                                                            setTagsAdd(false); // Close the tags add section
                                                        }}
                                                    >
                                                        <i className="bi bi-x plus_icon" />Close
                                                    </button>
                                                    {tagsAdd && (
                                                        <div className='d-flex'>
                                                            <Field
                                                                type="text"
                                                                value={newTags}
                                                                name='tags'
                                                                onChange={(e: any) => {
                                                                    setNewTags(e.target.value);
                                                                    // Clear the error message when the user starts typing
                                                                    setTagsError('');
                                                                }}
                                                                className="form-control mt-5"
                                                                placeholder='Enter New Tags'
                                                            />
                                                            <button
                                                                type='button'
                                                                onClick={() => handleAddTags({ tags: newTags })}
                                                                className="btn btn-primary Add_new_cate_btn"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    )}
                                                    <Select
                                                        isMulti
                                                        options={getTags && getTags.length > 0 ? getTags.map((item: any) => ({
                                                            value: item.tag_name,
                                                            label: item.tag_name
                                                        })) : []}
                                                        value={tagsSelected}
                                                        name='contact_tags_upd'
                                                        placeholder='Select Tags'
                                                        onChange={(tagsSelected) => {
                                                            handleTags(tagsSelected);
                                                        }}
                                                        className='w-100 mt-2'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group ms-5 me-5">
                                                    <label className="form-label mt-3 mb-3" htmlFor="leads_description_upd" >Description</label>
                                                    <Field
                                                        name="leads_description_upd"
                                                        className="form-control form-control-sm"
                                                        as="textarea"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <div className='d-flex justify-content-end'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        </>
    )
}

export default LeadsEdit