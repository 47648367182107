import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import Swal from 'sweetalert2';
import SubscriptionAlertContent from './SubscriptionAlertContent';

const SubscriptionAlert = ({details}:any) => {
  useEffect(() => {
    const contentHtml = ReactDOMServer.renderToString(<SubscriptionAlertContent details={details} />);

    Swal.fire({
      html: contentHtml, 
      width: 1000,
      padding: "1em",
      color: "#716add",
      backdrop: `
        rgba(23,23,23,0.89)`,
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      heightAuto: false, // Disable auto height adjustment
      customClass: {
        popup: 'custom-popup-class', // Define custom CSS class for the popup
      },
    });
  }, []);

  return null;
};

export default SubscriptionAlert;