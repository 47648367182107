import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import UserForm from './UserForm'
import toast, { Toaster } from "react-hot-toast"
import { GET_ALL_USERS, USER_EDIT_DATA } from '../auth/core/_requests'
import Pagination from '../../pages/Pagination';
import * as Yup from 'yup'
import Loader from '../../pages/Loader';
import UserEdit from './UserEdit';
import { UserDetails } from '../../pages/Interface'


const User = () => {
    const [userModal, setUserModal] = useState(false)
    const [refreshUsers, setRefreshUsers] = useState(false);
    const [userData, setUserData] = useState<UserDetails[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [showEditModal, setShowEditModal] = useState(false)
    const [imageUpload, setImageUpload] = useState<File[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            user_id_upd: '',
            user_name_upd: '',
            user_email_upd: '',
            user_mobile_upd: '',
            user_dob_upd: '',
            user_address_upd: '',
            user_role_upd: '',
            user_status_upd: '',
            user_department_upd: '',
            user_date_of_join_upd: '',
            user_remarks_upd: '',
        }
    )

    const validationEditSchema = Yup.object().shape({
        user_name_upd: Yup.string().required('Full Name is required'),
        user_email_upd: Yup.string().email('Invalid email').required('Email is required'),
        user_mobile_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Mobile Number is required'),
        user_address_upd: Yup.string().required('Address is required'),
        user_role_upd: Yup.string().required('Role is required'),
        user_status_upd: Yup.string().required('Status is required'),
    });

    const handleUserModal = () => {
        setUserModal(true)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const fetchUserData = async (all_users: any) => {
        try {
            const response = await GET_ALL_USERS(all_users);
            setUserData(response.data.user_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchUserData("0");
    }, []);

    const RefreshUsersData = () => {
        setRefreshUsers(true);
        fetchUserData("0")
            .then(response => {
                setRefreshUsers(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const handleEditUser = async (user_id: any) => {
        try {
            setLoadingEdit(true)
            setShowEditModal(true)
            const response = await USER_EDIT_DATA(user_id);
            const initialData = response.data.account_details[0]
            setImageUpload([response.data.account_details[0].user_pic]);
            setInitialEditValues({
                user_id_upd: initialData.user_id,
                user_name_upd: initialData.user_name,
                user_email_upd: initialData.user_email,
                user_mobile_upd: initialData.user_mobile,
                user_dob_upd: initialData.user_dob,
                user_address_upd: initialData.user_address,
                user_role_upd: initialData.user_role,
                user_department_upd: initialData.user_department,
                user_date_of_join_upd: initialData.user_doj,
                user_status_upd: initialData.user_status,
                user_remarks_upd: initialData.user_remarks,
            })
        } catch (error) {
            console.error('Error fetching members:', error);
        } finally {
            setLoadingEdit(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const filteredUserDetails = userData.filter((user) => {
        const searchTerm = searchQuery.toLowerCase();
        const userId = user.user_id && typeof user.user_id === 'string' ? user.user_id.toLowerCase() : '';
        const userName = user.user_name && typeof user.user_name === 'string' ? user.user_name.toLowerCase() : '';
        return (
            userId.includes(searchTerm) ||
            userName.includes(searchTerm) ||
            (user.user_role == '2' && "admin".includes(searchTerm)) ||
            (user.user_role == '3' && "employee".includes(searchTerm))
        );
    });


    const itemsPerPage = 10;
    const totalItems = userData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const userDetails = filteredUserDetails.slice(startIndex, startIndex + itemsPerPage);

    return (
        <>
            <div className='card bg_none'>
                <div className='card-header p-0 border-0'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Users</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {userData.length}  Users</span>
                    </h3>
                    <div className='card-toolbar'>
                        <button className="btn btn-primary refresh_icon me-5" onClick={RefreshUsersData}>{!refreshUsers ? (
                            <i className="bi bi-arrow-clockwise fs-2"></i>
                        ) : (
                            <i className="spinner-border spinner-border-sm fs-2"></i>
                        )} </button>

                        <div className="d-flex align-items-center me-5">
                            <div className="input-group">
                                <input type="search" placeholder='Search anything...' className="form-control w-50" value={searchQuery} onChange={handleSearchChange} />
                                <button type="button" className="btn btn-primary">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-primary refresh_icon' onClick={handleUserModal}>
                                <KTIcon iconName='plus-circle' className='fs-2' />
                                Create User
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <Toaster />

            <>
                {isLoading ? (
                    <>
                        <Loader />
                    </>
                ) : (userDetails.length > 0 ? (
                    <div className={`table-responsive mt-5 ${refreshUsers ? 'd opacity-50' : ''}`} >
                        <table className='table align-middle gs-0 gy-4'>
                            <thead className='table_bg_color'>
                                <tr className='fw-bold fs-6'>
                                    <th className='ps-5'>S.No</th>
                                    <th>Role</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Created on</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='bg-light'>
                                {userDetails.map((user, index) => (
                                    <tr key={index} className='border-bottom  border-gray-300'>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 ps-4 text-center'>{user.user_id}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {user.user_role == "1" ? (
                                                <span className='badge bg-info text-white'>Super Admin</span>
                                            ) : user.user_role == "3" ? (
                                                <span className='badge bg-dark text-white'>Employee</span>
                                            ) : user.user_role == "2" ? (
                                                <span className="badge bg-primary text-white">Admin</span>
                                            ) : null}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_name}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_email}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_mobile}</td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_status == '1' ? (
                                            <span className='badge bg-success text-white'>Active</span>
                                        ) : <span className='badge bg-danger text-white'>Deactivate</span>}
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_created_by}
                                            <span className="fw-semibold d-block fs-7">{user.user_created_on}</span>
                                        </td>

                                        <td style={{ paddingTop: "10px" }}>
                                            <div className="dropdown">
                                                <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-5"></i>
                                                </button>
                                                {user.user_role != "1" && (
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleEditUser(user.user_id)}
                                                            >
                                                                Edit
                                                            </button>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div>
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No users found</h3>
                        </div>
                    </div>
                )}
            </>

            <div className={`modal${userModal ? ' show' : ''}`} role="dialog" style={{ display: userModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg  modal-dialog-centered" role="document">
                    <UserForm setUserModal={setUserModal} fetchUserData={fetchUserData} />
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <UserEdit setShowEditModal={setShowEditModal} loadingEdit={loadingEdit} imageUpload={imageUpload}
                            initialEditValues={initialEditValues} setImageUpload={setImageUpload}
                            validationEditSchema={validationEditSchema} fetchUserData={fetchUserData} />
                    </div>
                </div >
            )}
        </>
    )
}

export default User