import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { TASK_POST_DATA } from '../../auth/core/_requests';
import AllUser from '../../../pages/AllUser';
import toast from 'react-hot-toast';



const TaskForm = ({ setTaskModal, leadsOwnerSelected, setLeadsOwnerSelected, setTaskAssignby, taskAssignby,
    fetchTaskList, contactOptions, leadsOptions,
}: any) => {
    const [loading, setLoading] = useState(false)
    const [reminderEnabled, setReminderEnabled] = useState(false);
    // const [selectedOption, setSelectedOption] = useState('Contact');
    const UserDetails: any = localStorage.getItem('kc-auth')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id

    const initialValues = {
        task_type: '',
        task_related_to: 'Contact',
        task_due_date: '',
        task_due_time: '',
        task_subject: '',
        task_status: '',
        task_priority: '',
        task_description: '',
        task_reminder_time: '',
        task_reminder_notify: '2',
    }

    const handleRelatedChange = (selected: any) => {
        setTaskAssignby(selected);
    };

    const handleTaskAssign = (selected: any) => {
        setLeadsOwnerSelected(selected);
    };

    const validationSchema = Yup.object().shape({
        task_type: Yup.string().required('Task type is required'),
        task_status: Yup.string().required('Task status is required'),
        task_due_date: Yup.string().required('Due date is required'),
        task_due_time: Yup.string().required('Due Time is required'),
    });



    // const handleSelectChange = (value: string) => {
    //     setSelectedOption(value);
    //     setTaskAssignby(null as any)
    // };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            if (!leadsOwnerSelected?.value) {
                toast.error("Please select task assignee", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    },
                    duration: 5000
                });
                setLoading(false);
                return;
            }
            // if (!taskAssignby?.value) {
            //     toast.error("Please select task telated to", {
            //         style: {
            //             background: '#F13637',
            //             color: 'white',
            //         },
            //         duration: 5000
            //     });
            //     setLoading(false);
            //     return;
            // }
            const LeadOwner = leadsOwnerSelected?.value;
            // const RelatedToId = taskAssignby?.value
            const RelatedToId = taskAssignby.map((option: any) => option.value);
            (JSON.stringify(RelatedToId));
            const ReminderOn = `${values.task_due_date} ${values.task_reminder_time}`;
            const response = await TASK_POST_DATA(
                LeadOwner ? LeadOwner : "",
                values.task_related_to,
                RelatedToId ? RelatedToId : "",
                values.task_type,
                values.task_due_date,
                values.task_due_time,
                values.task_status,
                values.task_priority,
                values.task_reminder_notify,
                values.task_reminder_time ? ReminderOn : "",
                values.task_description,
                values.task_subject,
                user_id
            )
            if (response.data.message === 'success') {
                toast.success("Task Created successfully", {
                    style: {
                        background: '#3DC13C',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
                setLeadsOwnerSelected(null as any)
                setTaskAssignby(null as any)
                actions.resetForm()
                fetchTaskList("0", user_id);
                setTaskModal(false)
            } else {
                toast.error("Failed to add task", {
                    style: {
                        background: '#F13637',
                        color: 'white',
                    }, duration: 5000
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    }


    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Create New Task :</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setTaskModal(false)}></button>
                </div>
                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ values }) => (
                        <Form>
                            <div className="modal-body form_content">
                                <div className="form-group mb-3">
                                    <label className='form-label mb-3 required'>Task Assignee</label>
                                    <div className='task_assignee_dropdown'>
                                        <Select
                                            options={AllUser()}
                                            value={leadsOwnerSelected}
                                            placeholder="Search or select..."
                                            isSearchable
                                            menuPosition="fixed"
                                            onChange={handleTaskAssign}
                                        />
                                    </div>
                                </div>

                                <div className="form-group  mb-3">
                                    <label className="form-label mb-3 required">Task Related To</label>
                                    <div className='form-group mb-3'>
                                        <Field as="select" name="task_related_to" aria-label="Select example" className="form-select form-select-sm">
                                            <option value="Contact">Contact</option>
                                            <option value="Lead">Lead</option>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="task_related_to" component="div" className="text-danger" />
                                </div>

                                {/* <div className="form-group mb-3">
                                    <Select
                                        id="dropdown"
                                        options={values.task_related_to === 'Lead' ? leadsOptions : contactOptions}
                                        value={taskAssignby}
                                        placeholder="Search or select..."
                                        isSearchable
                                        isMulti
                                        components={{ Option: CustomOption }}
                                        onChange={handleRelatedChange}
                                        menuPosition="fixed"
                                    />
                                </div> */}

                                <div className="form-group mb-3">
                                    <MultiSelect
                                        options={values.task_related_to === "Lead" ? leadsOptions : contactOptions}
                                        value={taskAssignby ? taskAssignby : []} // Ensuring value is an array
                                        onChange={handleRelatedChange}
                                        labelledBy="Select"
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="form-label mb-3 required" htmlFor="task_type">Task Type</label>
                                    <Field as="select" name="task_type" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="">-None-</option>
                                        <option value="1">Counseling</option>
                                        <option value="2">Email</option>
                                        <option value="3">Meeting</option>
                                    </Field>
                                    <ErrorMessage name="task_type" component="div" className="text-danger" />
                                </div>

                                <div className="form-group  mb-3">
                                    <label className="form-label mb-3 required">Due Date & Time</label>
                                    <div className='input-group'>
                                        <Field name="task_due_date" type="date" className="form-control form-control-sm" />
                                        <Field name="task_due_time" type="time" className="form-control form-control-sm" />
                                    </div>
                                    <div className='input-group'>
                                        <ErrorMessage name="task_due_date" component="div" className="text-danger me-5" />
                                        <ErrorMessage name="task_due_time" component="div" className="text-danger ms-20" />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label className="form-label mb-3 required" htmlFor="task_status">Status</label>
                                    <Field as="select" name="task_status" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="">-None-</option>
                                        <option value="1">Not started</option>
                                        <option value="6">Pending</option>
                                        <option value="3">In progress</option>
                                        <option value="4">Waiting For Input</option>
                                        <option value="5">Completed</option>
                                    </Field>
                                    <ErrorMessage name="task_status" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label mb-3 required" htmlFor="task_priority">Priority</label>
                                    <Field as="select" name="task_priority" aria-label="Select example" className="form-select form-select-sm">
                                        <option value="1">High</option>
                                        <option value="2">Highest</option>
                                        <option value="3">Low</option>
                                        <option value="4">Lowest</option>
                                        <option value="5">Normal</option>
                                    </Field>
                                    <ErrorMessage name="task_priority" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label mt-3 mb-3" htmlFor="task_subject">Subject</label>
                                    <Field type="text" name="task_subject" className="form-control form-control-sm" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label mt-3 mb-3" htmlFor="task_description">Task Description</label>
                                    <textarea name="task_description" className="form-control form-control-sm"></textarea>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="flexSwitchDefault" className="form-label mt-3 mb-3">Reminder</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchDefault"
                                            checked={reminderEnabled}
                                            onChange={(e) => setReminderEnabled(e.target.checked)} // Update state on switch change
                                        />
                                        <h6 className="ms-5">On due date at {values.task_reminder_time} by {
                                            values.task_reminder_notify == "1" ? "Email" :
                                                values.task_reminder_notify == "2" ? "Pop Up" :
                                                    values.task_reminder_notify == "3" ? "Email & Pop Up" : ""
                                        }
                                        </h6>
                                    </div>
                                </div>

                                {reminderEnabled && (
                                    <>
                                        <div style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', padding: '20px', marginTop: '10px' }}>
                                            <div className="d-flex align-items-center">
                                                <div className="fw-bold">Reminder on due date at </div>
                                                <div className="form-group ms-5">
                                                    <Field name="task_reminder_time" type="time" className="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-2">
                                                <div className="fw-bold">Notify</div>
                                                <div className="form-group ms-5">
                                                    <Field as="select" name="task_reminder_notify" aria-label="Select example" className="form-select border-0 form-select-sm">
                                                        {/* <option value="">Select</option> */}
                                                        {/* <option value="1">Email</option> */}
                                                        <option value="2">Pop Up</option>
                                                        {/* <option value="3">Both</option> */}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div >

        </>
    )
}

export default TaskForm