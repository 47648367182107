import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { LOG_CALL_POST_DATA } from '../../auth/core/_requests';
import { MultiSelect } from 'react-multi-select-component';

const LogCallForm = ({ setCallModal, handleRelatedChange, contactOptions, leadsOptions, handleSelectChange,
  selectedOption, taskAssignby, setTaskAssignby, fetchCallList
}: any) => {
  const [loading, setLoading] = useState(false)


  const UserDetails: any = localStorage.getItem('kc-auth')
  const userDetailsObject = JSON.parse(UserDetails);
  const user_id = userDetailsObject?.user_id

  const validationSchema = Yup.object().shape({
    call_task_type: Yup.string().required('Call Type is required'),
    call_start_date: Yup.string().required('Start date is required'),
    call_start_time: Yup.string().required('Start Time is required'),
    call_end_time: Yup.string().required('End Time is required'),
    call_duration: Yup.string().required('Call Duration is required'),
  });

  const initialValues = {
    call_task_type: '1',
    call_task_to: 'Contact',
    call_status: '',
    call_start_date: '',
    call_start_time: '',
    call_end_time: '',
    call_duration: '',
    call_subject: '',
    call_purpose: '',
    call_agenda: '',
    call_result: '',
    call_description: '',
  };

  const handleSubmit = async (values: any, actions: FormikValues) => {
    setLoading(true)
    try {
      // if (!taskAssignby?.value) {
      //   toast.error("Please select call to", {
      //     style: {
      //       background: '#F13637',
      //       color: 'white',
      //     },
      //     duration: 5000
      //   });
      //   setLoading(false);
      //   return;
      // }
      // const CallToId = taskAssignby?.value
      const CallToId = taskAssignby.map((option: any) => option.value);
      (JSON.stringify(CallToId));
      const response = await LOG_CALL_POST_DATA(
        values.call_task_to,
        CallToId,
        values.call_task_type,
        values.call_status,
        values.call_start_date,
        values.call_start_time,
        values.call_end_time,
        values.call_duration,
        values.call_subject,
        values.call_purpose,
        values.call_agenda,
        values.call_result,
        values.call_description,
        "",
        user_id
      )
      if (response.data.message === 'success') {
        toast.success("Log Call Created successfully", {
          style: {
            background: '#3DC13C',
            color: 'white',
          }, duration: 5000
        });
        setLoading(false);
        setTaskAssignby(null as any)
        actions.resetForm()
        fetchCallList("0", user_id);
        setCallModal(false)
      } else {
        toast.error("Failed to add call log", {
          style: {
            background: '#F13637',
            color: 'white',
          }, duration: 5000
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching call log:', error);
    }
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 className="modal-title">Log a call :</h2>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => setCallModal(false)}></button>
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <div className="modal-body form_content">
              <h4 className="mb-5">Call Information</h4>

              {/* <div className="form-group mb-3">
                <label className='form-label mb-3 required'>Call To</label>
                <div className="input-group mb-3">
                  <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{selectedOption}</button>
                  <ul className="dropdown-menu">
                    <li><button className="dropdown-item" onClick={() => handleSelectChange('Lead')}>Lead</button></li>
                    <li><button className="dropdown-item" onClick={() => handleSelectChange('Contact')}>Contact</button></li>
                  </ul>
                  <Select
                    id="dropdown"
                    options={selectedOption === 'Lead' ? leadsOptions : contactOptions}
                    value={taskAssignby}
                    placeholder="Search or select..."
                    isSearchable
                    className={selectedOption === 'Lead' ? 'task_leads_dropdown' : 'task_contacts_dropdown'}
                    onChange={handleRelatedChange}
                    menuPosition="fixed"
                  />
                </div>
              </div> */}

              <div className="form-group  mb-3">
                <label className="form-label mb-3 required">Call To</label>
                <div className='form-group mb-3'>
                  <Field as="select" name="call_task_to" aria-label="Select example" className="form-select form-select-sm">
                    <option value="Contact">Contact</option>
                    <option value="Lead">Lead</option>
                  </Field>
                </div>
                <ErrorMessage name="call_task_to" component="div" className="text-danger" />
              </div> 

              <div className="form-group mb-3">
                <MultiSelect
                  options={values.call_task_to === "Lead" ? leadsOptions : contactOptions}
                  value={taskAssignby ? taskAssignby : []} // Ensuring value is an array
                  onChange={handleRelatedChange}
                  labelledBy="Select"
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="call_task_type" className="form-label mb-3 required">Call Type</label>
                <Field as="select" name="call_task_type" aria-label="Select example" className="form-select form-select-sm">
                  <option value="1">Outbound</option>
                  <option value="2">Inbound</option>
                  <option value="3">Missed</option>
                </Field>
                <ErrorMessage name="call_task_type" component="div" className="text-danger" />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="call_status" className="form-label mb-3 required">Outgoing Call Status</label>
                <Field as="select" name="call_status" aria-label="Select example" className="form-select form-select-sm">
                  <option value="1">Completed</option>
                  <option value="3">Not Connected</option>
                  <option value="4">Unable to Pick Call</option>
                </Field>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="call_start_date" className="form-label mb-3 required">Call Start Date</label>
                <Field name="call_start_date" type="date" className="form-control form-control-sm" />
                <ErrorMessage name="call_start_date" component="div" className="text-danger" />
              </div>
              <div className="form-group  mb-3">
                <label className="form-label mb-3 required">Call Start Time & End Time</label>
                <div className='input-group'>
                  <Field name="call_start_time" type="time" className="form-control form-control-sm" />
                  <Field name="call_end_time" type="time" className="form-control form-control-sm" />
                </div>
                <div className='input-group'>
                  <ErrorMessage name="call_start_time" component="div" className="text-danger me-5" />
                  <ErrorMessage name="call_end_time" component="div" className="text-danger ms-20" />
                </div>
              </div>
              {values.call_task_type != "3" && (
                <div className="form-group mb-3">
                  <label htmlFor="call_duration" className="form-label mb-3 required">Call Duration</label>
                  <Field name="call_duration" type="text" className="form-control form-control-sm" />
                  <ErrorMessage name="call_duration" component="div" className="text-danger" />
                </div>
              )}
              <div className="form-group mb-3">
                <label htmlFor="call_subject" className="form-label mb-3 required">Subject</label>
                <Field name="call_subject" type="text" className="form-control form-control-sm" />
              </div>


              {values.call_task_type === "2" ? (
                <>
                  <h4 className="mt-5 mb-5">Reason For Incoming Call</h4>
                  <div className="form-group mb-3">
                    <label htmlFor="call_description" className="form-label mb-3 required">Description</label>
                    <Field name="call_description" type="text" className="form-control form-control-sm" />
                  </div>
                </>
              ) : values.call_task_type === "1" ? (
                <>
                  <h4 className="mt-5 mb-5">Purpose Of Outgoing Call</h4>

                  <div className="form-group mb-3">
                    <label htmlFor="call_purpose" className="form-label mb-3 required">Call Purpose</label>
                    <Field as="select" name="call_purpose" aria-label="Select example" className="form-select form-select-sm">
                      <option value="">-None-</option>
                      <option value="1">Prospective</option>
                      <option value="2">Negotiation</option>
                    </Field>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="call_agenda" className="form-label mb-3 required">Call Agenda</label>
                    <Field name="call_agenda" type="text" className="form-control form-control-sm" />
                  </div>

                  <h4 className="mt-5 mb-5">Outcome Of Outgoing Call</h4>
                  <div className="form-group mb-3">
                    <label htmlFor="call_result" className="form-label mb-3 required">Call Result</label>
                    <Field as="select" name="call_result" aria-label="Select example" className="form-select form-select-sm">
                      <option value="1">Interested</option>
                      <option value="2">Not Interested</option>
                      <option value="3">No Response/Busy</option>
                      <option value="4">Requested More Info</option>
                      <option value="5">Requested call back</option>
                      <option value="6">Invalid Number</option>
                    </Field>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="call_description" className="form-label mb-3 required">Description</label>
                    <Field name="call_description" type="text" className="form-control form-control-sm" />
                  </div>
                </>
              ) : ''}
            </div>

            <div className="modal-footer">
              <div className='d-flex justify-content-end'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Submit'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LogCallForm;
