import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom';

const Login = () => {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const initialValues = {
    user_email_address: '',
    user_password: '',
  }

  const validationSchema = Yup.object().shape({
    user_email_address: Yup.string().required('Email is required').email('Invalid email'),
    user_password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async (values: any) => {
    setLoading(true)
    try {
      const response: any = await login(values.user_email_address, values.user_password)
      if (response.data === 'Failed') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please Check Email Password',
        });
      } 
      else if(response.data === 'account_deactivated') {
        Swal.fire({
          icon: 'info',
          title: 'Account deactivated',
          text: 'Please contact admin to reactive your account',
        });
      }
      else {
        saveAuth(response.data)
      }
      const loginStatus = response.data.api_token;
      if (loginStatus) {
        const { data: user } = await getUserByToken(response.data.api_token)
        setCurrentUser(user)
      } else {
        saveAuth(undefined)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
      setLoading(false)
    }
  }

  return (
    <>
      <div className='login_bg_image d-flex align-items-center justify-content-center' style={{ minHeight: "100vh" }}>
        <div className='container pt-5' style={{ maxWidth: "1170px" }}>
          <div className='row'>
            <div className='col-lg-10 mx-auto login-desk'>
              <div className='row'>
                <div className='col-md-7 bg-white p-10'>
                  <div className="text-center" style={{ padding: "50px" }}>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/kc_logo_blue.svg')}
                      className='h-50px mb-10'
                    />
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/avatars/login_left_image.png')}
                      className='w-100 mb-5'
                    />
                    <h3>Connecting Leads to Success</h3>
                    <p>Effortless Management from Lead to Admission: Experience the Power of Digital Factory CRM.</p>
                  </div>
                </div>
                <div className='col-md-5 login_form' style={{ padding: "50px" }}>
                  <div className='text-center mt-10'>
                    <h4 className='fs-2x text-white'>Welcome Back</h4>
                    <p>Sign in to your Account</p>
                  </div>
                  <div className='mt-10'>
                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                      {() => (
                        <Form>
                          <div className="mb-10">
                            <label className="form-label mb-3 text-white" htmlFor="user_email_address" >Email Address</label>
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1">
                                <i className="far fa-user"></i>
                              </span>
                              <Field type="text" name="user_email_address" placeholder="Enter Email Address" className="form-control form-control-sm" />
                            </div>
                            <ErrorMessage name="user_email_address" component="div" className="text-white" />
                          </div>
                          <div className="mb-2">
                            <label htmlFor="user_password" className='form-label text-white mb-3'>Password</label>
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1">
                                <i className="fas fa-lock"></i>
                              </span>
                              <Field type="password" name="user_password" placeholder="Enter Password" className="form-control form-control-sm" />
                            </div>
                            <ErrorMessage name="user_password" component="div" className="text-white" />
                          </div>
                         
                          <div className='mb-8 mt-12'>
                            <button
                              type='submit'
                              id='kt_sign_in_submit'
                              className='btn btn-primary btn-sm login_btn'
                            >
                              {!loading && <span className='indicator-label'>Login</span>}
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Please wait...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>

                          <div className="text-white text-center fw-semibold fs-6">
                            Built & Powered by <a href="https://digitalfactoryindia.com/" style={{ color: "#dbdbdb" }} target="_new">DIGITAL FACTORY</a>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default Login