import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik' 
import { KTIcon } from '../../../_metronic/helpers';


const DocumentForm = ({loading,handleImageChange, initialValues, uploadError, images, handleSubmit, formatFileSize, handleRemoveImage,
}: any) => { 
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {() => (
                    <Form>
                        <div className="modal-body">
                            <div className='form-group mb-3'>
                                <label className='form-label mb-3'>
                                    Application Processing
                                </label>
                                <div className='d-flex align-items-center'>
                                    <div className="form-check form-check-custom form-check-solid ">
                                        <Field
                                            type="radio"
                                            name="doc_app_process"
                                            value="1"
                                            id="sop"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="sop">SOP</label>
                                    </div>
                                    <div className='form-check form-check-custom form-check-solid ms-5'>
                                        <Field
                                            type="radio"
                                            name="doc_app_process"
                                            value="2"
                                            id="lor"
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="lor">LOR</label>
                                    </div>
                                </div>
                                <ErrorMessage name='doc_app_process' component="div" className="text-danger" />
                            </div>
                            <div className='form-group mb-3'>
                                <div className='mt-5'>
                                    <label className="form-label mb-5">Document Submission (Upload Documents)</label>
                                    <label className="file-attachment text-primary">
                                        <span className="me-20">Attach files</span> <i className="bi bi-images fs-2 text-primary"></i>
                                        <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg,video/mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                    </label>
                                </div>
                                {uploadError.length > 0 && (
                                    <div className="text-danger mt-5">
                                        {uploadError.map((error: any, index: any) => (
                                            <div key={index}>{error}</div>
                                        ))}
                                    </div>
                                )}

                                {images.length > 0 ? (
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            <tr className='fw-bold fs-6'>
                                                <th className='ps-8'>File Name</th>
                                                <th className='text-center'> Size</th>
                                                <th className='text-center'>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {images.map((image: any, index: any) => (
                                                <tr key={index}>
                                                    <td className='ps-8'>
                                                        {image.file.name}
                                                    </td>
                                                    <td className='text-center'>
                                                        {formatFileSize(image.file.size)}
                                                    </td>
                                                    <td className='text-center text-hover-primary' onClick={() => handleRemoveImage(index)}>
                                                        <KTIcon iconName='trash' className='fs-1 text-dark cursor-pointer' />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : <></>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className='d-flex justify-content-end'>
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Submit'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default DocumentForm